.error {
  gap: var(--vp-semantic-spacing-s);
  text-align: center;

  &,
  &-page,
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-page {
    height: 100%;
    max-height: calc(100dvh - #{rem(80)});
    min-height: 100dvh;
    width: 100%;
  }

  svg {
    flex-shrink: 1;
    flex-grow: 1;
    max-height: 50vh;
    width: auto;
  }

  > *:not(svg) {
    flex-grow: 0;
  }

  .app-router & {
    min-height: 100%;
  }

  &__wrapper {
    gap: var(--vp-semantic-spacing-s);
  }
}
