// Overrides VtmnIcon display
[class^='vtmx-'] {
  display: inline-flex;
  vertical-align: middle;
}

// remove checked effect if only focused
.vtmn-rating.vtmn-rating_size--medium
  > .vtmn-rating--interactive
  input:focus:not(:checked)
  + label {
  background-image: none;
}

// Override focus-within colors
body {
  --vp-semantic-box-shadow-focus: var(--vp-semantic-box-shadow-focus);
}

// Limit textareas to vertical resize only
.vtmn-text-input_container textarea.vtmn-text-input {
  resize: vertical;
}

.vtmn-text-input {
  box-shadow: inset 0 0 0 var(--vp-semantic-border-width-s)
    var(--vp-semantic-color-border-neutral) !important;

  &:hover {
    box-shadow: inset 0 0 0 var(--vp-semantic-border-width-m)
      var(--vp-semantic-color-border-neutral) !important;
  }
}
