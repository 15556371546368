@use 'sass:math';

.rewards-landing {
  display: flex;
  flex-direction: column;

  @include m-min {
    gap: var(--vp-semantic-spacing-7xl);
  }

  &__hero {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    background-color: var(--vp-semantic-color-container-brand);

    @include m-min {
      display: grid;
      flex-direction: column;
      grid-template-columns:
        var(--vp-semantic-spacing-l)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        repeat(12, 1fr)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        var(--vp-semantic-spacing-l);
    }

    &__media {
      position: relative;
      flex-grow: 1;
      min-block-size: 260px;

      > img {
        object-position: 30%;
      }

      @include m-min {
        grid-column: 11 / 17;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: var(--vp-semantic-spacing-2xl);

      padding-block: var(--vp-semantic-spacing-2xl);
      padding-inline: var(--vp-semantic-spacing-l);

      color: var(--vp-semantic-color-content-on-brand-neutral);
      background-color: var(--vp-semantic-color-container-brand);

      text-wrap: balance;

      @include m-min {
        grid-column: 3 / 11;
        padding-inline-end: var(--vp-semantic-spacing-6xl);
        padding-block: var(--vp-semantic-spacing-7xl);
      }

      &__action {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-l);

        button {
          align-self: flex-start;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-3xl);

    @include m-min {
      padding-inline: var(--vp-semantic-spacing-8xl);
      gap: var(--vp-semantic-spacing-7xl);
    }

    &__first-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--vp-semantic-spacing-4xl);
      padding-block: var(--vp-semantic-spacing-4xl);

      color: var(--vp-semantic-color-content-brand);
      background-color: var(--vp-semantic-color-container-commercial-neutral);

      @include m-min {
        padding-inline: var(--vp-semantic-spacing-7xl);
        border-radius: var(--vp-semantic-border-radius-m);
      }

      &__title {
        text-align: center;
        text-wrap: balance;
      }

      .steps {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--vp-semantic-spacing-2xl);

        @include m-min {
          flex-direction: row;
        }

        &__step {
          display: flex;
          flex-direction: column;

          @include m-min {
            inline-size: 30%;
          }

          &__tag {
            inline-size: fit-content;
            background-color: var(--vp-semantic-color-container-brand);
            color: var(--vp-semantic-color-content-on-brand-neutral);
          }

          &__title {
            margin-block-start: var(--vp-semantic-spacing-xs);
          }

          &__caption {
            margin-block-start: var(--vp-semantic-spacing-m);
          }
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-l);
      }
    }

    &__second-block {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-3xl);

      &__header {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-l);
        text-wrap: balance;

        @include m-min {
          text-align: center;
        }

        &__description {
          color: var(--vp-semantic-color-content-quiet);
        }
      }

      &__sub-blocks {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-2xl);

        @include m-min {
          flex-direction: row;
        }

        .sub-block {
          display: flex;
          flex-direction: column;
          gap: var(--vp-semantic-spacing-l);

          &__paragraph {
            color: var(--vp-semantic-color-content-quiet);
          }

          &__tag {
            inline-size: fit-content;
          }
        }
      }
    }
  }

  &__divider {
    inline-size: auto;
    block-size: 1.5px;
    margin-inline: var(--vp-semantic-spacing-l);
    background-color: var(--vp-semantic-color-border-quiet);

    @include m-min {
      inline-size: 100%;
      margin-inline: auto;
    }
  }

  &__terms {
    padding-block-end: var(--vp-semantic-spacing-7xl);
  }
}

.rewards-banner {
  display: flex;
  align-items: center;
  gap: var(--vp-semantic-spacing-m);
  color: var(--vp-semantic-color-content-brand);
  background-color: var(--vp-semantic-color-container-commercial-neutral);
  padding: var(--vp-semantic-spacing-s);
  border-radius: var(--vp-semantic-border-radius-s);
  margin-inline: var(--vp-semantic-border-radius-xs);
  margin-block-end: var(--vp-semantic-spacing-xl);

  @include m-min {
    margin-inline: calc(-1 * var(--vp-semantic-spacing-l));
  }

  &__icon {
    display: flex;
    align-items: center;
    background-color: var(--vp-semantic-color-container-neutral);
    padding: var(--vp-semantic-spacing-s);
    border-radius: var(--vp-semantic-border-radius-s);
  }
}

.rewards-terms-wrapper {
  padding-inline: var(--vp-semantic-spacing-l);
  padding-block-start: var(--vp-semantic-spacing-2xl);
  padding-block-end: var(--vp-semantic-spacing-7xl);

  @include m-min {
    padding-inline: var(--vp-semantic-spacing-8xl);
    padding-block-end: 0;
  }
}

.rewards-terms {
  display: flex;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-2xl);

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xs);

    &__breadcrumbs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: var(--vp-semantic-spacing-3xs);
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    ul {
      list-style-type: disc;
      padding: 0 var(--vp-semantic-spacing-xs);
    }

    &__link {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
