.preview {
  background-color: var(--vp-semantic-color-background-alternative);
  min-height: 200vh;
}

.noResult {
  background-color: var(--vp-semantic-color-background-main);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: auto;
  }
}
