@use 'sass:math';

@import '~/assets/scss/global/tools';

.author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-block: var(--vp-semantic-spacing-xs);

  &__avatar {
    width: var(--vp-semantic-spacing-3xl);
    margin-inline-end: var(--vp-semantic-spacing-s);

    > * {
      display: block;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    line-height: math.div(14em, 12);
    font-size: rem(14);
    word-break: normal;
  }

  &__date {
    color: var(--vp-semantic-color-content-quiet);
  }
}
