:root {
  --vtmn-new-color-black: #000000;
  --vtmn-new-color-white: #ffffff;
  --vtmn-new-color-rock-010: #f1f0ee;
  --vtmn-new-color-rock-020: #e1e0df;
  --vtmn-new-color-rock-050: #bfbfbf;
  --vtmn-new-color-rock-100: #a8a8a8;
  --vtmn-new-color-rock-200: #949494;
  --vtmn-new-color-rock-300: #7a7a7a;
  --vtmn-new-color-rock-400: #616161;
  --vtmn-new-color-rock-500: #424242;
  --vtmn-new-color-rock-600: #383838;
  --vtmn-new-color-rock-700: #2b2b2b;
  --vtmn-new-color-rock-800: #1f1f1f;
  --vtmn-new-color-rock-900: #101010;
  --vtmn-new-color-grey-050: #f7f8f9;
  --vtmn-new-color-grey-100: #eff1f3;
  --vtmn-new-color-grey-200: #d9dde1;
  --vtmn-new-color-grey-300: #b3bac3;
  --vtmn-new-color-grey-400: #8c96a2;
  --vtmn-new-color-grey-500: #687787;
  --vtmn-new-color-grey-600: #4e5d6b;
  --vtmn-new-color-grey-700: #344450;
  --vtmn-new-color-grey-800: #1a2a34;
  --vtmn-new-color-grey-900: #142129;
  --vtmn-new-color-grey-950: #001018;
  --vtmn-new-color-red-050: #feeced;
  --vtmn-new-color-red-100: #fec9cb;
  --vtmn-new-color-red-200: #fd9297;
  --vtmn-new-color-red-300: #fd7278;
  --vtmn-new-color-red-400: #e3262f;
  --vtmn-new-color-red-500: #ab0009;
  --vtmn-new-color-red-600: #870007;
  --vtmn-new-color-red-700: #5a0005;
  --vtmn-new-color-orange-050: #fff3ed;
  --vtmn-new-color-orange-100: #ffe4d6;
  --vtmn-new-color-orange-200: #fac3a5;
  --vtmn-new-color-orange-300: #fa9c69;
  --vtmn-new-color-orange-400: #db6900;
  --vtmn-new-color-orange-500: #c15e29;
  --vtmn-new-color-orange-600: #9a4b21;
  --vtmn-new-color-orange-700: #673216;
  --vtmn-new-color-yellow-050: #fffef0;
  --vtmn-new-color-yellow-100: #fffbc7;
  --vtmn-new-color-yellow-200: #fff58d;
  --vtmn-new-color-yellow-300: #fff370;
  --vtmn-new-color-yellow-400: #ffea28;
  --vtmn-new-color-yellow-500: #bcb02c;
  --vtmn-new-color-yellow-600: #999028;
  --vtmn-new-color-yellow-700: #67611b;
  --vtmn-new-color-conifer-050: #eafbe8;
  --vtmn-new-color-conifer-100: #cbf0c7;
  --vtmn-new-color-conifer-200: #88d77f;
  --vtmn-new-color-conifer-300: #74c76a;
  --vtmn-new-color-conifer-400: #23a942;
  --vtmn-new-color-conifer-500: #41a036;
  --vtmn-new-color-conifer-600: #378d2e;
  --vtmn-new-color-conifer-700: #256c1d;
  --vtmn-new-color-mint-050: #f0fff4;
  --vtmn-new-color-mint-100: #d6ffe3;
  --vtmn-new-color-mint-200: #c2ffd6;
  --vtmn-new-color-mint-300: #a8ffc5;
  --vtmn-new-color-mint-400: #94ffb8;
  --vtmn-new-color-mint-500: #7affa6;
  --vtmn-new-color-mint-600: #6ee797;
  --vtmn-new-color-mint-700: #58b678;
  --vtmn-new-color-mint-800: #149b65;
  --vtmn-new-color-mint-900: #336b46;
  --vtmn-new-color-green-050: #e4f9f3;
  --vtmn-new-color-green-100: #beefe2;
  --vtmn-new-color-green-200: #7cdfc4;
  --vtmn-new-color-green-300: #21ce9c;
  --vtmn-new-color-green-400: #02be8a;
  --vtmn-new-color-green-500: #029e73;
  --vtmn-new-color-green-600: #017f5c;
  --vtmn-new-color-green-700: #006f43;
  --vtmn-new-color-purple-050: #f2edf2;
  --vtmn-new-color-purple-100: #dccfdd;
  --vtmn-new-color-purple-200: #ac8daf;
  --vtmn-new-color-purple-300: #966f9a;
  --vtmn-new-color-purple-400: #6c4e6f;
  --vtmn-new-color-purple-500: #5b415d;
  --vtmn-new-color-purple-600: #49354b;
  --vtmn-new-color-purple-700: #2c202d;
  --vtmn-new-color-cobalt-010: #f5f4f5;
  --vtmn-new-color-cobalt-050: #ecedf8;
  --vtmn-new-color-cobalt-100: #c2c6ea;
  --vtmn-new-color-cobalt-200: #a4aadf;
  --vtmn-new-color-cobalt-300: #7a83d1;
  --vtmn-new-color-cobalt-400: #5f6ac8;
  --vtmn-new-color-cobalt-500: #3643ba;
  --vtmn-new-color-cobalt-600: #313daa;
  --vtmn-new-color-cobalt-700: #262f82;
  --vtmn-new-color-cobalt-800: #1e2567;
  --vtmn-new-color-cobalt-900: #171c4f;
  --vtmn-new-color-blue-050: #e7f3f9;
  --vtmn-new-color-blue-100: #bedeef;
  --vtmn-new-color-blue-200: #7ebee0;
  --vtmn-new-color-blue-300: #3d9acc;
  --vtmn-new-color-blue-400: #007dbc;
  --vtmn-new-color-blue-450: #0071b5;
  --vtmn-new-color-blue-500: #00689d;
  --vtmn-new-color-blue-600: #00537d;
  --vtmn-new-color-blue-700: #012b49;
  --vtmn-new-color-lemon-100: #f5ffb6;
  --vtmn-new-color-clementine-100: #ffd8b5;
  --vtmn-new-color-lavender-100: #dfbfff;
}

.vtmn-new-visual-identity {
  /* BACKGROUND COLORS */
  --vtmn-semantic-color_background-primary: var(--vtmn-new-color-white);
  --vtmn-semantic-color_background-secondary: var(--vtmn-new-color-cobalt-010);
  --vtmn-semantic-color_background-tertiary: var(--vtmn-new-color-rock-010);
  --vtmn-semantic-color_background-brand-primary: var(
    --vtmn-new-color-cobalt-500
  );
  --vtmn-semantic-color_background-brand-secondary: var(
    --vtmn-new-color-cobalt-050
  );
  --vtmn-semantic-color_background-accent: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_background-alert: var(--vtmn-new-color-red-400);
  --vtmn-semantic-color_background-primary-reversed: var(
    --vtmn-new-color-rock-900
  );
  --vtmn-semantic-color_background-brand-primary-reversed: var(
    --vtmn-new-color-white
  );

  /* CONTENT COLORS */
  --vtmn-semantic-color_content-primary: var(--vtmn-new-color-rock-900);
  --vtmn-semantic-color_content-secondary: var(--vtmn-new-color-rock-500);
  --vtmn-semantic-color_content-tertiary: var(--vtmn-new-color-rock-400);
  --vtmn-semantic-color_content-action: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_content-active: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_content-inactive: var(--vtmn-new-color-rock-200);
  --vtmn-semantic-color_content-negative: var(--vtmn-new-color-red-400);
  --vtmn-semantic-color_content-warning: var(--vtmn-new-color-orange-400);
  --vtmn-semantic-color_content-positive: var(--vtmn-new-color-mint-800);
  --vtmn-semantic-color_content-information: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_content-accent: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-visited: var(--vtmn-new-color-rock-900);
  --vtmn-semantic-color_content-primary-reversed: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-action-reversed: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-visited-reversed: var(
    --vtmn-new-color-rock-900
  );

  /* BORDER COLORS */
  --vtmn-semantic-color_border-primary: var(--vtmn-new-color-rock-100);
  --vtmn-semantic-color_border-secondary: var(--vtmn-new-color-rock-010);
  --vtmn-semantic-color_border-tertiary: var(--vtmn-new-color-white);
  --vtmn-semantic-color_border-active: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_border-inactive: var(--vtmn-new-color-rock-300);
  --vtmn-semantic-color_border-negative: var(--vtmn-new-color-red-400);
  --vtmn-semantic-color_border-warning: var(--vtmn-new-color-orange-400);
  --vtmn-semantic-color_border-positive: var(--vtmn-new-color-mint-800);
  --vtmn-semantic-color_border-information: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_border-primary-reversed: var(--vtmn-new-color-white);

  /* DECORATIVE COLORS */
  --vtmn-semantic-color_decorative-gold: var(--vtmn-new-color-lemon-100);
  --vtmn-semantic-color_decorative-jade: var(--vtmn-new-color-conifer-100);
  --vtmn-semantic-color_decorative-emerald: var(--vtmn-new-color-mint-100);
  --vtmn-semantic-color_decorative-gravel: var(--vtmn-new-color-rock-020);
  --vtmn-semantic-color_decorative-brick: var(--vtmn-new-color-red-100);
  --vtmn-semantic-color_decorative-saffron: var(
    --vtmn-new-color-clementine-100
  );
  --vtmn-semantic-color_decorative-cobalt: var(--vtmn-new-color-cobalt-100);
  --vtmn-semantic-color_decorative-amethyst: var(--vtmn-new-color-lavender-100);

  /* HOVER COLORS */
  --vtmn-semantic-color_hover-primary: #ecedf8;
  --vtmn-semantic-color_hover-primary-transparent: #3643ba0d;
  --vtmn-semantic-color_hover-secondary-transparent: #1010100d;
  --vtmn-semantic-color_hover-tertiary-transparent: #ecedf8cc;
  --vtmn-semantic-color_hover-tertiary: #dadcf1;
  --vtmn-semantic-color_hover-brand: #323894;
  --vtmn-semantic-color_hover-accent: #323894;
  --vtmn-semantic-color_hover-primary-reversed-transparent: #ffffff14;
  --vtmn-semantic-color_hover-secondary-reversed-transparent: #ffffff05;
  --vtmn-semantic-color_hover-tertiary-reversed-transparent: #ffffffcc;

  /* ACTIVE COLORS */
  --vtmn-semantic-color_active-primary: #e1e3f4;
  --vtmn-semantic-color_active-primary-transparent: #3643ba26;
  --vtmn-semantic-color_active-secondary-transparent: #10101026;
  --vtmn-semantic-color_active-tertiary-transparent: #ecedf8cc;
  --vtmn-semantic-color_active-tertiary: #d2d5ef;
  --vtmn-semantic-color_active-brand: #2d2e70;
  --vtmn-semantic-color_active-accent: #2d2e70;
  --vtmn-semantic-color_active-primary-reversed-transparent: #ffffff40;
  --vtmn-semantic-color_active-secondary-reversed-transparent: #ffffff66;
  --vtmn-semantic-color_active-tertiary-reversed-transparent: #ffffffcc;
  --vtmn-semantic-color_active-brand-reversed-transparent: #ffffff99;
}

.vtmn-new-visual-identity--dark {
  /* BACKGROUND COLORS */
  --vtmn-semantic-color_background-primary: var(--vtmn-new-color-rock-900);
  --vtmn-semantic-color_background-secondary: var(--vtmn-new-color-black);
  --vtmn-semantic-color_background-tertiary: var(--vtmn-new-color-rock-800);
  --vtmn-semantic-color_background-brand-primary: var(
    --vtmn-new-color-cobalt-500
  );
  --vtmn-semantic-color_background-brand-secondary: var(--vtmn-new-color-black);
  --vtmn-semantic-color_background-accent: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_background-alert: var(--vtmn-new-color-red-300);
  --vtmn-semantic-color_background-primary-reversed: var(
    --vtmn-new-color-white
  );
  --vtmn-semantic-color_background-brand-primary-reversed: var(
    --vtmn-new-color-black
  );

  /* CONTENT COLORS */
  --vtmn-semantic-color_content-primary: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-secondary: var(--vtmn-new-color-rock-020);
  --vtmn-semantic-color_content-tertiary: var(--vtmn-new-color-rock-100);
  --vtmn-semantic-color_content-action: var(--vtmn-new-color-cobalt-300);
  --vtmn-semantic-color_content-active: var(--vtmn-new-color-cobalt-500);
  --vtmn-semantic-color_content-inactive: var(--vtmn-new-color-rock-300);
  --vtmn-semantic-color_content-negative: var(--vtmn-new-color-red-300);
  --vtmn-semantic-color_content-warning: var(--vtmn-new-color-orange-300);
  --vtmn-semantic-color_content-positive: var(--vtmn-new-color-mint-700);
  --vtmn-semantic-color_content-information: var(--vtmn-new-color-cobalt-300);
  --vtmn-semantic-color_content-accent: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-visited: var(--vtmn-new-color-white);
  --vtmn-semantic-color_content-primary-reversed: var(
    --vtmn-new-color-rock-900
  );
  --vtmn-semantic-color_content-action-reversed: var(--vtmn-new-color-rock-900);
  --vtmn-semantic-color_content-visited-reversed: var(
    --vtmn-new-color-purple-200
  );

  /* BORDER COLORS */
  --vtmn-semantic-color_border-primary: var(--vtmn-new-color-rock-500);
  --vtmn-semantic-color_border-secondary: var(--vtmn-new-color-black);
  --vtmn-semantic-color_border-tertiary: var(--vtmn-new-color-rock-800);
  --vtmn-semantic-color_border-active: var(--vtmn-new-color-cobalt-300);
  --vtmn-semantic-color_border-inactive: var(--vtmn-new-color-rock-300);
  --vtmn-semantic-color_border-negative: var(--vtmn-new-color-red-300);
  --vtmn-semantic-color_border-warning: var(--vtmn-new-color-orange-300);
  --vtmn-semantic-color_border-positive: var(--vtmn-new-color-mint-700);
  --vtmn-semantic-color_border-information: var(--vtmn-new-color-cobalt-300);
  --vtmn-semantic-color_border-primary-reversed: var(--vtmn-new-color-white);

  /* DECORATIVE COLORS */
  --vtmn-semantic-color_decorative-gold: var(--vtmn-new-color-yellow-700);
  --vtmn-semantic-color_decorative-jade: var(--vtmn-new-color-conifer-700);
  --vtmn-semantic-color_decorative-emerald: var(--vtmn-new-color-mint-900);
  --vtmn-semantic-color_decorative-gravel: var(--vtmn-new-color-rock-600);
  --vtmn-semantic-color_decorative-brick: var(--vtmn-new-color-red-600);
  --vtmn-semantic-color_decorative-saffron: var(--vtmn-new-color-orange-600);
  --vtmn-semantic-color_decorative-cobalt: var(--vtmn-new-color-cobalt-700);
  --vtmn-semantic-color_decorative-amethyst: var(--vtmn-new-color-purple-600);

  /* HOVER COLORS */
  --vtmn-semantic-color_hover-primary: #171c4f;
  --vtmn-semantic-color_hover-primary-transparent: #3643ba0d;
  --vtmn-semantic-color_hover-secondary-transparent: #ffffff0d;
  --vtmn-semantic-color_hover-tertiary-transparent: #171c4fcc;
  --vtmn-semantic-color_hover-tertiary: #171c4f;
  --vtmn-semantic-color_hover-brand: #323894;
  --vtmn-semantic-color_hover-accent: #323894;
  --vtmn-semantic-color_hover-primary-reversed-transparent: #10101014;
  --vtmn-semantic-color_hover-secondary-reversed-transparent: #10101005;
  --vtmn-semantic-color_hover-tertiary-reversed-transparent: #101010cc;

  /* ACTIVE COLORS */
  --vtmn-semantic-color_active-primary: #171c4f;
  --vtmn-semantic-color_active-primary-transparent: #3643ba26;
  --vtmn-semantic-color_active-secondary-transparent: #ffffff26;
  --vtmn-semantic-color_active-tertiary-transparent: #171c4fcc;
  --vtmn-semantic-color_active-tertiary: #171c4f;
  --vtmn-semantic-color_active-brand: #2d2e70;
  --vtmn-semantic-color_active-accent: #2d2e70;
  --vtmn-semantic-color_active-primary-reversed-transparent: #10101040;
  --vtmn-semantic-color_active-secondary-reversed-transparent: #10101066;
  --vtmn-semantic-color_active-tertiary-reversed-transparent: #101010cc;
  --vtmn-semantic-color_active-brand-reversed-transparent: #10101099;
}

/* PRICE OVERRIDE (Light mode) */
.vtmn-new-visual-identity .vtmn-price {
  background-color: transparent;
  color: var(--vtmn-new-color-black);
}
.vtmn-new-visual-identity .vtmn-price.vtmn-price_variant--strikethrough {
  background-color: transparent;
  color: var(--vtmn-new-color-rock-400);
}

/* PRICE OVERRIDE (Dark mode) */
.vtmn-new-visual-identity--dark .vtmn-price {
  background-color: transparent;
  color: var(--vtmn-new-color-white);
}
.vtmn-new-visual-identity--dark .vtmn-price.vtmn-price_variant--strikethrough {
  background-color: transparent;
  color: var(--vtmn-new-color-rock-100);
}

/* CARD OVERRIDE (Light mode) */
.vtmn-new-visual-identity .vtmn-card {
  --vtmn-shadow_100: 0 0 0 1px var(--vtmn-new-color-rock-020);
  --vtmn-shadow_200: 0 0 0 1px var(--vtmn-new-color-rock-020);
  --vtmn-shadow_300: 0 0 0 1px var(--vtmn-new-color-rock-020);
  --vtmn-shadow_400: 0 0 0 1px var(--vtmn-new-color-rock-020);
}

/* CARD OVERRIDE (Dark mode) */
.vtmn-new-visual-identity--dark .vtmn-card {
  --vtmn-shadow_100: 0 0 0 1px var(--vtmn-new-color-rock-500);
  --vtmn-shadow_200: 0 0 0 1px var(--vtmn-new-color-rock-500);
  --vtmn-shadow_300: 0 0 0 1px var(--vtmn-new-color-rock-500);
  --vtmn-shadow_400: 0 0 0 1px var(--vtmn-new-color-rock-500);
}

/* TAG OVERRIDE (Dark mode) */
.vtmn-new-visual-identity--dark .vtmn-tag_variant--brand {
  color: var(--vtmn-new-color-white);
}

/* CHIP OVERRIDE (Dark mode) */
.vtmn-new-visual-identity--dark .vtmn-chip[aria-pressed='true'],
.vtmn-new-visual-identity--dark
  .vtmn-chip_variant--input[aria-pressed='true']
  > .vp-icon-button
  > span[class^='vtmx-'],
.vtmn-chip_variant--input[aria-pressed='true'] > .vp-icon-button > svg {
  color: var(--vtmn-new-color-white);
}

/* BUTTON, CARD, TEXT INPUT, TAG, SEARCH, ALERT, MODAL, SNACKBAR, TOOLTIP, POPOVER, TOAST, NAVBAR, SELECT OVERRIDES (Light & Dark modes) */
.vtmn-new-visual-identity .vtmn-card,
.vtmn-new-visual-identity .vtmn-text-input,
.vtmn-new-visual-identity .vtmn-tag,
.vtmn-new-visual-identity .vtmn-alert,
.vtmn-new-visual-identity .vtmn-modal,
.vtmn-new-visual-identity .vtmn-snackbar,
.vtmn-new-visual-identity .vtmn-tooltip,
.vtmn-new-visual-identity .vtmn-popover,
.vtmn-new-visual-identity .vtmn-skeleton,
.vtmn-new-visual-identity .vtmn-toast,
.vtmn-new-visual-identity .vtmn-navbar .vtmn-navbar_link,
.vtmn-new-visual-identity .vtmn-select_container,
.vtmn-new-visual-identity--dark .vtmn-card,
.vtmn-new-visual-identity--dark .vtmn-text-input,
.vtmn-new-visual-identity--dark .vtmn-tag,
.vtmn-new-visual-identity--dark .vtmn-alert,
.vtmn-new-visual-identity--dark .vtmn-modal,
.vtmn-new-visual-identity--dark .vtmn-snackbar,
.vtmn-new-visual-identity--dark .vtmn-tooltip,
.vtmn-new-visual-identity--dark .vtmn-popover,
.vtmn-new-visual-identity--dark .vtmn-skeleton,
.vtmn-new-visual-identity--dark .vtmn-toast,
.vtmn-new-visual-identity--dark .vtmn-navbar .vtmn-navbar_link,
.vtmn-new-visual-identity--dark .vtmn-select_container select {
  --vtmn-radius_100: var(--vp-semantic-border-radius-main);
  --vtmn-radius_200: var(--vp-semantic-border-radius-main);
  --vtmn-radius_300: var(--vp-semantic-border-radius-main);
  --vtmn-radius_400: var(--vp-semantic-border-radius-main);
  --vtmn-radius_500: var(--vp-semantic-border-radius-main);
  --vtmn-radius_600: var(--vp-semantic-border-radius-main);
  --vtmn-radius_700: var(--vp-semantic-border-radius-main);
  --vtmn-radius_800: var(--vp-semantic-border-radius-main);
}

/* DROPDOWN OVERRIDE (Light & Dark modes) */
.vtmn-new-visual-identity .vtmn-dropdown details summary,
.vtmn-new-visual-identity .vtmn-dropdown label,
.vtmn-new-visual-identity .vtmn-dropdown .vtmn-dropdown_items,
.vtmn-new-visual-identity--dark .vtmn-dropdown details summary,
.vtmn-new-visual-identity--dark .vtmn-dropdown label,
.vtmn-new-visual-identity--dark .vtmn-dropdown .vtmn-dropdown_items {
  border-radius: var(--vp-semantic-border-radius-main);
}

/* NAVBAR OVERRIDE (Light & Dark modes) */
.vtmn-new-visual-identity .vtmn-navbar_link .vtmn-badge,
.vtmn-new-visual-identity--dark .vtmn-navbar_link .vtmn-badge {
  border-radius: var(--vp-semantic-border-radius-full);
}

/* SEARCH OVERRIDE (Light & Dark modes) */
.vtmn-new-visual-identity .vtmn-search input,
.vtmn-new-visual-identity .vtmn-search .vtmn-search_buttons,
.vtmn-new-visual-identity--dark .vtmn-search input,
.vtmn-new-visual-identity--dark .vtmn-search .vtmn-search_buttons {
  border-radius: var(--vp-semantic-border-radius-m);
  padding-left: 16px;
  padding-right: 8px;
}

/* MODAL OVERRIDE (Light & Dark modes) */
.vtmn-new-visual-identity .vtmn-modal,
.vtmn-new-visual-identity--dark .vtmn-modal {
  border-radius: var(--vp-semantic-border-radius-main);
}

.vtmn-new-visual-identity .vtmn-link,
.vtmn-new-visual-identity .vtmn-tag,
.vtmn-new-visual-identity .vtmn-chip,
.vtmn-new-visual-identity *[class^='vtmn-select'],
.vtmn-new-visual-identity *[class^='vtmn-text-input'] {
  font-family: 'Inter', sans-serif !important;
}

/* SKELETON OERRIDE */
.vtmn-new-visual-identity .vtmn-skeleton {
  background-color: var(--vp-semantic-color-background-alternative);
}

.vtmn-new-visual-identity .vtmn-skeleton::after {
  background-image: linear-gradient(
    90deg,
    var(--vp-semantic-color-container-neutral),
    var(--vp-semantic-color-container-quiet),
    var(--vp-semantic-color-container-neutral)
  );
}

.vtmn-new-visual-identity [class^='vtmn-text-input'],
.vtmn-new-visual-identity .vtmn-radio-button[type='radio'] + label,
.vtmn-new-visual-identity .vtmn-checkbox[type='checkbox'] + label {
  color: var(--vp-semantic-color-content-neutral) !important;
}

.vtmn-new-visual-identity
  *[class^='vtmn-']:has(span[class^='vtmx-'])
  span[class^='vtmx-'] {
  color: inherit !important;
}

.vtmn-new-visual-identity .vtmn-text-input {
  background-color: var(--vp-semantic-color-background-main);
  color: var(--vp-semantic-color-content-neutral);
}
