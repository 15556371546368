@use 'sass:math';

.sitemap {
  padding: var(--vp-semantic-spacing-2xl) 0;

  &__list {
    display: flex;
    flex-flow: column;
    gap: var(--vp-semantic-spacing-s);
    margin-block-start: var(--vp-semantic-spacing-2xl);
  }
}
