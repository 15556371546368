@use 'sass:math';

$main-column-max-width: calc(
  map-get($container-max-widths, xl) - var(--vp-semantic-spacing-2xl)
);

.homepage {
  &__secondary-text {
    color: var(--vp-semantic-color-content-quiet);
  }
}

.followed-projects {
  background-color: var(--vp-semantic-color-background-alternative);
  padding-block: var(--vp-semantic-spacing-4xl);

  &--empty {
    padding-block: var(--vp-semantic-spacing-2xl);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--vp-semantic-spacing-s);
  }

  &__incentive {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    text-align: center;

    button {
      margin: auto;
    }
  }

  &__animation {
    position: relative;

    .vp-icon-button {
      position: absolute;
      inset-block-end: var(--vp-semantic-spacing-m);
      inset-inline-end: var(--vp-semantic-spacing-m);
    }
  }
}

.creation-project {
  background-color: var(--vp-semantic-color-background-alternative);
  padding: var(--vp-semantic-spacing-xl) 0;
  margin-bottom: -(var(--vp-semantic-spacing-m));

  &__container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 2fr minmax(min-content, max-content) 2fr;
    grid-template-areas:
      'title picture'
      'description picture'
      'button picture';

    @include s-max {
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas:
        'title'
        'description'
        'picture'
        'button';
    }
  }

  &__title {
    grid-area: title;
    align-self: end;
    color: var(--vp-semantic-color-content-neutral);

    @include s-max {
      margin-bottom: var(--vp-semantic-spacing-m);
    }
  }

  &__description {
    grid-area: description;
    align-self: center;
    padding: var(--vp-semantic-spacing-xl) 0;

    @include s-max {
      padding: 0;
    }
  }

  &__buttons {
    grid-area: button;
    display: flex;
    gap: calc(var(--vp-semantic-spacing-xs) * 2);
    justify-content: flex-start;

    @include m-max {
      flex-direction: column;
      align-items: center;
      a {
        inline-size: 100%;
      }
    }
  }

  &__picture {
    grid-area: picture;
    display: flex;
    justify-content: center;
    align-items: center;

    @include s-max {
      margin: var(--vp-semantic-spacing-m) 0;
    }
  }
}

.complete-profile {
  &--not-homepage {
    margin-top: var(--vp-semantic-spacing-6xl);
  }

  &--banner {
    padding: 0;
    background-color: var(--vp-semantic-color-container-neutral);
  }

  &__banner-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--vp-semantic-spacing-m);

    @include m-min {
      flex-direction: row;
      justify-content: center;
    }
  }

  .vp-button__label svg {
    color: var(--vp-semantic-color-content-primary);
  }
}

.top-projects {
  padding: var(--vp-semantic-spacing-3xl) 0 var(--vp-semantic-spacing-xl);

  &__staring {
    @include l-min {
      padding-bottom: var(--vp-semantic-spacing-5xl);
    }
  }

  &__content {
    height: 100%;
  }

  &__inner {
    margin-top: var(--vp-semantic-spacing-m);

    &--flex {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-4xl);
      justify-content: space-between;
    }
  }

  @include l-min {
    &__inner {
      gap: var(--vp-semantic-spacing-xl);
      flex-direction: row;
    }

    &__staring {
      flex: 1;
    }

    &__scroller {
      inline-size: rem(420);
      max-inline-size: rem(420);
    }
  }

  @include m-max {
    padding-bottom: calc(var(--vp-semantic-spacing-xs) * 7);
  }
}

.all-projects {
  padding-block: var(--vp-semantic-spacing-4xl);

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
      width: 100%;

      @include m-min {
        width: auto;
      }
    }
  }

  .projects-list {
    align-self: stretch;
    margin-block-start: var(--vp-semantic-spacing-2xl);
  }
}

// =============
// Project Item
// =============
.project-item {
  $self: &;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  overflow: hidden;
  border-radius: var(--vp-semantic-border-radius-main);
  gap: var(--vp-semantic-spacing-m);

  // Followed projects
  &--tail {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xs);
  }

  &__access {
    position: relative;
    background-color: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-semantic-border-radius-main);
    flex: 1;
  }

  &__access-link {
    display: block;
    padding: var(--vp-semantic-spacing-m);
    padding-right: var(--vp-semantic-spacing-2xl);
    padding-bottom: var(--vp-semantic-spacing-xl);
    border-radius: var(--vp-semantic-border-radius-main);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    font-size: rem(14);
    font-weight: 600;
    height: 100%;

    #{$self}__goto {
      background-color: var(--vp-semantic-color-container-brand);
    }

    &:hover,
    &:focus {
      border-color: var(--vp-semantic-color-border-brand);
    }
  }

  &--horizontal {
    align-items: flex-start;
    flex-direction: row;

    #{$self}__link {
      min-width: 50%;
    }

    .top-projects__staring & {
      display: block;
      gap: var(--vp-semantic-spacing-xl);
      height: 100%;

      @include s-max {
        flex-direction: column;
      }

      #{$self}__link {
        @include s-max {
          min-width: 100%;
        }
      }

      #{$self}__link,
      #{$self}__header,
      #{$self}__media {
        height: 100%;
      }

      #{$self}__body {
        padding-top: var(--vp-semantic-spacing-m);

        @include m-min {
          background-color: var(--vp-semantic-color-background-main);
          padding: var(--vp-semantic-spacing-2xl) var(--vp-semantic-spacing-2xl)
            var(--vp-semantic-spacing-3xl);

          max-width: math.div(100%, math.div(5, 2));

          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      #{$self}__goto {
        z-index: 5;
      }

      #{$self}__media {
        @include m-min {
          max-width: math.div(100%, math.div(5, 4));
        }
      }
    }

    #{$self}__body {
      padding: 0;
      padding-top: var(--vp-semantic-spacing-xs);
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    border-radius: var(--vp-semantic-border-radius-main);
    transition: all 0.2s ease-in-out;
    &:hover #{$self}__media img {
      transform: scale(1.05);
      filter: brightness(1.2);
    }

    &:focus #{$self}__media::after {
      opacity: 0.8;
    }
  }

  &__text-link {
    text-decoration: none;
    &:focus {
      outline: none;
    }
  }

  &__header {
    display: block;
    flex-grow: 0;
    position: relative;
    overflow: hidden;

    // Sticker on picture
    #{$self}__sticker {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: var(--vp-semantic-spacing-2xs);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xs);

    font-weight: 600;

    position: relative;
    padding: 0 var(--vp-semantic-spacing-xs);

    > * {
      display: block;
    }

    .top-projects__staring & {
      font-weight: 500;
    }
  }

  &__media {
    display: block;
    position: relative;
    border-radius: var(--vp-semantic-border-radius-main);
    overflow: hidden;

    &::before {
      display: block;
      content: '';
      background: var(--vp-semantic-color-background-alternative);
      padding-top: math.div(1080%, 19.2);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--vp-semantic-border-radius-main);
      border: 2px solid var(--vp-semantic-color-border-brand);
      transition: all 0.2s ease;
      color: inherit;
      opacity: 0;
    }

    img {
      display: block;
      background-color: var(--vp-semantic-color-background-main);
      @include broken-link();
      transition: all 1s ease;
      transform-origin: center center;
    }
  }

  &__sticker {
    display: inline-block;
    padding: 0 var(--vp-semantic-spacing-xs);
    border-radius: var(--vp-semantic-border-radius-main);
    background-color: var(--vp-semantic-color-background-main);
    font-size: rem(12);
    line-height: rem(20);
    white-space: nowrap;
    &--new {
      color: var(--vp-semantic-color-content-on-commercial-neutral);
      background-color: var(--vp-semantic-color-container-commercial-neutral);
    }
    &--finished {
      color: var(--vp-semantic-color-content-neutral);
      background-color: var(--vp-semantic-color-container-quiet);
    }
  }

  &__goto {
    display: flex;
    position: absolute;
    width: var(--vp-semantic-spacing-2xl);
    height: var(--vp-semantic-spacing-2xl);
    line-height: var(--vp-semantic-spacing-2xl);
    right: 0;
    bottom: 0;
    border-top-left-radius: var(--vp-semantic-border-radius-s);
    border-bottom-right-radius: var(--vp-semantic-border-radius-main);
    background-color: var(--vp-semantic-color-container-brand);

    svg {
      color: var(--vp-semantic-color-content-on-brand-neutral);
    }

    .top-projects__staring & {
      width: var(--vp-semantic-spacing-3xl);
      height: var(--vp-semantic-spacing-3xl);
      line-height: var(--vp-semantic-spacing-3xl);
    }

    > * {
      margin: auto;
    }
  }

  &__title {
    display: block;
    margin-bottom: var(--vp-semantic-spacing-m);
    text-wrap: balance;
  }

  &__description {
    display: block;
  }
}

// ===============
// Scroller Mixins
// ===============
@mixin TwoItemsPerLine {
  // 16px x 4 gaps (each side of the scroller + one between items + one portion of next item),
  // divided by the number of displayed items
  width: calc(
    50vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 5), 2))}
  );
  min-width: calc(
    50vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 5), 2))}
  );
}

@mixin ThreeItemsPerLine {
  width: calc(
    #{math.div(100vw, 3)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 6), 3))}
  );
  min-width: calc(
    #{math.div(100vw, 3)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 6), 3))}
  );
}

@mixin FourItemsPerLine {
  width: calc(
    25vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 7), 4))}
  );
  min-width: calc(
    25vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 7), 4))}
  );
}

@mixin FiveItemsPerLine {
  width: calc(
    20vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 8), 5))}
  );
  min-width: calc(
    20vw - #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 8), 5))}
  );
}

@mixin SixItemsPerLine {
  width: calc(
    #{math.div($main-column-max-width, 6)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 7), 6))}
  );
  min-width: calc(
    #{math.div($main-column-max-width, 6)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 7), 6))}
  );
}

@mixin SixItemsPerLineExact {
  width: calc(
    #{math.div($main-column-max-width, 6)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 5), 6))}
  );
  min-width: calc(
    #{math.div($main-column-max-width, 6)} -
      #{calc(math.div(calc(var(--vp-semantic-spacing-s) * 2 * 5), 6))}
  );
}

@mixin responsiveScroller($self) {
  position: relative;

  &__container {
    gap: var(--vp-semantic-spacing-s);
  }

  #{$self}__item {
    width: calc(100vw - #{var(--vp-semantic-spacing-5xl)});
    min-width: calc(100vw - #{var(--vp-semantic-spacing-5xl)});
  }

  // Hide scrollbar
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  // Desktop navigation buttons
  .projects-scroller__arrows {
    position: absolute;
    cursor: pointer;

    @include m-min {
      bottom: var(--vp-semantic-spacing-xs);

      &--hidden {
        display: none;
      }
    }

    @include m-max {
      bottom: calc(-1 * var(--vp-semantic-spacing-3xl));
    }

    // override vitamin button icon span
    span {
      padding: 0 !important;
    }
  }
}

// =========================
// Followed Projects Scroller
// =========================
.followed-scroller {
  $self: &;
  padding-block-start: var(--vp-semantic-spacing-xl);

  @include responsiveScroller($self);
  position: initial;

  @include l-min {
    width: 100%;
  }

  .project-item {
    height: 100%;

    &__title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 0;
    }
  }

  #{$self}__item {
    // Two items per line
    @include s-min {
      @include TwoItemsPerLine;
    }

    // Three items per line
    @include m-min {
      @include ThreeItemsPerLine;
    }

    // Four items per line
    @include l-min {
      @include FourItemsPerLine;
    }

    @include xl-min {
      @include SixItemsPerLineExact;
    }
  }

  // Desktop navigation buttons
  .projects-scroller__arrows {
    top: 0;
    bottom: auto;

    &--left {
      right: var(--vp-semantic-spacing-5xl);
    }

    &--right {
      right: var(--vp-semantic-spacing-m);
    }
  }
}

// =========================
// Top Projects Scroller
// =========================
.top-projects-scroller {
  $self: &;

  padding-bottom: var(--vp-semantic-spacing-m);

  @include l-min {
    padding-bottom: var(--vp-semantic-spacing-5xl);
  }

  .project-item {
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include responsiveScroller($self);

  &__container {
    @include l-min {
      gap: 0;
    }
  }

  #{$self}__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: var(--vp-semantic-spacing-m);

    // Two items per line
    @include m-min {
      @include TwoItemsPerLine;
    }

    @include l-min {
      min-width: rem(420);
    }
  }

  // Desktop navigation buttons
  .projects-scroller__arrows {
    &--right {
      left: var(--vp-semantic-spacing-4xl);
    }

    &--left {
      left: 0;
    }
  }
}

// =============
// Skeleton
// =============
.skeleton {
  $self: &;

  &__arrow {
    @include skeleton();
    background: var(--vp-semantic-color-background-alternative);
    width: var(--vp-semantic-spacing-3xl);
    height: var(--vp-semantic-spacing-2xl);
  }

  &__item {
    @include skeleton();
    background: var(--vp-semantic-color-background-alternative);
    width: 100%;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include skeleton();

    .project-item__media & {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
    }
  }

  &-template {
    // reset background
    .project-item__media::before {
      background: none;
    }

    // =================
    // Followed Skeleton
    // =================
    &.followed-skeleton {
      $bg: var(--vp-semantic-color-background-alternative);

      #{$self}__arrow,
      #{$self}__item,
      #{$self}__media {
        background-color: $bg;
      }

      #{$self}__item {
        height: 100%;
      }

      .project-item__access {
        max-height: rem(156px);
        #{$self}__item {
          height: 100%;
        }
      }
    }

    // =========================
    // top-projects Skeleton
    // =========================
    &.top-projects-skeleton,
    &.all-projects-skeleton {
      $bg: #c0deed;
      #{$self}__arrow,
      #{$self}__item,
      #{$self}__media {
        background-color: $bg;

        .vtmn-theme_core--dark & {
          background-color: var(--vp-core-color-black);
        }
      }
    }

    // =================
    // Project list Skeleton
    // =================
    &.project-list-skeleton {
      $bg: var(--vp-semantic-color-background-alternative);
      #{$self}__arrow,
      #{$self}__item,
      #{$self}__media {
        background-color: $bg;
      }
    }
  }
}
