.panel {
  $self: &;
  background-color: var(--vp-semantic-color-background-alternative);
  border-radius: var(--vp-semantic-border-radius-s);

  & + & {
    margin-top: var(--vp-semantic-spacing-xl);
  }

  &__header {
    // @include gradient;
    border-top-left-radius: var(--vp-semantic-spacing-xs);
    border-top-right-radius: var(--vp-semantic-spacing-xs);
    padding: var(--vp-semantic-spacing-xl) var(--vp-semantic-spacing-m);
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xs);

    &,
    > * {
      color: var(--vp-semantic-color-content-neutral);
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: var(--vp-semantic-spacing-m);
    min-height: rem(80);
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-s);

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &--grey {
    #{$self}__header {
      background: var(--vp-semantic-color-background-alternative);
    }
  }

  &--white {
    #{$self}__header {
      background: var(--vp-semantic-color-background-main);
    }
  }

  &__contact {
    margin-top: 2rem;
  }
}
