@use 'sass:math';

.header {
  $self: &;
  display: flex;
  align-items: center;

  border-block-end: 1px solid var(--vp-semantic-color-border-quiet);
  background: var(--vp-semantic-color-background-main);

  height: rem(80);
  inset-inline-start: 0;
  inset-block-start: 0;
  position: sticky;
  width: 100%;

  z-index: 200;
  transition: transform 0.2s ease-out;

  @include m-min {
    height: rem(60);
  }

  .scrolled:not(.is-scrolling-up) & {
    transform: translateY(-100%);
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    text-decoration: none;

    .logo {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      width: 100%;
    }

    * {
      transition: color 0.2s ease-out;
    }

    a,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
    }

    a.active {
      pointer-events: none;
      color: var(--vp-semantic-color-content-neutral);
    }
  }

  &__logo-baseline {
    display: inline-block;
    font-size: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
  }

  &__nav-user {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: var(--vp-semantic-spacing-xs);

    .vtmn-loader_size--medium.vtmn-loader {
      inline-size: rem(40);
      block-size: rem(40);
    }
  }

  &__user-identifier {
    position: relative;
    line-height: 0;
  }

  &__messages {
    display: flex;
    align-items: center;
    text-decoration: none;

    &.active {
      background-color: var(--vp-semantic-color-background-alternative);
    }
  }

  &__avatar {
    background-color: transparent;
    border-radius: var(--vp-core-border-radius-600);

    &:hover {
      @include m-min {
        background-color: var(--vp-semantic-color-content-quiet);
      }
    }

    &--opened {
      &:focus {
        background-color: var(--vp-semantic-color-content-quiet);
      }
    }
  }

  &__button {
    align-items: center;
    display: inline-flex;
    gap: var(--vp-semantic-spacing-2xs);
    justify-content: center;

    background: transparent;
    color: var(--vp-semantic-color-content-neutral);
    cursor: pointer;
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    line-height: var(--vp-semantic-typography-screen-l-body-s-line-height);

    padding: 0 var(--vp-semantic-spacing-xs);

    white-space: nowrap;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus,
    &.active {
      color: var(--vp-semantic-color-content-brand);
      outline-color: var(--vp-semantic-color-border-brand);
    }
  }

  &__flyout {
    &__inner {
      border-block-end: 1px solid var(--vp-semantic-color-border-quiet);
      background: var(--vp-semantic-color-container-neutral);
      padding: var(--vp-semantic-spacing-m);

      position: fixed;
      inset: rem(80) 0 auto;
      z-index: 0;

      @include m-min {
        border: 1px solid var(--vp-semantic-color-border-quiet);
        margin-block-start: calc(var(--vp-semantic-spacing-xs) + 1px);
        position: absolute;
        inset: auto 0 auto auto;
      }
    }

    &::before {
      content: '';
      pointer-events: none;
      background: var(--vp-semantic-color-container-overlay);

      position: fixed;
      inset: rem(80) 0 0;
      z-index: -5;

      @include m-min {
        content: none;
      }
    }

    ul {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-m);

      list-style: none;

      li {
        text-align: center;

        @include m-min {
          text-align: start;
        }
      }

      > li:last-child {
        &::before {
          background: var(--vp-semantic-color-border-quiet);
          content: '';
          display: block;
          margin-block-end: var(--vp-semantic-spacing-s);
          block-size: 1px;
        }
      }
    }
  }

  &__language {
    min-width: 30px;
    display: flex;
    margin: var(--vp-semantic-spacing-xs);
    cursor: pointer;
  }
}

.cocreation-logo {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-xs);

  @include m-min {
    align-items: center;
    flex-direction: row;
  }

  svg {
    width: 100%;
    height: auto;
    max-width: rem(154);

    @include m-min {
      width: rem(186);
    }
  }

  span {
    color: var(--vp-semantic-color-content-brand);
    font-weight: 500;
    font-size: var(--vp-semantic-typography-screen-s-title-s-font-size);
    line-height: 1;

    @include m-min {
      font-size: var(--vp-semantic-typography-screen-m-title-m-font-size);
    }

    .vp--light-mode & {
      opacity: 0.8;
    }
  }
}

.locales {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  line-height: 1;

  &__icon {
    padding: var(--vp-semantic-spacing-2xs);
    @include s-max {
      display: none;
    }
  }

  &__button {
    font-size: rem(14);
    font-weight: 600;
    padding: var(--vp-semantic-spacing-2xs);
    margin-right: var(--vp-semantic-spacing-xs);
    white-space: nowrap;
    cursor: pointer;
  }

  &__flyout > .flyout__inner {
    min-width: rem(135);
  }

  &__option {
    display: block;
    width: 100%;
    text-align: left;
    font-size: rem(14);
    color: var(--vp-semantic-color-content-quiet);
    padding: var(--vp-semantic-spacing-xs);
    margin: var(--vp-semantic-spacing-3xs) 0;
    background-color: transparent;
    border: none;
    border-radius: var(--vp-core-border-radius-100);
    cursor: pointer;
    transition: backgroundColor 0.2s ease;
    white-space: nowrap;

    &:hover {
      background-color: var(--vp-semantic-color-background-alternative);
    }

    &--selected {
      font-weight: 600;
      color: var(--vp-semantic-color-content-brand);
    }
  }
}
