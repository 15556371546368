@use 'sass:math';

// fix scrollbar
.infinite-scroll-component {
  overflow: visible !important;
}

// Question grid
.thread-questions {
  display: block;
  width: 100% !important;
  margin: var(--vp-semantic-spacing-xl) 0;

  &__item {
    display: block;
    overflow: hidden;
    margin-bottom: var(--vp-semantic-spacing-m);
    border-radius: var(--vp-semantic-border-radius-s);
    background-color: var(--vp-semantic-color-background-main);
    border: 1px solid var(--vp-semantic-color-border-catchy);
    width: 100%;

    @include m-min {
      width: calc(50% - #{var(--vp-semantic-spacing-xs)});
    }

    @include l-min {
      width: calc(
        (100% / 3) - #{calc(var(--vp-semantic-spacing-xs) * (math.div(2, 3)))}
      );
    }
  }

  header {
    padding: var(--vp-semantic-spacing-xl) var(--vp-semantic-spacing-m)
      var(--vp-semantic-spacing-m);
    background-color: var(--vp-semantic-color-background-main);
    color: var(--vp-semantic-color-content-neutral);

    > * {
      color: var(--vp-semantic-color-content-neutral);
    }

    p {
      margin: 0;
    }
  }

  &__title {
    margin-bottom: var(--vp-semantic-spacing-xs);
    color: var(--vp-semantic-color-content-quiet);
  }

  footer {
    text-align: right;
    padding: var(--vp-semantic-spacing-xl) var(--vp-semantic-spacing-m)
      var(--vp-semantic-spacing-m);

    button {
      margin: auto;
    }
  }

  &__most {
    display: grid;
    grid-template-columns: rem(30) minmax(0, 1fr);
    column-gap: var(--vp-semantic-spacing-xs);
    row-gap: var(--vp-semantic-spacing-xs);
    margin: var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-m) 0;
    font-size: rem(12);
  }

  &__most-title {
    grid-column: 2;
    color: var(--vp-semantic-color-status-info);
    font-size: rem(14);
  }

  &__most-icon {
    display: inline-block;
    margin-right: var(--vp-semantic-spacing-xs);
    font-size: rem(16);

    &--like {
      color: var(--vp-semantic-color-status-negative);
    }
    &--comment {
      color: var(--vp-semantic-color-status-info);
    }
  }

  &__most-content {
    padding: var(--vp-semantic-spacing-xs);
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-s);
    word-break: break-word;
  }

  &__most-firstname {
    display: block;
    font-weight: 600;
    margin-bottom: var(--vp-semantic-spacing-xs);
  }

  &__link {
    display: block;
    position: relative;
    margin-bottom: var(--vp-semantic-spacing-xs);
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-2xl)
      var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-xl);
    background-color: var(--vp-semantic-color-background-main);
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: 500;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__arrow {
    position: absolute;
    right: rem(10);
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(14);
  }
}

// Graphic styles
$reaction: thread-idea__reaction;
.thread-idea {
  $self: &;
  margin-top: var(--vp-semantic-spacing-m);

  &__layout {
    display: grid;
    grid-template-columns: rem(32) minmax(0, 1fr) auto;
    column-gap: var(--vp-semantic-spacing-xs);
    row-gap: var(--vp-semantic-spacing-xs);
    align-items: center;
  }

  &__actions {
    grid-column: 2/3;
    display: flex;
    justify-content: flex;
  }

  &__comments-opener {
    margin-left: var(--vp-semantic-spacing-xl);
  }

  &__comments-list {
    margin-top: var(--vp-semantic-spacing-xs);
    @include s-max {
      grid-column: 1/4;
      margin-left: var(--vp-semantic-spacing-m);
    }
    @include m-min {
      grid-column: 2/4;
    }

    &__see-more-action {
      display: flex;
      justify-content: center;
      margin-top: var(--vp-semantic-spacing-xl);
    }
  }

  &__comment {
    display: grid;
    grid-template-columns: rem(32) minmax(0, 1fr) auto;
    column-gap: var(--vp-semantic-spacing-xs);
    row-gap: var(--vp-semantic-spacing-xs);
    align-items: center;
    margin-bottom: var(--vp-semantic-spacing-m);
  }

  &__author {
    align-self: flex-start;
    width: 100%;
    flex: 0 0 auto;

    .avatar {
      font-size: rem(24);
      width: 100%;
    }

    &--admin .avatar {
      border: 1px solid var(--vp-semantic-color-border-inverse);
    }
  }

  &__date {
    font-size: rem(12);
    color: var(--vp-semantic-color-content-quiet);
  }

  &__opener {
    padding: rem(2);
    border: none;
    background: none;
    text-align: right;
    font-size: rem(11);
    line-height: math.div(17em, 14);
    color: var(--vp-semantic-color-content-quiet);
    cursor: pointer;

    &:hover {
      color: var(--vp-semantic-color-content-neutral);
    }

    &[disabled] {
      color: inherit;
      opacity: 0.8;
      pointer-events: none;
    }
  }

  &__content {
    display: grid;

    &--highlighted {
      padding: var(--vp-semantic-spacing-xs);
      background-color: background-brand-secondary;
      border: 1px solid border-active;
      border-radius: var(--vp-semantic-border-radius-s);
    }
  }

  &__identity {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    margin-bottom: var(--vp-semantic-spacing-2xs);

    > * {
      margin: var(--vp-semantic-spacing-3xs) var(--vp-semantic-spacing-xs)
        var(--vp-semantic-spacing-3xs) 0;
    }
  }

  &__content-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > span {
      min-width: rem(65);
    }
  }

  &__text {
    flex: 1;
    color: var(--vp-semantic-color-content-neutral);
    font-size: rem(14);
    line-height: math.div(20em, 14);
    word-break: break-word;
  }

  &__picture {
    position: relative;
    overflow: hidden;
    align-self: flex-start;
    margin-left: var(--vp-semantic-spacing-xs);

    &-wrapper {
      display: block;
      position: relative;
      overflow: hidden;
      border-radius: var(--vp-core-border-radius-100);

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    .zoom-button {
      opacity: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--vp-semantic-border-radius-main);
      cursor: pointer;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  &__reactions {
    min-width: rem(40);
    > * {
      vertical-align: middle;
    }
  }

  &__reaction-button {
    display: flex;
    align-items: center;
    padding: rem(2);
    white-space: nowrap;
    border: 0;
    background: none;
    font-size: rem(11);
    color: var(--vp-semantic-color-content-quiet);
    cursor: pointer;

    > [class^='vtmx'] {
      margin-right: var(--vp-semantic-spacing-xs);
      transition: all 0.2s linear;
      transform-origin: center center;
      font-size: rem(14);
    }

    &:hover {
      color: var(--vp-semantic-color-content-neutral);
    }

    &[disabled] {
      color: inherit;
      opacity: 0.6;
      pointer-events: none;
    }

    &--active {
      color: var(--vp-semantic-color-content-brand);
      font-weight: 600;

      &:hover {
        color: var(--vp-semantic-color-content-brand);
      }

      > svg {
        animation: scalePop 0.8s ease-out 0ms 1 backwards;
        color: var(--vp-semantic-color-content-brand);
      }
    }
  }
}

.comment-post {
  $cp: &;
  margin-bottom: var(--vp-semantic-spacing-m);

  &--idea {
    padding: 0;
  }

  &__form {
    display: flex;
    gap: var(--vp-semantic-spacing-m);

    &__textarea {
      inline-size: 100%;

      textarea {
        block-size: 100%;
      }
    }

    button {
      align-self: flex-end;
    }
  }

  &__button {
    button {
      margin: 0;
    }
  }
}

.load-more {
  padding-bottom: var(--vp-semantic-spacing-xs);
  text-align: center;
  margin: var(--vp-semantic-spacing-xs) auto;
}
