.delete-account-dialog {
  display: flex;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-m);

  &__actions {
    display: flex;
    gap: var(--vp-semantic-spacing-m);
  }
}
