.back-button {
  margin-block: var(--vp-semantic-spacing-m);

  a {
    text-decoration: none;
  }

  &.vp-button {
    align-self: start;
  }
}
