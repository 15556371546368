.entity {
  margin-bottom: var(--vp-semantic-spacing-m);

  .panel__header {
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-m);
    margin-block-start: var(--vp-semantic-spacing-xl);
  }

  &__media {
    display: block;
    margin: var(--vp-semantic-spacing-m) auto;
    text-align: center;
  }

  &__picture {
    display: inline-block;
    position: relative;
    min-height: rem(200);
    max-height: 40vh;

    // reset next/image for display specificities
    span[style] > span[style] {
      display: none !important;
    }

    img {
      display: block;
      margin: auto;
      @include resetNextImage();
    }
  }
}
