@use 'sass:math';

.share-popin {
  gap: var(--vp-semantic-spacing-m);
  text-align: center;

  &,
  ul {
    display: flex;
    flex-direction: column;
  }

  ul {
    gap: var(--vp-semantic-spacing-xs);
  }
}
