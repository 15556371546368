@import 'rc-slider/assets/index.css';

.rc-slider-tooltip {
  z-index: 100;
}

.rc-slider.SCALE,
.rc-slider.RANKING_SCALE,
.rc-slider.VAS {
  height: rem(30);
  transition: all 0.4s ease-out;

  .rc-slider-rail {
    background-color: var(--vp-semantic-color-border-quiet) !important;
    border-radius: var(--vp-semantic-border-radius-s);
  }

  .rc-slider-track,
  .rc-slider-rail {
    height: var(--vp-semantic-spacing-m);
  }

  .rc-slider-dot {
    display: none;
  }

  .rc-slider-handle {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    border-color: var(--vp-semantic-color-border-inverse);
    border-width: 2px;
    opacity: 1;

    block-size: var(--vp-semantic-spacing-3xl);
    inline-size: var(--vp-semantic-spacing-3xl);
    margin-block-start: rem(-12);
  }
}

.rc-slider.RANKING_SCALE.RESULT {
  .rc-slider-handle {
    cursor: default;
  }
}

.rc-slider.SCALE.RESULT {
  .rc-slider-handle {
    cursor: default;
  }
}

// ============ SCALE ================
.rc-slider.SCALE {
  margin-bottom: var(--vp-semantic-spacing-xs);
  padding: 0;

  &.unanswered {
    opacity: 0.8;
    filter: grayscale(100%);
  }

  .rc-slider-track {
    background-color: var(--vp-semantic-color-container-brand);
  }

  .rc-slider-step {
    height: var(--vp-semantic-spacing-xs);
  }

  .rc-slider-handle {
    background-color: var(--vp-semantic-color-background-main);
    border-color: var(--vp-semantic-color-border-neutral);
    box-shadow: 0px 6px 6px 0px #00537d1a;
  }

  .rc-slider-mark {
    margin-top: var(--vp-semantic-spacing-m);
    word-break: initial;
    color: var(--vp-semantic-color-content-quiet);
    font-size: rem(14);
  }
}

// ============ RANKING SCALE ================

.ranking-scale-choices-caption {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  margin-bottom: rem(20);
  gap: var(--vp-semantic-spacing-s);

  > div {
    display: flex;
    align-items: center;
    gap: var(--vp-semantic-spacing-xs);
  }

  .item-letter {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    background: var(--vp-semantic-color-container-brand);
    border-radius: var(--vp-semantic-border-radius-full);
    color: var(--vp-semantic-color-content-on-brand-neutral);

    text-align: center;
    font-weight: 600;
    font-size: rem(14);
    text-transform: uppercase;

    width: rem(22);
    height: rem(22);
  }

  .item-label {
    font-size: rem(12);
    line-height: rem(22);
    color: var(--vp-semantic-color-content-neutral);
  }
}

.ranking-scale-labels {
  display: flex;
  justify-content: space-between;
  font-size: rem(12);
  margin-bottom: var(--vp-semantic-spacing-xs);
}

.ranking-scale-container {
  position: relative;

  &__animation {
    pointer-events: none;

    position: absolute;
    left: 0;
    top: 60%;
    width: rem(120);

    transform: translate(-30%, -50%);
    z-index: 5;
  }

  .rc-slider.RANKING_SCALE {
    pointer-events: none;
    margin-bottom: var(--vp-semantic-spacing-3xl);

    // select all sliders but the first
    &:nth-child(n + 2) {
      position: absolute;
      left: 0;
      top: 0;

      // hide the rail to keep only the first
      .rc-slider-rail {
        visibility: hidden;
        background-color: transparent !important;
      }
    }

    &.unanswered {
      .rc-slider-handle {
        border-color: #7a83d1;
        background-color: #7a83d1;
      }
    }

    .rc-slider-track {
      background-color: transparent;
    }

    .rc-slider-handle {
      background-color: var(--vp-semantic-color-container-brand);
      border-color: var(--vp-semantic-color-container-brand);
      box-shadow: 0px 6px 6px 0px #00537d1a;
      color: var(--vp-semantic-color-content-on-brand-neutral);

      font-weight: 600;
      font-size: rem(14);
      line-height: calc(var(--vp-semantic-spacing-3xl) - 2);
      text-align: center;
      text-transform: uppercase;

      pointer-events: auto;
    }

    .rc-slider-dot-active {
      border-color: var(--vp-semantic-color-border-brand);
    }

    .rc-slider-step {
      height: var(--vp-semantic-spacing-m);
    }

    .rc-slider-mark {
      margin-top: rem(6);
    }
  }
}

// ============ VAS ================

.rc-slider.VAS {
  margin-bottom: rem(20);

  &.unanswered {
    opacity: 0.9;
    filter: grayscale(100%);
  }

  .rc-slider-handle {
    background-color: var(--vp-semantic-color-background-main);
    border-color: var(--vp-semantic-color-border-neutral);
    box-shadow: 0px 6px 6px 0px #00537d1a;
  }

  .rc-slider-track {
    height: var(--vp-semantic-spacing-m);
    background-color: transparent;
  }

  .rc-slider-rail {
    height: var(--vp-semantic-spacing-m);
  }

  .rc-slider-step {
    height: var(--vp-semantic-spacing-m);
  }

  .rc-slider-dot {
    display: none;
  }
}

.rc-slider.VAS1 .rc-slider-rail {
  background: linear-gradient(
    0.25turn,
    var(--vp-semantic-color-status-negative),
    var(--vp-semantic-color-container-catchy),
    var(--vp-semantic-color-status-positive)
  ) !important;
}

.rc-slider.VAS2 .rc-slider-rail {
  background: linear-gradient(
    0.25turn,
    var(--vp-semantic-color-status-negative),
    var(--vp-semantic-color-container-catchy),
    var(--vp-semantic-color-status-positive),
    var(--vp-semantic-color-container-catchy),
    var(--vp-semantic-color-status-negative)
  ) !important;
}

.rc-slider.VASResult {
  margin-bottom: 0;
  margin-top: var(--vp-semantic-spacing-xl);

  .rc-slider-handle {
    cursor: default;
  }
}

.vas {
  &__slide {
    position: relative;
  }

  &__animation {
    width: rem(120);
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-35%, -50%);
    pointer-events: none;
  }

  &__average {
    display: block;
    text-align: center;
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-s);
    padding: var(--vp-semantic-spacing-xs);
    margin-top: var(--vp-semantic-spacing-xs);
    margin-bottom: var(--vp-semantic-spacing-xs);
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    font-size: rem(14);
    color: var(--vp-semantic-color-content-neutral);

    span {
      text-align: center;
      margin-bottom: var(--vp-semantic-spacing-xs);
      flex: 3;
    }

    span:first-of-type {
      text-align: left;
    }

    span:last-of-type {
      text-align: right;
    }
  }
}

.children_information {
  text-align: center;

  a {
    cursor: pointer;
    color: var(--vp-semantic-color-status-info);
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
    color: var(--vp-semantic-color-content-quiet);
    text-decoration: none;
  }
}

.restricted-access {
  margin: rem(50) rem(30) rem(20);
  padding: rem(30) rem(20);
  text-align: center;
  background: background-brand-secondary;
  border: 1px solid var(--vp-semantic-color-border-neutral);
  border-radius: rem(10);

  @include m-min {
    max-inline-size: rem(500);
    margin-left: auto;
    margin-right: auto;
  }

  strong {
    display: block;
    font-size: rem(20);
  }
  *:last-of-type {
    margin-bottom: 0;
  }
}
