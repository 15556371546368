@use 'sass:math';

.notification-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__labels {
    padding-right: var(--vp-semantic-spacing-xs);
  }

  &__description {
    color: var(--vp-semantic-color-content-quiet);
  }

  &__subjects {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xs);

    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__favorite-sports {
    display: inline-flex;
    align-items: center;
    gap: var(--vp-semantic-spacing-2xs);
    color: var(--vp-semantic-color-content-brand);

    &__link {
      text-decoration: underline;
    }
  }
}

// Common profile
.profile {
  margin: 0 auto;
  max-width: rem(980);
  background-color: var(--vp-semantic-color-background-main);

  @include m-min {
    display: grid;
    height: 100%;
    width: calc(100vw - #{var(--vp-semantic-spacing-2xl)});
    grid-template-rows: 1fr auto;
    grid-template-columns: minmax(0, rem(320)) minmax(0, 1fr);
  }

  &__header {
    display: flex;
    flex-direction: column;

    @include m-min {
      grid-column: 1 / -1;
      grid-row: 1;
    }
  }

  &__title {
    padding: 0 0 var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-l);

    @include m-min {
      padding: var(--vp-semantic-spacing-5xl) 0 var(--vp-semantic-spacing-2xl);
    }
  }

  &__subtitle {
    color: var(--vp-semantic-color-content-quiet);
  }

  &__action {
    display: flex;
    align-items: center;
    gap: var(--vp-semantic-spacing-l);

    .vp-accordion & {
      margin-block-start: var(--vp-semantic-spacing-m);
    }
  }

  &__aside {
    grid-row: 2;
    grid-column: 1;

    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xl);

    padding-block-end: var(--vp-semantic-spacing-2xl);

    &__completion {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-2xs);

      @include s-max {
        padding-inline: var(--vp-semantic-spacing-m);
      }

      > p {
        color: var(--vp-semantic-color-content-brand);
      }

      &__progress {
        background: var(--vp-semantic-color-container-quiet);
        border-radius: var(--vp-semantic-border-radius-s);

        block-size: var(--vp-semantic-spacing-m);
        overflow: hidden;
        position: relative;

        &__bar {
          background: var(--vp-semantic-color-content-brand);
          height: 100%;
          position: absolute;

          transition: width 1s ease-in-out;
        }
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-xs);

        background-color: var(--vp-semantic-color-container-commercial-neutral);
        border-radius: var(--vp-semantic-border-radius-s);

        margin-block-start: var(--vp-semantic-spacing-s);
        padding: var(--vp-semantic-spacing-m);

        .vp-subtitle-m {
          display: flex;
          align-items: flex-end;
        }

        p {
          color: var(--vp-semantic-color-container-brand);
        }
      }
    }
  }

  &__nav {
    border-right: 1px solid var(--vp-semantic-color-border-quiet);

    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xs);

    @include m-min {
      padding-inline-end: var(--vp-semantic-spacing-m);
    }
  }

  &__picture {
    margin: var(--vp-semantic-spacing-m) 0;
  }

  &__avatar {
    background: none;
    appearance: none;
    cursor: pointer;

    align-items: center;
    display: flex;
    gap: var(--vp-semantic-spacing-xs);
  }

  .profile__nav {
    .vp-link-list {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-xs);
    }

    .vp-link-list__item-link {
      color: var(--vp-semantic-color-content-neutral);
      padding: var(--vp-semantic-spacing-s) var(--vp-semantic-spacing-m);
      border-radius: var(--vp-semantic-border-radius-s);
      text-decoration: none;

      @include m-min {
        border-left: 4px solid transparent;
      }

      &:hover {
        background-color: var(--vp-semantic-color-container-quiet);

        @include m-min {
          border-left: 4px solid var(--vp-semantic-color-container-quiet);
        }
      }

      &.active {
        @include m-min {
          background-color: var(--vp-semantic-color-container-quiet);
          border-left: 4px solid var(--vp-semantic-color-border-brand);
          border-radius: 0 var(--vp-semantic-border-radius-s)
            var(--vp-semantic-border-radius-s) 0;
          font-weight: var(
            --vp-semantic-typography-screen-l-subtitle-m-font-weight
          );
          pointer-events: none;
        }
      }

      .profile__link-list__item {
        align-items: center;
        display: flex;
        gap: var(--vp-semantic-spacing-xs);

        inline-size: 100%;

        > .vp-sticker {
          margin-inline: auto var(--vp-semantic-spacing-xs);
        }

        &__label {
          align-items: center;
          display: flex;
          gap: var(--vp-semantic-spacing-s);
        }
      }
    }

    .vp-link-list__item-link:not(.profile__avatar) > svg {
      @include m-min {
        display: none;
      }
    }
  }

  &__main {
    @include m-min {
      grid-row: 2;
      grid-column: 2;
    }

    @include s-max {
      display: flex;
      flex-direction: column;
      background-color: var(--vp-semantic-color-background-main);
    }
  }

  &__page-content {
    flex: 1;
    padding: 0 var(--vp-semantic-spacing-l) var(--vp-semantic-spacing-2xl);

    @include m-min {
      padding: 0;
      margin-inline-start: var(--vp-semantic-spacing-6xl);
      margin-block-end: var(--vp-semantic-spacing-5xl);
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xs);

    position: relative;

    &--row {
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;
    }

    &--col-2 {
      flex-direction: row;

      > div:first-of-type {
        inline-size: 50%;

        @include l-min {
          inline-size: 30%;
        }
      }

      > div:last-of-type {
        inline-size: 100%;
      }
    }

    &--m {
      gap: var(--vp-semantic-spacing-m);
    }

    .vp-accordion & {
      padding-inline-start: var(--vp-semantic-spacing-m);
    }

    .vtmn-text-input_label {
      margin-bottom: 0;
    }

    input {
      padding-right: var(--vp-semantic-spacing-3xl);

      .vp--dark-mode & {
        color-scheme: dark;
      }

      .vtmn-theme_core--dark & {
        color-scheme: dark;
      }
    }

    .vtmn-text-input_container {
      align-items: stretch;
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  &__delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: none;
    width: var(--vp-semantic-spacing-4xl);
    height: var(--vp-semantic-spacing-4xl);
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: rem(16);
    color: var(--vp-semantic-color-content-quiet);
    cursor: pointer;

    &:hover {
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__fieldgroup {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xl);

    margin-block: var(--vp-semantic-spacing-2xl);

    &:has(.vp-accordion) {
      margin-block-end: 0;
    }

    .vp-accordion & {
      padding-block-start: 0;
    }

    &--link-list {
      margin-block-end: var(--vp-semantic-spacing-m);
    }

    &:first-child {
      padding-block-start: 0;
    }
  }

  &__fieldgroup + &__fieldgroup {
    @include customDividerStart();
    padding-block-start: var(--vp-semantic-spacing-2xl);
    margin-block: 0 var(--vp-semantic-spacing-2xl);
  }

  .vtmn-text-input_label {
    color: var(--vp-semantic-color-content-neutral);
  }

  .vtmn-text-input_container {
    align-items: stretch;
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.children-form {
  &__radios {
    display: flex;
    flex-direction: column;
    margin-top: var(--vp-semantic-spacing-m);
  }

  &__radios > * + * {
    margin-top: var(--vp-semantic-spacing-xs);
  }

  &__label {
    width: 100%;
  }

  &__next-button {
    margin-top: var(--vp-semantic-spacing-6xl);
  }
}

.child {
  &__criteria {
    > div {
      margin-top: var(--vp-semantic-spacing-2xl);
    }
  }
}

.favourite-sports {
  &__fieldset + &__fieldset {
    margin-top: var(--vp-semantic-spacing-2xl);
  }

  & &__list {
    margin-block: var(--vp-semantic-spacing-2xl);

    .child & {
      margin-block: 0;
    }
  }
}

.delete-dialog-checkbox {
  max-inline-size: rem(560);

  .vp-checkbox__label {
    flex: auto;
  }
}
