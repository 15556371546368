@use 'sass:math';

.info {
  align-items: center;
  display: flex;
  gap: var(--vp-semantic-spacing-xs);

  margin-top: var(--vp-semantic-spacing-xs);
  padding: var(--vp-semantic-spacing-m);

  color: var(--vp-semantic-color-content-quiet);
  background-color: var(--vp-semantic-color-alpha-brand-s);

  border-radius: var(--vp-semantic-border-radius-s);
}
