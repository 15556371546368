@use 'sass:math';

.collapsible-panel {
  $self: &;

  & + & {
    margin-top: rem(15);
  }

  // Elements

  &__header {
    background: var(--vp-semantic-color-background-main);
    border-left: rem(2) solid var(--vp-semantic-color-status-info);
    cursor: pointer;
    position: relative;
    padding: rem(15) rem(40) rem(15) rem(15);
    transition: all 0.2s ease-out;
  }

  &__inline-picture {
    vertical-align: middle;
  }

  &__content {
    padding: rem(15) rem(10);
    background: var(--vp-semantic-color-background-main);
    border: 2px solid var(--vp-semantic-color-status-info);
    border-top: 0;
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    @include m-min {
      padding: rem(30);
    }

    p {
      margin: 0;
    }

    img {
      vertical-align: middle;
    }
  }

  &__icon {
    position: absolute;
    right: rem(20);
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(18);
    transform-origin: center center;

    > svg {
      display: block;
      transition: all 0.2s ease-out;
    }
  }

  &__grid-template {
    display: grid;
    grid-template-columns: rem(60) minmax(0, 1fr);
    row-gap: var(--vp-semantic-spacing-m);

    @include m-min {
      grid-template-columns: rem(65) 1fr rem(65) 1fr;

      > * {
        padding: var(--vp-semantic-spacing-m);
      }

      div:nth-child(8n + 1),
      div:nth-child(8n + 2),
      div:nth-child(8n + 3),
      div:nth-child(8n + 4) {
        background-color: var(--vp-semantic-color-background-alternative);
      }
    }
  }

  &__grid-icon {
    padding: rem(12) 0 0;
    text-align: center;
    color: var(--vp-semantic-color-status-info);
  }

  &__grid-content {
    padding: rem(12) 0;
    word-wrap: break-word;

    @include m-min {
      padding: rem(12);
    }

    a {
      color: var(--vp-semantic-color-status-info);
      text-decoration: underline;
    }

    p:first-of-type {
      margin-bottom: rem(4);
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  // Modifiers
  &--default,
  &--secondary {
    #{$self}__header {
      text-transform: uppercase;
    }
  }

  &--primary {
    #{$self}__header {
      background: var(--vp-semantic-color-container-brand);
      color: var(--vp-semantic-color-content-on-brand-neutral);
    }

    #{$self}__icon > .vtmx-chevron-down-line {
      .vtmn-new-visual-identity & {
        color: var(--vp-semantic-color-content-on-brand-neutral) !important;
      }
    }
  }

  &--secondary {
    #{$self}__header {
      background: var(--vp-semantic-color-background-alternative);
    }
  }

  &--closed {
    #{$self}__body {
      height: 0;
      overflow: hidden;
    }
  }

  &--opened {
    #{$self}__header {
      background-color: var(--vp-semantic-color-container-brand);
      color: var(--vp-semantic-color-content-on-brand-neutral);
    }
    #{$self}__icon > .vtmx-chevron-down-line {
      .vtmn-new-visual-identity & {
        color: var(--vp-semantic-color-content-on-brand-neutral) !important;
      }
      transform: rotate(180deg);
    }
  }
}
