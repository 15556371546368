@use 'sass:math';

@mixin leftTooltip() {
  .flyout__inner {
    left: auto;
    right: 0;
    transform: translateX(0);
    transform-origin: right center;
  }
}

@mixin rightTooltip() {
  .flyout__inner {
    left: 0;
    transform: translateX(0);
    transform-origin: left center;
  }
}

.flyout {
  $self: &;

  &__inner {
    box-shadow: 0 0.375rem 0.375rem 0 var(--vp-semantic-color-alpha-brand-s);
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--vp-semantic-color-background-main);
    padding: var(--vp-semantic-spacing-xs);
    border-radius: var(--vp-semantic-border-radius-s);
    animation: fadeIn 0.1s ease-out;
    transform-origin: center center;
    z-index: 90;

    @include s-max {
      min-width: rem(280);
    }

    @include m-min {
      min-width: rem(300);
    }
  }

  .flyout__actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: var(--vp-semantic-spacing-xs) 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    > * {
      white-space: nowrap;
    }

    > * + button,
    > * + span {
      margin-left: var(--vp-semantic-spacing-xs);
    }
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation: fadeIn 0.1s ease-out 0.1s backwards;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: var(--vp-semantic-spacing-xs) solid transparent;
    animation: fadeIn 0.1s ease-out 0.1s backwards;
    z-index: 91;
  }

  &--top {
    > #{$self}__inner {
      bottom: 100%;
      margin-bottom: var(--vp-semantic-spacing-xs);
    }

    &::before {
      top: -1px;
      border-bottom-width: 0;
      border-top-color: rgba(0, 0, 0, 0.2);
      margin-top: -(var(--vp-semantic-spacing-xs));
    }

    &::after {
      top: -1px;
      border-bottom-width: 0;
      border-top-color: var(--vp-semantic-color-background-main);
      margin-top: -(var(--vp-semantic-spacing-xs));
    }
  }

  &--bottom {
    > #{$self}__inner {
      top: 100%;
      margin-top: var(--vp-semantic-spacing-xs);
    }

    &::before {
      bottom: -1px;
      border-top-width: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      margin-bottom: -(var(--vp-semantic-spacing-xs));
    }

    &::after {
      bottom: -1px;
      border-top-width: 0;
      border-bottom-color: var(--vp-semantic-color-background-main);
      margin-bottom: -(var(--vp-semantic-spacing-xs));
    }
  }

  // tooltip on left
  &--left {
    @include leftTooltip();
  }

  // tooltip on right
  &--right {
    @include rightTooltip();
  }

  @include m-max {
    &--mobile-left {
      @include leftTooltip();
    }

    // tooltip on right
    &--mobile-right {
      @include rightTooltip();
    }
  }
}

.confirm-button {
  display: inline-block;
  align-self: center;
  position: relative;
  vertical-align: top;
  text-align: center;

  &__content {
    display: block;
    font-size: rem(12);
    line-height: math.div(15em, 12);
    text-align: left;

    [type='checkbox'] {
      display: inline-block;
      margin-right: var(--vp-semantic-spacing-xs);
      vertical-align: middle;
    }
  }

  &__flyout {
    @include m-min {
      min-width: rem(220);
    }
  }

  &__label {
    display: block;
    font-size: rem(14);
    line-height: math.div(16em, 14);
    margin-bottom: var(--vp-semantic-spacing-xs);
  }
}

.comment-menu {
  display: inline-block;
  position: relative;

  &__flyout > .flyout__inner {
    min-width: rem(220);
  }
}
