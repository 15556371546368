.profile-picture-dialog {
  &__container {
    background: var(--vp-semantic-color-background-main);
    box-shadow: 0 0.375rem 0.375rem 0 var(--vp-semantic-color-alpha-brand-s);
    max-height: calc(100vh - #{rem(56)});
    inline-size: rem(600px);
    margin: 0 auto;
    border-radius: var(--vp-semantic-border-radius-s);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--vp-semantic-spacing-m);
    }
  }
}
