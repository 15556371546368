@mixin skeleton {
  display: block;
  background-color: var(--vp-semantic-color-background-alternative);
  border-radius: var(--vp-semantic-border-radius-main);
  min-height: var(--vp-semantic-spacing-m);
}

.skeleton {
  @include skeleton();
}
