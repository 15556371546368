.guest-mode-access {
  .vtmn-modal_content {
    max-inline-size: 28rem;

    &_body--text {
      display: flex;
      flex-direction: column;
    }
  }

  &__actions {
    display: flex;
    gap: var(--vp-semantic-spacing-xs);
  }
}
