@import '~/assets/scss/global/tools';

.user-identifier {
  display: flex;
  align-items: center;
  gap: var(--vp-semantic-spacing-xs);

  color: var(--vp-semantic-color-content-neutral);
  background-color: transparent;
  border-radius: var(--vp-semantic-border-radius-full);
  cursor: pointer;

  &__name-label {
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    display: none;

    @include m-min {
      display: block;
      font-weight: 600;
    }
  }

  &__login-label {
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    font-weight: 600;
  }

  &--without-name {
    &:hover {
      cursor: default;
    }
  }
}

.avatar {
  --avatar-color: var(--vp-core-color-grey-200);

  background: var(--avatar-color);
  position: relative;
  border-radius: var(--vp-semantic-border-radius-full);
  overflow: hidden;

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--vp-core-color-black);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    &--reversed {
      color: var(--vp-semantic-color-content-inverse);
    }
  }

  &__background {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: var(--avatar-color);
    background: linear-gradient(
      130deg,
      var(--avatar-color) 0%,
      var(--avatar-color) 50%,
      rgba(255, 255, 255, 0.25) 50%
    );

    background-position: center;
    background-size: cover;
  }
}
