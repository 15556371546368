.interaction-option {
  @include kit-body-small;
  display: grid;
  grid-template-columns: rem(22) 1fr;
  column-gap: var(--vp-semantic-spacing-xs);
  align-items: center;

  & + & {
    margin-top: var(--vp-semantic-spacing-xs);
  }

  &--tile {
    color: var(--vp-semantic-color-content-neutral);
  }
}
