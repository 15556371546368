@use 'sass:math';

.page-container {
  position: relative;
  animation: pageTransition 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) 0.1s 1
    backwards;
}
.content-page {
  padding-bottom: var(--vp-semantic-spacing-4xl);

  p > span {
    color: inherit !important;
  }

  h1 {
    margin: 0 0 var(--vp-semantic-spacing-3xl);
  }

  h2 {
    margin: var(--vp-semantic-spacing-4xl) 0 var(--vp-semantic-spacing-2xl);
  }

  h4 {
    margin: var(--vp-semantic-spacing-3xl) 0 var(--vp-semantic-spacing-xl);
  }

  > p {
    margin: var(--vp-semantic-spacing-xl) 0;
    line-height: math.div(24em, 16);
  }

  .vp-accordion__item-panel > p {
    padding-block-end: var(--vp-semantic-spacing-xl);
  }

  .inline-title {
    float: left;
    margin: 0;
    line-height: rem(22);
    padding-right: rem(8);
  }

  ul,
  ol {
    margin: rem(10) 0 rem(20);
    list-style: none;
  }

  li {
    position: relative;
    margin: var(--vp-semantic-spacing-xs) 0;
    font-size: rem(16);
    line-height: math.div(30em, 16);
    padding-left: var(--vp-semantic-spacing-m);
    &::before {
      position: absolute;
      top: rem(-2);
      left: 0;
      content: '\203A';
      color: var(--vp-semantic-color-status-info);
      font-size: rem(30);
    }
  }

  a:not(.vp-link) {
    color: var(--vp-semantic-color-status-info);
    text-decoration: underline;

    &:hover {
      color: var(--vp-semantic-color-content-quiet);
      text-decoration: none;
    }

    &[target='_blank'] {
      @include external-link;
    }
  }
}
