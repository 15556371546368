.unsubscribe_page {
  .unsubscribe_logo {
    position: relative;
    padding: var(--vp-semantic-spacing-m);
    text-align: center;
    background-color: var(--vp-semantic-color-background-main);
  }

  .unsubscribe-notifications {
    width: 50%;
    margin: auto;
    margin-block-start: var(--vp-semantic-spacing-m);

    @include s-max {
      width: 80%;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  hr {
    margin-top: var(--vp-semantic-spacing-xl);
  }
}
