@use 'sass:math';

.project-status {
  display: grid;
  grid-template-columns: rem(180) 0 auto;

  &__hidden-block {
    height: 50%;
    left: -1px;
    position: relative;
    border-top: var(--vp-semantic-border-width-s) solid
      var(--vp-semantic-color-border-quiet);
    border-left: var(--vp-semantic-border-width-s) solid
      var(--vp-semantic-color-border-quiet);
    grid-column: 2;
    top: 50%;
    padding-left: var(--vp-semantic-spacing-3xl);
  }

  &__block {
    padding: var(--vp-semantic-spacing-m);
    @include m-min {
      margin-left: var(--vp-semantic-spacing-xl);
    }
  }

  &__content {
    display: flex;
    grid-column: 3;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--vp-semantic-spacing-xl);
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-main);

    p {
      margin-bottom: 0px;
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__content > * + * {
    margin-top: var(--vp-semantic-spacing-m);
  }
}

.start {
  display: grid;
  grid-template-columns: rem(180) 0 auto;
  padding: var(--vp-semantic-spacing-m);

  &__block {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--vp-semantic-color-content-neutral);
    background-color: var(--vp-semantic-color-status-positive);
    border-radius: var(--vp-semantic-border-radius-main);
    padding: var(--vp-semantic-spacing-xl) 0;

    @include m-min {
      margin-left: var(--vp-semantic-spacing-xl);
    }

    p {
      color: var(--vp-core-color-white);
      margin-bottom: 0;
    }
  }

  &__title {
    color: var(--vp-core-color-white);
  }

  &__block > * + * {
    margin-top: var(--vp-semantic-spacing-m);
  }
}

.step {
  display: grid;
  grid-template-columns: rem(180) 0 auto;

  > p {
    margin-bottom: 0;
  }

  &__timeline {
    z-index: -9999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: var(--vp-semantic-spacing-xl);
    gap: var(--vp-semantic-spacing-xs);
    border-right: var(--vp-semantic-border-width-s) solid
      var(--vp-semantic-color-border-quiet);
    white-space: nowrap;

    &--last {
      position: relative;
      border-color: transparent;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 50%;
        border-right: var(--vp-semantic-border-width-s) solid
          var(--vp-semantic-color-border-quiet);
      }
    }
  }

  &__timeline-icon {
    position: relative;
    top: calc(50% - 12px);
    left: calc(0% - 14px);
    color: var(--vp-semantic-color-content-neutral);
  }

  &__card {
    @include m-min {
      margin-left: var(--vp-semantic-spacing-xl);
    }
  }

  &__timeline-icon-background {
    z-index: -1;
    position: relative;
    top: -20px;
    left: 4px;
    width: var(--vp-semantic-spacing-m);
    height: var(--vp-semantic-spacing-m);
    border-radius: var(--vp-semantic-border-radius-full);
    background-color: var(--vp-semantic-color-background-main);
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--vp-semantic-spacing-xs);
    gap: var(--vp-semantic-spacing-m);
  }

  &__action {
    position: relative;
    flex-grow: 1;
    z-index: 14;

    @include m-min {
      max-width: 30%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      text-wrap: balance;
    }
  }

  &__content > * + * {
    margin-top: var(--vp-semantic-spacing-m);
  }

  &__date {
    display: none;
  }

  &__icon {
    margin-right: var(--vp-semantic-spacing-xs);
  }
}

@include m-max {
  .steps {
    position: relative;

    &::before {
      z-index: -9999;
      content: '';
      position: absolute;
      top: var(--vp-semantic-spacing-5xl);
      bottom: var(--vp-semantic-spacing-5xl);
      left: 50%;
      border-right: var(--vp-semantic-border-width-s) solid
        var(--vp-semantic-color-border-neutral);
    }
  }

  .project-status {
    display: block;

    &__hidden-block {
      display: none;
    }
  }

  .start {
    display: block;
  }

  .step {
    display: block;

    &__timeline {
      display: none;
    }

    &__timeline-icon {
      display: none;
    }

    &__block {
      flex-direction: column;
      align-items: stretch;
    }

    &__action {
      margin-top: var(--vp-semantic-spacing-m);
      width: 100%;
    }

    &__content {
      max-width: none;
    }

    &__date {
      display: flex;
      align-items: center;
    }
  }
}
