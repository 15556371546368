@use 'sass:math';

.popin {
  &__description {
    text-align: center;
  }

  &__title {
    margin-top: var(--vp-semantic-spacing-xs);
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: var(--vp-semantic-spacing-m);
    text-align: center;
  }
}

.modal {
  display: block;

  &.show .modal-dialog {
    margin: 0;
    inline-size: calc(100% - #{rem(30)});
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-out;

    @include l-min {
      max-inline-size: rem(900);
    }
  }

  .modal-content {
    background: var(--vp-semantic-color-background-main);
    box-shadow: 0 0.375rem 0.375rem 0 var(--vp-semantic-color-alpha-brand-s);
    max-height: calc(100vh - #{rem(56)});
    max-inline-size: fit-content;
    margin: 0 auto;
  }

  &.modal-scrollable .modal-content {
    overflow-y: auto;
  }

  .modal-title {
    white-space: nowrap;
    margin: 0;
  }

  .modal-body {
    color: var(--vp-semantic-color-content-neutral);
  }
}

.dialog__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--vp-semantic-color-container-overlay);
  z-index: 300;
}

.modal-inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-s);
  padding: var(--vp-semantic-spacing-2xl);

  & > .vp-icon-button {
    position: absolute;
    top: var(--vp-semantic-spacing-s);
    right: var(--vp-semantic-spacing-s);
  }
}

.modal-header {
  align-items: center;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--vp-semantic-spacing-s);

  @include m-min {
    justify-content: flex-end;
  }

  &:empty {
    display: none;
  }
}

.pop-connect {
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: 2rem;
  .title {
    font-size: rem(30);
    color: var(--vp-semantic-color-status-info);
    font-weight: 600;
    line-height: rem(34);
    margin-bottom: rem(10);
  }
}
.pop-connect-desc {
  margin-top: 2rem;
  .title {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: var(--vp-semantic-color-status-info);
  }
}

.error-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--vp-semantic-typography-screen-l-caption-font-size);
  line-height: var(--vp-semantic-typography-screen-l-caption-line-height);
  text-align: center;
  color: var(--vp-semantic-color-content-quiet);

  button {
    margin: rem(4) auto;
  }
}

.error-disconnect {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__info {
    display: flex;
    flex-direction: column;
    margin: var(--vp-semantic-spacing-xs) 0 var(--vp-semantic-spacing-xs) 0;
  }
}

.modal-large {
  .modal-body {
    overflow-x: auto;
    max-height: calc(100vh - #{rem((62 + 72))});
  }
  .modal-dialog {
    max-width: 900px;
  }
  .modal-title {
    white-space: nowrap;
    margin-top: 0;
  }
  .content-page {
    padding-top: 0;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 15px;
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 10px;
    background-color: var(--vp-semantic-color-background-alternative);
  }

  ::-webkit-scrollbar-thumb:vertical {
    border-radius: 10px;
    height: 30px;
    background-color: var(--vp-semantic-color-container-brand);
  }
}

.buttons-abuse {
  display: flex;
  justify-content: space-around;
  margin-top: var(--vp-semantic-spacing-xs);
  @include s-max {
    flex-direction: column;
  }
}

.title-abuse {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-block-start: var(--vp-semantic-spacing-2xl);

  @include m-min {
    margin-block-start: 0;
  }

  > span {
    margin-left: var(--vp-semantic-spacing-s);
  }
}

.abuse {
  &__filter {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-s);
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--vp-semantic-spacing-s);

    @include m-min {
      flex-direction: row;
    }

    .vp-button {
      align-self: flex-start;
    }
  }

  &__comment {
    display: flex;
    flex-direction: column;
  }
}
