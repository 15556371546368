@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--vp-semantic-color-background-main);
    opacity: 0.85;
  }
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image {
  border-radius: var(--vp-semantic-border-radius-s);
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  padding: 32px;
  margin: auto;
  cursor: pointer;
  border-radius: var(--vp-semantic-border-radius-full);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--vp-semantic-color-background-alternative);
  transition: all 0.2s ease-out;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: var(--vp-semantic-color-content-neutral);
  }
}
.ril__navButtons:hover {
  background-color: var(--vp-semantic-color-background-alternative);
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 8px;
  &::before {
    mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+')
      no-repeat 50% 50%;
  }
}

.ril__navButtonNext {
  right: 8px;
  &::before {
    mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSJjdXJyZW50Q29sb3IiLz48L3N2Zz4=')
      no-repeat 50% 50%;
  }
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: var(--vp-semantic-color-content-neutral);
}

.ril__toolbar {
  top: 0;
  height: rem(60);
}

.ril__toolbarSide {
  height: rem(60);
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  background-color: var(--vp-semantic-color-container-brand);
  padding-left: rem(10);
  padding-right: 20px;
  flex: 0 0 auto;
  border-bottom-left-radius: var(--vp-semantic-spacing-xs);
}

.ril__toolbarItem {
  display: inline-block;
  line-height: rem(60);
  padding: 0;
  color: var(--vp-semantic-color-content-neutral);
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & + & {
    margin-left: var(--vp-semantic-spacing-xs);
  }
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  position: relative;
  width: var(--vp-semantic-spacing-3xl);
  height: var(--vp-semantic-spacing-3xl);
  border-radius: var(--vp-semantic-border-radius-full);
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--vp-semantic-color-background-quiet);
  transition: all 0.2s ease-out;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: var(--vp-semantic-color-background-main);
  }
}
.ril__builtinButton:hover {
  background-color: var(--vp-semantic-color-container-overlay);
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton::before {
  mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPjwvc3ZnPg==')
    no-repeat 50% 50%;
}

.ril__zoomInButton::before {
  mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj48cGF0aCBkPSJNMSAxOWw2LTYiLz48cGF0aCBkPSJNOSA4aDYiLz48cGF0aCBkPSJNMTIgNXY2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==')
    no-repeat 50% 50%;
}

.ril__zoomOutButton::before {
  mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj48cGF0aCBkPSJNMSAxOWw2LTYiLz48cGF0aCBkPSJNOSA4aDYiLz48L2c+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSI3IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat 50% 50%;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: var(--vp-semantic-color-background-main);
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vp-semantic-color-content-neutral);
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: var(--vp-semantic-color-content-neutral);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
