@include keyframes(rotatingPendulum) {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@include keyframes(rotatingPendulumBlur) {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include keyframes(shiningBulb) {
  0% {
    color: var(--vp-semantic-color-content-neutral);
  }
  50% {
    color: background-accent;
  }
  100% {
    color: var(--vp-semantic-color-content-neutral);
  }
}

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@include keyframes(fadeInHalf) {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@include keyframes(appearUp) {
  from {
    opacity: 0;
    transform: translateY(2%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(appearFromTop) {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@include keyframes(appearFromLeft) {
  from {
    opacity: 0;
    transform: translateX(-2%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slideFromLeft) {
  from {
    transform: translateX(-2%);
  }
  to {
    transform: translateX(0);
  }
}

@include keyframes(pageTransition) {
  from {
    opacity: 0;
    transform: translateX(-6%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(screenSlideUpIn) {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(screenSlideUpOut) {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100vh);
  }
}

@include keyframes(screenSlideDownIn) {
  from {
    opacity: 0;
    transform: translateY(-100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(screenSlideDownOut) {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100vh);
  }
}

@include keyframes(bubbleUp) {
  from {
    opacity: 0;
    transform: translateY(15%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ideaAppear) {
  from {
    opacity: 0;
    transform: translateX(-5%) skewX(-30deg);
  }
  to {
    opacity: 1;
    transform: translateX(0) skewX(0);
  }
}

@include keyframes(popAppear) {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@include keyframes(popDisappear) {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@include keyframes(scalePop) {
  from {
    transform: scale(1.4) rotate(-10deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
}

@include keyframes(flapAppear) {
  from {
    opacity: 0;
    transform: rotateX(90deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@include keyframes(scaleXexpand) {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
@include keyframes(scaleXshrink) {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}
