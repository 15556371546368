@use 'sass:math';

$font-size-root: 16px;

// Return a value without its unit
@function trim-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

// Convert pixel values into rem values
@function rem($value) {
  @return math.div(trim-unit($value), trim-unit($font-size-root)) * 1rem;
}

@function delayAnimation($value1) {
  @return $value1 * 0.1s;
}

// =================== MEDIAQUERIES ==========================

$mediaquery: (
  s-min: rem(0px),
  s-max: rem(767px),
  m-min: rem(768px),
  m-max: rem(991px),
  l-min: rem(992px),
  l-max: rem(1279px),
  xl-min: rem(1280px),
);

// Media Queries
@function mediaquery($key) {
  @return #{map-get($mediaquery, $key)};
}

// or
@function mq($key) {
  @return #{map-get($mediaquery, $key)};
}

// MEDIA QUERIES RANGES
@mixin mediaquery-between($min, $max) {
  // convert string values in variables
  @if type-of($min) ==string {
    $min: mediaquery($min);
  } @else {
    $min: rem($min);
  }
  @if type-of($max) ==string {
    $max: mediaquery($max);
  } @else {
    $max: rem($max);
  }
  @if $min !=null and $max !=null {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}
// alertative versions
@mixin mediaquery-range($min, $max) {
  @include mediaquery-between($min, $max);
}

@mixin mq-between($min, $max) {
  @include mediaquery-between($min, $max);
}

@mixin mq-range($min, $max) {
  @include mediaquery-between($min, $max);
}

// Mediaqueries mixins
@mixin s-min {
  @media screen and (min-width: mq(s-min)) {
    @content;
  }
}

@mixin m-min {
  @media screen and (min-width: mq(m-min)) {
    @content;
  }
}

@mixin l-min {
  @media screen and (min-width: mq(l-min)) {
    @content;
  }
}

@mixin xl-min {
  @media screen and (min-width: mq(xl-min)) {
    @content;
  }
}

@mixin s {
  @media screen and (min-width: mq(s-min)) and (max-width: mq(s-max)) {
    @content;
  }
}

@mixin m {
  @media screen and (min-width: mq(m-min)) and (max-width: mq(m-max)) {
    @content;
  }
}

@mixin l {
  @media screen and (min-width: mq(l-min)) and (max-width: mq(l-max)) {
    @content;
  }
}

@mixin s-max {
  @media screen and (max-width: mq(s-max)) {
    @content;
  }
}

@mixin m-max {
  @media screen and (max-width: mq(m-max)) {
    @content;
  }
}

@mixin l-max {
  @media screen and (max-width: mq(l-max)) {
    @content;
  }
}

// =================== TRANSITIONS ==========================
@mixin transition($transition) {
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}

@mixin scale($ratio, $ratio-y) {
  -webkit-transform: scale($ratio, $ratio-y);
  -ms-transform: scale($ratio, $ratio-y);
  transform: scale($ratio, $ratio-y);
}

@mixin translateRotate($degrees, $x, $y) {
  -webkit-transform: translate($x, $y) rotate($degrees);
  -ms-transform: translate($x, $y) rotate($degrees);
  transform: translate($x, $y) rotate($degrees);
}

@mixin scaleRotate($degrees, $ratio, $ratio-y) {
  -webkit-transform: scale($ratio, $ratio-y) rotate($degrees);
  -ms-transform: scale($ratio, $ratio-y) rotate($degrees);
  transform: scale($ratio, $ratio-y) rotate($degrees);
}

@mixin translateScale($x, $y, $ratio, $ratio-y) {
  -webkit-transform: translate($x, $y) scale($ratio, $ratio-y);
  -moz-transform: translate($x, $y) scale($ratio, $ratio-y);
  -ms-transform: translate($x, $y) scale($ratio, $ratio-y);
  -o-transform: translate($x, $y) scale($ratio, $ratio-y);
  transform: translate($x, $y) scale($ratio, $ratio-y);
}

@mixin translateSkew($x, $y, $ratio, $ratio-y) {
  -webkit-transform: translate($x, $y) skew($ratio, $ratio-y);
  -moz-transform: translate($x, $y) skew($ratio, $ratio-y);
  -ms-transform: translate($x, $y) skew($ratio, $ratio-y);
  -o-transform: translate($x, $y) skew($ratio, $ratio-y);
  transform: translate($x, $y) skew($ratio, $ratio-y);
}

@mixin perspectiveRotate3D($p, $rx, $ry, $rz) {
  -webkit-transform: perspective($p) rotateX($rx) rotateY($ry) rotateZ($rz);
  -moz-transform: perspective($p) rotateX($rx) rotateY($ry) rotateZ($rz);
  -ms-transform: perspective($p) rotateX($rx) rotateY($ry) rotateZ($rz);
  -o-transform: perspective($p) rotateX($rx) rotateY($ry) rotateZ($rz);
  transform: perspective($p) rotateX($rx) rotateY($ry) rotateZ($rz);
}

@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
  -moz-transform: skew($x, $y);
  -ms-transform: skew($x, $y);
  -o-transform: skew($x, $y);
  transform: skew($x, $y);
}

@mixin gradient-vertical($color-1, $p1, $color-2, $p2) {
  background: $color-1;
  background: -moz-linear-gradient(top, $color-1 $p1, $color-2 $p2);
  background: linear-gradient(to bottom, $color-1 $p1, $color-2 $p2);
  background: -webkit-linear-gradient(top, $color-1 $p1, $color-2 $p2);
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin grayscale($p) {
  -webkit-filter: grayscale($p);
  filter: grayscale($p);
}

@mixin gradient {
  background: linear-gradient(
    135deg,
    var(--vp-semantic-color-container-brand),
    var(--vp-semantic-color-status-positive)
  );
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin listDrag() {
  list-style: none;
  list-style-type: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background-color: var(--vp-semantic-color-background-main);
  border: 1px solid var(--vp-semantic-color-border-catchy);
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: rem(60);
  cursor: row-resize;
  z-index: 10;
}

@mixin external-link {
  display: inline-block;
  position: relative;
  padding-right: rem(14);
  &::after {
    position: absolute;
    right: rem(3);
    top: rem(3);
    font-family: serif;
    content: '\2197';
    font-size: rem(12);
    width: rem(9);
    height: rem(9);
    line-height: rem(9);
    color: var(--vp-semantic-color-content-quiet);
    border: 1px solid var(--vp-semantic-color-border-quiet);
    border-top: 0;
    border-right: 0;
    border-radius: rem(1);
  }
}

@mixin zoomButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: var(--vp-semantic-spacing-xs);

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: var(--vp-semantic-color-background-main);
  }

  &:focus {
    outline: none;
    border-color: var(--vp-semantic-color-border-brand);
  }

  > span {
    margin: auto;
  }
}

@mixin resetNextImage {
  position: static !important;
  min-width: auto !important;
  width: auto !important;
  height: auto !important;
  max-height: 40vh !important;
}

@mixin broken-link() {
  &::after {
    content: '';
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ1bmxpbmsiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS11bmxpbmsgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0zMDQuMDgzIDQwNS45MDdjNC42ODYgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxbC00NC42NzQgNDQuNjc0Yy01OS4yNjMgNTkuMjYyLTE1NS42OTMgNTkuMjY2LTIxNC45NjEgMC01OS4yNjQtNTkuMjY1LTU5LjI2NC0xNTUuNjk2IDAtMjE0Ljk2bDQ0LjY3NS00NC42NzVjNC42ODYtNC42ODYgMTIuMjg0LTQuNjg2IDE2Ljk3MSAwbDM5LjU5OCAzOS41OThjNC42ODYgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxbC00NC42NzUgNDQuNjc0Yy0yOC4wNzIgMjguMDczLTI4LjA3MiA3My43NSAwIDEwMS44MjMgMjguMDcyIDI4LjA3MiA3My43NSAyOC4wNzMgMTAxLjgyNCAwbDQ0LjY3NC00NC42NzRjNC42ODYtNC42ODYgMTIuMjg0LTQuNjg2IDE2Ljk3MSAwbDM5LjU5NyAzOS41OTh6bS01Ni41NjgtMjYwLjIxNmM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsNDQuNjc0LTQ0LjY3NGMyOC4wNzItMjguMDc1IDczLjc1LTI4LjA3MyAxMDEuODI0IDAgMjguMDcyIDI4LjA3MyAyOC4wNzIgNzMuNzUgMCAxMDEuODIzbC00NC42NzUgNDQuNjc0Yy00LjY4NiA0LjY4Ni00LjY4NiAxMi4yODQgMCAxNi45NzFsMzkuNTk4IDM5LjU5OGM0LjY4NiA0LjY4NiAxMi4yODQgNC42ODYgMTYuOTcxIDBsNDQuNjc1LTQ0LjY3NWM1OS4yNjUtNTkuMjY1IDU5LjI2NS0xNTUuNjk1IDAtMjE0Ljk2LTU5LjI2Ni01OS4yNjQtMTU1LjY5NS01OS4yNjQtMjE0Ljk2MSAwbC00NC42NzQgNDQuNjc0Yy00LjY4NiA0LjY4Ni00LjY4NiAxMi4yODQgMCAxNi45NzFsMzkuNTk3IDM5LjU5OHptMjM0LjgyOCAzNTkuMjhsMjIuNjI3LTIyLjYyN2M5LjM3My05LjM3MyA5LjM3My0yNC41NjkgMC0zMy45NDFMNjMuNTk4IDcuMDI5Yy05LjM3My05LjM3My0yNC41NjktOS4zNzMtMzMuOTQxIDBMNy4wMjkgMjkuNjU3Yy05LjM3MyA5LjM3My05LjM3MyAyNC41NjkgMCAzMy45NDFsNDQxLjM3MyA0NDEuMzczYzkuMzczIDkuMzcyIDI0LjU2OSA5LjM3MiAzMy45NDEgMHoiPjwvcGF0aD48L3N2Zz4=);
    display: block;
    width: rem(20);
    height: rem(20);
    color: rgb(100, 100, 100);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0.4;
  }
}

@mixin customDividerStart {
  border-block-start: 1px solid var(--vp-semantic-color-border-quiet);
}

@mixin customDividerEnd {
  border-block-end: 1px solid var(--vp-semantic-color-border-quiet);
}
