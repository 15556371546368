@use 'sass:math';

.env-ribbon {
  position: fixed;
  width: rem(100);
  height: rem(100);
  top: 0;
  left: 0;
  z-index: 1020;
  pointer-events: none;
  overflow: hidden;

  span {
    background-color: var(--vp-semantic-color-content-on-brand-accent);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    color: var(--vp-semantic-color-content-on-commercial-neutral);
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(24);
    text-align: center;
    text-transform: uppercase;

    position: absolute;
    right: 0;
    top: 0;
    width: rem(180);
    white-space: nowrap;

    transform-origin: right center;
    transform: rotate(-45deg) translate(rem(24), rem(-24));
  }
}
