.formNavigation {
  display: flex;
  position: sticky;
  bottom: var(--vp-semantic-spacing-3xl);
  margin-left: auto;
  margin-right: var(--vp-semantic-spacing-3xl);
  background-color: var(--vp-semantic-color-background-main);
  width: fit-content;
  height: var(--vp-semantic-spacing-4xl);
  border-radius: var(--vp-semantic-border-radius-full);
  box-shadow: 0 6px 6px rgba(0, 83, 125, 0.1);
  z-index: 30;
  border: 1px solid var(--vp-semantic-color-border-neutral);

  &__button {
    background: none;
    border: 0;
    width: var(--vp-semantic-spacing-5xl);
    color: var(--vp-semantic-color-content-brand);
    cursor: pointer;

    &:disabled {
      opacity: 0.38;
      cursor: not-allowed;
      color: var(--vp-semantic-color-content-neutral);
    }

    & + & {
      border-left: 1px solid var(--vp-semantic-color-border-catchy);
    }
  }
}
