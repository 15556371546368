@use 'sass:math';

.single {
  position: relative;
  padding-bottom: var(--vp-semantic-spacing-xl);

  .dates {
    span {
      display: inline-block;

      & + span {
        padding-left: rem(10);
      }
    }
    time {
      font-weight: 600;
    }
  }

  .media-picture {
    margin-bottom: rem(10);

    > img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
