@use 'sass:math';

.announcement {
  $self: &;

  @include m-min {
    padding: 0 var(--vp-semantic-spacing-2xl) var(--vp-semantic-spacing-5xl);
    background-color: var(--vp-semantic-color-background-main);
  }

  @include m-min {
    padding: 0 var(--vp-semantic-spacing-3xl) var(--vp-semantic-spacing-5xl);
    max-width: rem(760);
    margin: auto;
  }

  &__layout {
    margin-top: var(--vp-semantic-spacing-m);
    @include make-row;
  }

  &__side,
  &__main {
    @include make-col-ready;
    @include make-col(12);
    margin-left: auto;
    margin-right: auto;
    max-width: calc(var(--vp-semantic-spacing-xs) * 72);

    @include m-min {
      @include make-col(6);
    }
  }

  &__main {
    order: 2;
    @include m-min {
      order: 1;
    }
  }

  &__side {
    order: 1;
    @include m-min {
      order: 2;
    }
  }

  &__counters {
    position: relative;
    z-index: 20;

    #{$self}__main & {
      @include m-min {
        display: none;
      }
    }
    #{$self}__side & {
      @include m-max {
        display: none;
      }
    }
  }

  &__counter {
    display: inline-block;
    vertical-align: middle;
    margin-right: var(--vp-semantic-spacing-xl);
    font-size: rem(14);

    @include m-min {
      font-size: rem(16);
    }
  }

  &__counter-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: var(--vp-semantic-spacing-xs);

    &--like {
      color: var(--vp-semantic-color-status-negative);
    }

    &--comment {
      color: var(--vp-semantic-color-status-info);
    }
  }

  &__counter-label {
    display: inline-block;
    vertical-align: middle;
  }

  &__content {
    @include m-max {
      padding: 0 var(--vp-semantic-spacing-xl);
    }
  }

  &__medias {
    position: relative;
    margin-bottom: var(--vp-semantic-spacing-2xl);
  }

  &__author {
    margin-top: var(--vp-semantic-spacing-2xl);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: var(--vp-semantic-spacing-m);
    margin: var(--vp-semantic-spacing-2xl) 0;
  }

  &__like {
    position: relative;

    &--loading {
      button > span {
        opacity: 0;
      }
    }

    &--disconnected {
      display: flex;
      align-items: center;
      gap: var(--vp-semantic-spacing-2xs);
    }
  }

  &__like-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  &__parent-link {
    margin-block-end: var(--vp-semantic-spacing-2xl);
  }

  &__picture {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: math.div(1080%, 19.2);
      background-color: var(--vp-semantic-color-background-alternative);
    }

    img {
      background-color: var(--vp-semantic-color-background-alternative);
      cursor: pointer;
    }
  }

  &__video {
    position: relative;
    overflow: hidden;
    border-radius: var(--vp-semantic-border-radius-s);
    margin: var(--vp-semantic-spacing-m) auto var(--vp-semantic-spacing-2xl);
  }

  &__comments {
    &__title {
      margin: var(--vp-semantic-spacing-2xl) 0 var(--vp-semantic-spacing-m) 0;
    }
  }

  .interaction-details__description {
    img {
      border-radius: var(--vp-semantic-border-radius-s);
    }
  }

  .translate {
    margin-block-end: var(--vp-semantic-spacing-2xl);
  }
}

.announcement-card {
  &__title {
    margin-block-end: var(--vp-semantic-spacing-2xl);
    text-wrap: balance;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);
    margin-block-end: var(--vp-semantic-spacing-xs);

    ul {
      list-style: initial;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      list-style-position: inside;
    }

    &__preview {
      margin-top: var(--vp-semantic-spacing-xl);
      margin-bottom: var(--vp-semantic-spacing-xs);
      height: rem(120);
      overflow: hidden;
      background: linear-gradient(
        to bottom,
        var(--vp-semantic-color-content-neutral),
        transparent
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: transparent;
    }
  }

  &__media {
    position: relative;
    overflow: hidden;
    border-radius: var(--vp-semantic-border-radius-s);

    @include s-max {
      &::before {
        content: '';
        display: block;
        padding-top: math.div(1080%, 19.2);
        background-color: var(--vp-semantic-color-background-alternative);
      }
    }

    img {
      background-color: var(--vp-semantic-color-background-alternative);
      cursor: pointer;
    }
  }

  &__counters-display {
    display: flex;
    margin-bottom: var(--vp-semantic-spacing-2xl);
  }

  &__counter {
    display: flex;
    align-items: center;
    color: var(--vp-semantic-color-content-quiet);

    & + & {
      margin-left: var(--vp-semantic-spacing-xl);
    }
  }

  &__counter-icon {
    display: flex;
    align-items: center;
    margin-right: var(--vp-semantic-spacing-xs);
    color: var(--vp-semantic-color-content-quiet);
  }

  &__counter-label {
    display: inline-block;
    vertical-align: middle;
    color: var(--vp-semantic-color-content-quiet);
  }
}
