.selection-end {
  &__form {
    margin-bottom: var(--vp-semantic-spacing-4xl);
  }

  &__cancel-application {
    > * + * {
      margin-top: var(--vp-semantic-spacing-m);
    }
  }
}
