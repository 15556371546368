@use 'sass:math';

@mixin text-content-layout {
  @include make-col-ready;
  @include make-col(12);
  @include m-min {
    @include make-col(6);
  }
  @include l-min {
    @include make-col(7);
  }
}

.back-banner {
  .back-button {
    &,
    & * {
      color: var(--vp-semantic-color-content-brand);
    }
    &:focus,
    &:active,
    &:hover {
      &,
      & * {
        opacity: 0.8;
      }
    }
  }
}

.questionnaire,
.profile-completion,
.children-form,
.target-criterias,
.timeslots {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--vp-semantic-spacing-5xl);
  padding-top: var(--vp-semantic-spacing-2xl);
  min-height: calc(100vh - #{rem(140)});

  &__inner {
    display: flex;
    flex-direction: column;

    min-height: inherit;

    &__centered {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-s);
      justify-content: center;
    }
  }

  &__slide {
    &--in {
      animation: screenSlideUpIn 0.4s ease-out 0s forwards;
    }

    &--out {
      animation: screenSlideUpOut 0.4s ease-out 0s forwards;
    }

    &--down-in {
      animation: screenSlideDownIn 0.4s ease-out 0s forwards;
    }

    &--down-out {
      animation: screenSlideDownOut 0.4s ease-out 0s forwards;
    }
  }
}

.conclusion {
  margin-top: var(--vp-semantic-spacing-3xl);
}

.translatable {
  margin: var(--vp-semantic-spacing-m) 0;
  &__translate-button {
    display: flex;
    justify-content: flex-end;
  }
}

.preview-sticker {
  position: fixed;
  top: rem(60);
  right: 0;
  margin: var(--vp-semantic-spacing-m);
}

.form-preview {
  background-color: var(--vp-semantic-color-background-main);
  border-radius: var(--vp-semantic-border-radius-s);
  box-shadow: 0 0.375rem 0.375rem 0 var(--vp-semantic-color-alpha-brand-s);

  padding: var(--vp-semantic-spacing-xl);

  inline-size: 100%;
  max-inline-size: rem(600);
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 10;

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    margin: var(--vp-semantic-spacing-xs);

    button {
      padding: var(--vp-semantic-spacing-xs);
      border: 0;
      background: none;
      cursor: pointer;

      &:hover {
        color: var(--vp-semantic-color-content-quiet);
      }
    }
  }

  &__title {
    margin-bottom: var(--vp-semantic-spacing-m);
  }

  &__explain {
    color: var(--vp-semantic-color-content-quiet);
  }
}

.centered-content {
  margin-right: auto;
  margin-left: auto;
  max-width: rem(1122);
}

.header-banner {
  &__layout {
    display: flex;
  }
}

.interaction__cta {
  display: flex;
  flex-direction: column;

  .vp-button {
    inline-size: 100%;
  }
}

.interaction-header-form {
  position: relative;
  margin-bottom: var(--vp-semantic-spacing-xl);
}

.interaction-type {
  display: inline-flex;
  align-items: center;
  padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-m);
  font-size: rem(14);
  font-weight: 600;
  background-color: var(--vp-semantic-color-container-neutral);
  gap: var(--vp-semantic-spacing-xs);
}

.interaction-user-status {
  $self: &;
  display: block;

  & + & {
    margin-top: var(--vp-semantic-spacing-m);
  }

  &__count {
    display: inline-block;
    min-width: var(--vp-semantic-spacing-m);
    height: var(--vp-semantic-spacing-m);
    line-height: var(--vp-semantic-spacing-m);
    border-radius: var(--vp-semantic-border-radius-s);
    font-size: rem(12);
    font-weight: 600;
    color: var(--vp-semantic-color-content-neutral);
    text-align: center;
  }

  &__contribution {
    display: flex;
    align-items: center;
    font-size: rem(14);
    font-weight: 600;
    gap: var(--vp-semantic-spacing-xs);

    &.info {
      color: var(--vp-semantic-color-status-info);

      #{$self}__count {
        background-color: var(--vp-semantic-color-status-info);
      }
    }

    &.positive {
      color: var(--vp-semantic-color-status-positive);

      #{$self}__count {
        background-color: var(--vp-semantic-color-status-positive);
      }
    }

    &.warning {
      color: var(--vp-semantic-color-status-warning);

      #{$self}__count {
        background-color: var(--vp-semantic-color-status-warning);
      }
    }
  }
}

.interaction-header {
  position: relative;
  margin-bottom: var(--vp-semantic-spacing-xl);

  > * + * {
    margin-top: var(--vp-semantic-spacing-m);
  }

  h2 {
    text-wrap: balance;
  }

  &__layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.interaction-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  gap: var(--vp-semantic-spacing-m);

  @include m-min {
    flex-direction: row;
    align-items: flex-start;
  }
}

.interaction-summary {
  width: 100%;
  margin-right: var(--vp-semantic-spacing-3xl);

  &__form-title {
    padding-right: var(--vp-semantic-spacing-m);
  }

  &__options {
    display: block;
    border-radius: var(--vp-semantic-border-radius-main);
    margin-bottom: var(--vp-semantic-spacing-2xl);
    padding: var(--vp-semantic-spacing-m);

    &--white-background {
      background-color: var(--vp-semantic-color-container-quiet);
    }

    .inbox__interaction & {
      background: none;
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
}

.main-cta {
  &__non-active {
    align-items: center;
    display: flex;
    gap: var(--vp-semantic-spacing-xs);
    justify-content: center;

    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-main);
    color: var(--vp-semantic-color-content-neutral);

    padding: var(--vp-component-button-spacing-medium-padding-block-start)
      var(--vp-component-button-spacing-medium-padding-inline-end)
      var(--vp-component-button-spacing-medium-padding-block-end)
      var(--vp-component-button-spacing-medium-padding-inline-start);

    text-align: center;
    text-transform: uppercase;

    width: 100%;
  }
}

.interaction-aside {
  width: 100%;
  margin: auto;

  @include m-min {
    position: sticky;
    // considering header and project tabs cumulated heights
    top: rem(160);
    width: 70%;
    margin: 0;
    left: 0;
    pointer-events: none;
  }

  &__shortcuts {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);
    pointer-events: auto;

    @include m-min {
      background-color: var(--vp-semantic-color-background-main);
      border: 1px solid var(--vp-semantic-color-border-quiet);
      padding: var(--vp-semantic-spacing-m);
    }

    header {
      .vtmn-skeleton {
        padding-top: 56.25%;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: var(--vp-semantic-spacing-xs);

    .vp-button {
      align-self: stretch;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    // make cta fixed if only one ongoing interaction
    .ongoing-unique & {
      position: fixed;
      padding: var(--vp-semantic-spacing-m);
      background-color: var(--vp-semantic-color-background-main);
      inset: auto 0 0;
      z-index: 110;

      @include m-min {
        position: static;
        padding: 0;
        background: none;
      }
    }
  }

  &__picture {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: math.div(1080%, 19.2);
    }

    img {
      background-color: var(--vp-semantic-color-background-main);
    }
  }

  &__video {
    position: relative;
    border-radius: var(--vp-semantic-border-radius-s);
    overflow: hidden;
  }

  &__author {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--vp-semantic-spacing-xs);
  }

  &__contact {
    margin: var(--vp-semantic-spacing-xs) 0;
  }
}

.project {
  &__body {
    border-top: 1px solid var(--vp-semantic-color-border-brand);
    position: relative;
    margin-top: var(--vp-semantic-spacing-2xs);
    background-color: var(--vp-semantic-color-container-neutral);
    padding: var(--vp-semantic-spacing-m);

    ul,
    ol {
      margin-bottom: var(--vp-semantic-spacing-m);
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      list-style-position: inside;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background-color: white;
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-m);
    color: var(--vp-semantic-color-content-brand);
    border: 1px solid var(--vp-semantic-color-border-brand);
    font-weight: 600;
    border-radius: var(--vp-semantic-border-radius-s);
  }

  &__content {
    margin-top: var(--vp-semantic-spacing-xl);
  }

  &__layout {
    display: flex;
    flex-direction: column;

    @include m-min {
      flex-direction: row;
    }
  }

  &__summary {
    margin-right: var(--vp-semantic-spacing-xl);
    width: 100%;
  }

  &__media {
    @include m-min {
      width: 70%;
      margin: auto;
    }
  }

  &__description {
    font-size: rem(16);
    line-height: math.div(24em, 16);
    word-break: break-word;

    // reset
    * {
      background-color: transparent !important;
      color: inherit !important;
    }

    p {
      margin-bottom: var(--vp-semantic-spacing-m);

      &:empty,
      &:blank {
        display: none;
      }
    }

    li {
      list-style-position: inside;
    }

    a {
      color: var(--vp-semantic-color-content-brand) !important;

      &:hover,
      &:focus {
        color: var(--vp-semantic-color-content-quiet) !important;
        text-decoration: underline;
      }
    }
  }
}

.interaction-details {
  margin-top: var(--vp-semantic-spacing-2xl);
  margin-bottom: var(--vp-semantic-spacing-2xl);

  &__layout {
    @include text-content-layout;
  }

  &__statuses {
    margin-top: var(--vp-semantic-spacing-m);

    &:empty {
      display: none;
    }
  }

  &__description {
    margin-bottom: var(--vp-semantic-spacing-xl);
    font-size: rem(18);
    line-height: math.div(24em, 18);
    word-break: break-word;

    // reset
    * {
      background-color: transparent !important;
      color: inherit !important;
    }

    ul,
    ol {
      margin-bottom: var(--vp-semantic-spacing-m);
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      list-style-position: inside;
    }

    p {
      margin-bottom: var(--vp-semantic-spacing-m);
    }

    a {
      color: var(--vp-semantic-color-content-brand) !important;

      &:hover,
      &:focus {
        color: var(--vp-semantic-color-content-quiet) !important;
        text-decoration: underline;
      }
    }
  }
}

.selection-beardman-children {
  display: flex;
  gap: var(--vp-semantic-spacing-2xl);
  margin-bottom: var(--vp-semantic-spacing-2xl);

  @include s-max {
    flex-direction: column;
    &__media {
      width: 100%;
    }
  }

  &__media {
    width: 30%;
  }

  &__title {
    margin: var(--vp-semantic-spacing-xs) 0;
    font-weight: 600;
  }
}

.contact-project-group {
  display: flex;
  flex-direction: column;

  border-top: 1px solid var(--vp-semantic-color-border-quiet);
  padding-top: var(--vp-semantic-spacing-xl);
  margin-top: var(--vp-semantic-spacing-xl);

  &__action {
    margin-top: var(--vp-semantic-spacing-m);
    align-self: flex-start;
  }
}

.interaction-tag-sticker {
  gap: var(--vp-semantic-spacing-2xs);
}
