@use 'sass:math';

.timeslots-confirmation {
  &__answer {
    margin-bottom: var(--vp-semantic-spacing-xl);
  }

  &__new-timeslot {
    margin: var(--vp-semantic-spacing-xl) 0;
  }

  &__cancel {
    textarea {
      width: 100%;
      height: 180px;
    }
  }

  &__message {
    margin-bottom: var(--vp-semantic-spacing-m);
    color: var(--vp-semantic-color-content-quiet);
  }

  &__contact {
    margin-top: var(--vp-semantic-spacing-4xl);
    margin-bottom: var(--vp-semantic-spacing-xs);

    &__description {
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__date {
    display: flex;
    align-items: center;
    margin-top: var(--vp-semantic-spacing-2xl);
    margin-bottom: var(--vp-semantic-spacing-m);
    span {
      margin-right: var(--vp-semantic-spacing-xs);
    }
  }

  &__action {
    > div {
      background-color: var(--vp-semantic-color-background-main);
      padding: var(--vp-semantic-spacing-xs);
      border-radius: var(--vp-semantic-spacing-xs);
      margin-top: var(--vp-semantic-spacing-xs);
    }
    span {
      margin-right: var(--vp-semantic-spacing-xs);
    }
  }

  &__confirmed {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include s-max {
      flex-direction: column;

      &__button {
        display: flex;
        justify-content: center;
      }

      div {
        width: 100%;
      }
    }

    > p {
      display: flex;
      align-items: center;
      gap: var(--vp-semantic-spacing-xs);

      svg {
        color: var(--vp-semantic-color-status-positive);
      }
    }
  }
}

.timeslots-summary {
  &__timeslot {
    margin: var(--vp-semantic-spacing-m) 0;

    &--accepted,
    &--confirmed {
      color: var(--vp-semantic-color-status-positive);
    }

    &--refused,
    &--unconfirmed {
      color: var(--vp-semantic-color-status-negative);
    }
  }
}

.popin-title {
  display: flex;
  align-items: center;
  color: var(--vp-semantic-color-status-warning);
  gap: var(--vp-semantic-spacing-xs);
}
