@use 'sass:math';

.sign-up-banner {
  background-color: var(--vp-semantic-color-background-main);

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--vp-semantic-spacing-4xl) var(--vp-semantic-spacing-xl);
    gap: var(--vp-semantic-spacing-xl);

    @include m-min {
      gap: var(--vp-semantic-spacing-4xl);
    }

    > ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--vp-semantic-spacing-xs);

      > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: var(--vp-semantic-spacing-2xs);

        > p {
          text-align: start;
          inline-size: 70%;
        }

        @include m-min {
          flex-direction: column;

          > p {
            text-align: center;
          }
        }
      }

      @include m-min {
        inline-size: 80%;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
