.profile-completion {
  &__introduction {
    position: relative;
    max-width: rem(340);
    padding: var(--vp-semantic-spacing-5xl) 0;
    margin: auto;
    text-align: center;

    &__illustration {
      margin-bottom: var(--vp-semantic-spacing-2xl);
    }

    &__title {
      margin-bottom: var(--vp-semantic-spacing-m);
    }

    &__action {
      margin: var(--vp-semantic-spacing-2xl) auto;
      display: flex;
      justify-content: center;
    }
  }

  &__end {
    &__profile-link {
      text-decoration: none;
      color: var(--vp-semantic-color-status-info);
      font-weight: 600;
    }
  }

  &__page {
    position: relative;

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--vp-semantic-spacing-m);

      > * {
        display: flex;
        flex-direction: column;
      }

      input {
        width: 100%;
      }
    }

    &__action {
      margin-top: var(--vp-semantic-spacing-2xl);
    }
  }
}
