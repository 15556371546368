.loader {
  $self: &;
  display: inline-block;
  vertical-align: middle;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: background 0.3s ease-in-out;
    pointer-events: none;
  }

  &__nested-content {
    transition: all 0.2s ease-out;
    filter: none;
  }

  &--centered {
    display: block;
    margin: auto;

    .vtmn-loader {
      margin: auto;
    }
  }

  &--inverted {
    .vtmn-loader {
      color: var(--vp-core-color-white);
    }
  }

  &--overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;
    margin: 0;

    &:after {
      background-color: rgba(255, 255, 255, 0.5);
    }

    > .loader__icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  &--nesting {
    display: block;
    position: relative;
    margin: 0;

    &.loader--loading {
      .loader__nested-content {
        pointer-events: none;
        user-select: none;
        opacity: 0.5;
      }

      > .loader__icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      &::after {
        pointer-events: initial;
      }
    }
  }
}
