.administrator-data {
  &__preview {
    @include customDividerStart();
    @include customDividerEnd();
    padding-block-start: var(--vp-semantic-spacing-m);

    &__content {
      display: flex;
      align-items: center;
      gap: var(--vp-semantic-spacing-xs);
      padding-block: var(--vp-semantic-spacing-xs);
    }
  }
}
