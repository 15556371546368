.beardman {
  position: relative;
  margin-bottom: var(--vp-semantic-spacing-3xl);
  animation: bubbleUp 0.3s ease-out 0.2s backwards;
  color: var(--vp-semantic-color-content-on-brand-neutral);

  &__background {
    opacity: 0.6;
  }

  &__pull {
    fill: var(--vp-semantic-color-container-brand);
  }

  &__content {
    display: flex;
    gap: var(--vp-semantic-spacing-m);

    @include m-min {
      display: block;
    }
  }

  &__avatar {
    min-width: calc(var(--vp-semantic-spacing-xs) * 7);
    margin-right: var(--vp-semantic-spacing-xs);

    svg {
      max-width: 100%;
      height: auto;
    }

    @include m-min {
      position: absolute;
      right: 100%;
      margin-right: var(--vp-semantic-spacing-m);
    }

    @include m-min {
      width: rem(84);
      margin-right: var(--vp-semantic-spacing-2xl);
    }
  }

  &__talk {
    display: block;
    position: relative;
    background-color: var(--vp-semantic-color-container-brand);
    border-radius: var(--vp-semantic-border-radius-m);
    padding: var(--vp-semantic-spacing-m);
    flex-grow: 1;

    &--un-shrunk {
      max-block-size: 100%;
    }

    @include m-min {
      margin-inline: calc(-1 * var(--vp-semantic-spacing-m));
      padding: var(--vp-semantic-spacing-xl);
    }

    @include l-min {
      margin-inline: calc(-1 * var(--vp-semantic-spacing-xl));
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      right: 100%;
      top: var(--vp-semantic-spacing-3xl);
      border: rem(10) solid transparent;
      border-right-width: rem(10);
      border-right-color: var(--vp-semantic-color-container-brand);
      animation: fadeIn 0.1s ease-out 0.1s backwards;
      z-index: 91;
    }
  }

  &__title {
    @include m-min {
      font-size: var(--vp-semantic-typography-screen-m-title-m-font-size);
      line-height: var(--vp-semantic-typography-screen-m-title-m-line-height);
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    ul > p {
      font-weight: 600;
    }

    margin-top: var(--vp-semantic-spacing-xs);
    @include m-min {
      font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
      line-height: var(--vp-semantic-typography-screen-l-body-m-line-height);
    }

    &--clamped {
      overflow: hidden;
      max-block-size: rem(150);
      background: linear-gradient(
        180deg,
        #fff 50%,
        rgba(255, 255, 255, 0.15) 80%,
        rgba(255, 255, 255, 0) 100%
      );
      background-clip: text;
      color: transparent;
    }
  }

  &__insert {
    display: block;
    margin-top: var(--vp-semantic-spacing-s);
  }

  &__read-more .vp-link {
    color: var(--vp-semantic-color-content-on-brand-neutral);
  }
}
