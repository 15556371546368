.inbox {
  $self: &;

  display: flex;
  flex-direction: row;
  gap: var(--vp-semantic-spacing-xl);

  background: var(--vp-semantic-color-background-alternative);

  &--m-min {
    padding-top: var(--vp-semantic-spacing-xl);
    padding-bottom: var(--vp-semantic-spacing-4xl);
  }

  &__loading-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    svg {
      max-height: 50dvh;
    }
  }

  &__interaction {
    background-color: var(--vp-semantic-color-background-main);

    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: var(--vp-semantic-spacing-m);

    inline-size: 100dvw;
    block-size: 100dvh;
    overflow: scroll;

    padding: var(--vp-semantic-spacing-m);

    @include m-min {
      background-color: transparent;
      padding: 0;
      inline-size: rem(345px);
      max-block-size: calc(100dvh - #{rem(80)});
      border-radius: 0 0 var(--vp-semantic-spacing-xs) 0;
    }

    > .loader {
      &,
      .loader__nested-content {
        height: 100%;
      }
    }

    &__drawer {
      &,
      .MuiDrawer-paper {
        height: 100dvh;
      }

      .MuiDrawer-paper {
        background-color: var(--vp-semantic-color-background-main);

        > .vp-button {
          position: absolute;
          right: 0;
          top: var(--vp-semantic-spacing-s);

          color: var(--vp-semantic-color-content-neutral);
        }
      }
    }

    &__wrapper {
      background: var(--vp-semantic-color-background-main);
      border-radius: var(--vp-semantic-border-radius-s);
      color: var(--vp-semantic-color-content-neutral);

      flex-grow: 1;
      height: 100%;

      position: relative;
      overflow: hidden;

      > * {
        &:not(:first-child):not(:last-child) {
          &:after {
            content: '';
            display: block;
            height: 1px;
            background: var(--vp-semantic-color-border-quiet);
            margin: 0 var(--vp-semantic-spacing-s);
          }
        }
      }
    }

    .interaction-aside__picture {
      border-radius: var(--vp-semantic-border-radius-main);
    }

    &__header {
      > div {
        display: flex;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-m);

        padding: var(--vp-semantic-spacing-xl);
      }

      .vp-button {
        align-self: stretch;
      }
    }

    &__summary {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-m);

      padding: var(--vp-semantic-spacing-xl);

      .interaction-option__label {
        font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
      }
    }

    &__tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__contribution {
    align-items: flex-start;
    padding: var(--vp-semantic-spacing-xl);

    &,
    &__timeslots {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-m);
    }

    &__status {
      display: flex;
      flex-direction: row;

      align-items: center;
      gap: var(--vp-semantic-spacing-xs);

      font-weight: 600;

      svg {
        color: var(--vp-semantic-color-status-info);
      }

      .positive svg {
        color: var(--vp-semantic-color-status-positive);
      }
    }

    &__child-firstname {
      color: var(--vp-semantic-color-content-neutral);
      font-weight: 600;
    }

    &--submitted,
    &--unconfirmed,
    &--refused {
      --vp-semantic-color-status-info: var(--vp-semantic-color-status-info);
      color: var(--vp-semantic-color-content-neutral);
    }
  }

  &__wrapper {
    display: flex;
    align-items: stretch;
    flex-grow: 1;

    background: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-semantic-border-radius-s);
    color: var(--vp-semantic-color-content-neutral);

    height: 100dvh;

    position: relative;
    overflow: hidden;

    @include m-min {
      height: calc(100dvh - #{rem(80)});
    }

    .vp--dark-mode & {
      border: 1px solid var(--vp-semantic-color-border-quiet);
    }

    &#{$self}__no-message {
      background: transparent;

      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 5;

      > .text-center {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--vp-semantic-spacing-xs);

        max-width: rem(285px);

        > * {
          color: var(--vp-semantic-color-content-quiet);
        }
      }
    }

    &__header {
      border-block-end: 1px solid var(--vp-semantic-color-border-quiet);

      display: flex;
      flex-direction: column;
      justify-content: center;

      min-height: rem(66px);
      padding: var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-xl);

      &--tablet {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
      }
    }

    &__aside {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 100%;

      .channel__preview {
        cursor: pointer;
        padding: var(--vp-semantic-spacing-m);
        background: none;
        width: 100%;
        text-align: start;
        border-block-end: 1px solid var(--vp-semantic-color-border-quiet);

        > div {
          display: flex;
          gap: var(--vp-semantic-spacing-s);
        }

        &:hover {
          background: var(--vp-semantic-color-container-quiet);
        }

        &--is-active {
          background: var(--vp-semantic-color-alpha-brand-m);

          @include l-min {
            pointer-events: none;
          }
        }

        &__media {
          background: var(--vp-semantic-color-background-main);
          border-radius: var(--vp-semantic-border-radius-full);

          flex-shrink: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;
          overflow: hidden;

          height: rem(80);
          width: rem(80);

          .loader {
            height: 100%;
          }
        }

        &__content {
          &-title {
            color: var(--vp-semantic-color-content-neutral);
          }

          &-message {
            color: var(--vp-semantic-color-content-quiet);
            margin-bottom: var(--vp-semantic-spacing-xs);
          }

          &-date {
            color: var(--vp-semantic-color-content-brand);
            font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
          }
        }
      }

      @include l-min {
        width: 388px;
        border-inline-end: 1px solid var(--vp-semantic-color-border-quiet);
      }
    }

    main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__date-separator-wrapper {
    display: flex;
    justify-content: center;
    margin: var(--vp-semantic-spacing-m);

    > span {
      border-radius: var(--vp-core-border-radius-400);
      background-color: var(--vp-semantic-color-container-neutral);
      color: var(--vp-semantic-color-content-brand);
      font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
      padding: var(--vp-semantic-spacing-2xs) var(--vp-semantic-spacing-xs);
    }
  }

  &__divider {
    padding: var(--vp-semantic-spacing-m);
  }

  .str-chat {
    height: 100%;

    @include s-max {
      width: 100dvw;
    }

    --str-chat__message-color: var(--vp-semantic-color-content-neutral);
    --str-chat__background-color: var(--vp-semantic-color-background-main);
    --str-chat__channel-list-background-color: var(
      --vp-semantic-color-background-main
    );
    --str-chat__virtual-list-background-color: var(
      --vp-semantic-color-background-main
    );
    --str-chat__message-input-background-color: var(
      --vp-semantic-color-background-main
    );
    --str-chat__channel-list-border-inline-end: none;
    --str-chat__message-textarea-color: var(
      --vp-semantic-color-content-neutral
    );
    --str-chat__message-send-color: var(
      --vp-semantic-color-content-on-brand-neutral
    );
    --str-chat__message-send-border-radius: var(
      --vp-semantic-border-radius-full
    );
    --str-chat__message-send-background-color: var(
      --vp-semantic-color-container-brand
    );
    --str-chat__message-send-disabled-background-color: var(
      --vp-semantic-color-background-alternative
    );

    --str-chat__message-send-disabled-color: var(
      --vp-semantic-color-content-on-brand-neutral
    );

    --str-chat__message-send-disabled-background-color: var(
      --vp-semantic-color-background-alternative
    );

    &__input-flat {
      border-top: 1px solid var(--vp-semantic-color-border-inverse);
      padding: var(--vp-semantic-spacing-xl) var(--vp-semantic-spacing-xl) 0;
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      &--textarea-wrapper {
        width: 100%;
      }
    }
    &__message {
      &-textarea-container {
        border-color: var(--vp-semantic-color-border-quiet);
      }

      &-textarea-with-emoji-picker {
        .str-chat__emoji-picker-button {
          svg {
            path:first-child {
              fill: var(--vp-semantic-color-container-neutral) !important;
            }
          }
        }
      }

      &-textarea-emoji-picker {
        background: transparent;
        border-radius: var(--vp-semantic-border-radius-full);
        color: var(--vp-semantic-color-container-brand);

        svg {
          path:not(:first-child) {
            fill: var(--vp-semantic-color-container-brand) !important;
          }
        }
      }

      &-textarea-emoji-picker-container {
        right: -20px !important;
      }

      &-text {
        line-height: var(--vp-semantic-typography-screen-l-body-l-line-height);
      }

      &-input {
        padding: var(--vp-semantic-spacing-s);
        .str-chat__send-button {
          margin-left: var(--vp-semantic-spacing-s);
          svg {
            --str-chat__spacing-px: rem(8px);
          }
        }
      }
    }
    &__file-input {
      &-label {
        margin-bottom: 0;
        background: var(--vp-semantic-color-background-alternative);
        border-radius: var(--vp-semantic-border-radius-full);
      }
    }
    .message {
      &-wrapper {
        color: var(--vp-semantic-color-content-neutral);
        padding: var(--vp-semantic-spacing-m);
        align-items: flex-start;
        &,
        .message-header {
          display: flex;
          gap: var(--vp-semantic-spacing-s);
        }
        .message-header {
          margin-block-end: var(--vp-semantic-spacing-s);
          &-name {
            font-weight: 600;
          }
          &-timestamp {
            color: var(--vp-semantic-color-content-quiet);
            font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
          }
        }
        .str-chat__modal--open {
          z-index: 300;
        }
      }
      &-system {
        display: flex;
        align-items: flex-start;
        gap: var(--vp-semantic-spacing-xs);
        background: var(--vp-semantic-color-background-alternative);
        border-radius: var(--vp-core-border-radius-100);
        padding: var(--vp-semantic-spacing-xs);
        p {
          flex-grow: 1;
          font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
          color: var(--vp-semantic-color-content-quiet);
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .emoji-mart {
    background: var(--vp-semantic-color-background-main);
    border-color: var(--vp-semantic-color-border-inverse);
  }
  .emoji-mart-category-label span,
  .emoji-mart-search input {
    background: var(--vp-semantic-color-background-main);
    color: var(--vp-semantic-color-content-quiet);
  }
}
