.popper {
  $self: &;
  z-index: 300;

  &__content {
    background-color: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-semantic-border-radius-s);
    box-shadow: 0 0.375rem 0.375rem 0 var(--vp-semantic-color-alpha-brand-s);
    padding: var(--vp-semantic-spacing-m);
    position: relative;
  }

  &__opener {
    display: flex;
    align-items: center;
  }

  &__arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  &[data-popper-placement*='bottom'] {
    &#{$self}--with-arrow {
      .popper__content {
        margin-top: 1em;
      }
    }

    #{$self}__arrow {
      top: 0;
      left: 0;
      margin-top: -1em;
      width: 3em;
      height: 1em;

      &::before {
        border-width: 0 1em 1em 1em;
        border-color: transparent transparent
          var(--vp-semantic-color-background-alternative) transparent;
      }
    }
  }

  &[data-popper-placement*='top'] {
    padding-bottom: var(--vp-semantic-spacing-s);

    &#{$self}--with-arrow {
      .popper__content {
        margin-bottom: 1px;
      }
    }

    #{$self}__arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -1em;
      width: 3em;
      height: 1em;

      &::before {
        border-width: 1em 1em 0 1em;
        border-color: var(--vp-semantic-color-background-alternative)
          transparent transparent transparent;
      }
    }
  }

  &[data-popper-placement*='right'] {
    padding-left: var(--vp-semantic-spacing-m);

    &#{$self}--with-arrow {
      .popper__content {
        margin-left: 1em;
      }
    }

    #{$self}__arrow {
      left: 0;
      margin-left: -1em;
      height: 3em;
      width: 1em;

      &::before {
        border-width: 1em 1em 1em 0;
        border-color: transparent
          var(--vp-semantic-color-background-alternative) transparent
          transparent;
      }
    }
  }

  &[data-popper-placement*='left'] {
    padding-right: var(--vp-semantic-spacing-m);

    &#{$self}--with-arrow {
      .popper__content {
        margin-right: 1em;
      }
    }

    #{$self}__arrow {
      right: 0;
      margin-right: -1em;
      height: 3em;
      width: 1em;

      &::before {
        border-width: 1em 0 1em 1em;
        border-color: transparent transparent transparent
          var(--vp-semantic-color-background-alternative);
      }
    }
  }

  &__option {
    cursor: pointer;

    align-items: center;
    display: flex;
    gap: var(--vp-semantic-spacing-xs);

    background-color: transparent;
    color: var(--vp-semantic-color-content-neutral);

    padding: var(--vp-semantic-spacing-s);
    white-space: nowrap;

    transition: backgroundColor 0.2s ease-out;

    &:hover {
      color: var(--vp-semantic-color-content-brand);
    }

    &--active,
    &--active:hover,
    &--active svg {
      color: var(--vp-semantic-color-content-brand);
      font-weight: 600;
    }

    &:disabled {
      cursor: default;
    }
  }
}
