@use 'sass:math';

.home-hero {
  .hero {
    background-color: var(--vp-semantic-color-background-main);

    &__slide {
      background-color: var(--vp-semantic-color-container-brand);

      block-size: 80vh;
      max-block-size: 90vh;
      position: relative;

      display: flex;
      flex-direction: column;

      @include m-min {
        display: grid;
        grid-template-columns:
          var(--vp-semantic-spacing-l)
          calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
          repeat(12, 1fr)
          calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
          var(--vp-semantic-spacing-l);

        block-size: 60vh;
      }
    }

    &__media {
      position: relative;
      flex-grow: 1;

      @include m-min {
        grid-column: 1 / 10;
      }
    }

    &__headings {
      display: block;
    }

    &__text {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: center;
      gap: var(--vp-semantic-spacing-s);

      padding-block: var(--vp-semantic-spacing-2xl);
      padding-inline: var(--vp-semantic-spacing-l);

      @include m-min {
        grid-column: 10 / 15;
        padding-inline: var(--vp-semantic-spacing-4xl);
      }

      .vp-button {
        background: var(--vp-semantic-color-content-on-brand-neutral);
      }
    }

    &__title {
      color: var(--vp-semantic-color-content-on-brand-neutral);
      text-wrap: balance;
    }

    &__subtitle {
      display: block;
      margin-top: var(--vp-semantic-spacing-xs);
      font-size: rem(16);
      line-height: math.div(22em, 16);
      font-weight: 500;
      color: var(--vp-semantic-color-content-on-brand-neutral);
    }
  }
}
