@use 'sass:math';

.footer {
  $self: &;
  padding-block: var(--vp-semantic-spacing-3xl);
  background: var(--vp-semantic-color-container-brand);

  @include m-min {
    padding-block: var(--vp-semantic-spacing-5xl);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xl);
  }

  &__main {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xl);

    @include m-min {
      flex-direction: row;
    }

    @include l-min {
      flex-wrap: nowrap;
      gap: var(--vp-semantic-spacing-8xl);
    }
  }

  &__top {
    padding-block-end: var(--vp-semantic-spacing-2xl);
    border-bottom: 1px solid var(--vp-core-color-white);
  }

  section:not(.footer__switchers),
  nav ul {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xs);
  }

  &__bottom {
    nav ul {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: var(--vp-semantic-spacing-l);
      justify-content: flex-start;
      opacity: 0.7;
    }
  }

  &__logo {
    .cocreation-logo {
      > span {
        color: var(--vp-semantic-color-content-on-brand-neutral);
      }
    }
  }

  &__main,
  &__logo {
    max-inline-size: rem(300);

    @include m-min {
      max-inline-size: none;
    }
  }

  &__switchers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--vp-semantic-spacing-xs);
    color: var(--vp-semantic-color-content-on-brand-neutral);

    @include m-min {
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      flex-grow: 1;
    }

    .vp-link {
      color: var(--vp-semantic-color-content-on-brand-neutral);
    }

    &__divider {
      @include m-min {
        display: block;
        content: '';
        background-color: var(--vp-semantic-color-content-on-brand-neutral);
        width: rem(3);
        height: rem(3);
        border-radius: var(--vp-semantic-border-radius-full);
      }
    }
  }

  &__title {
    font-weight: 600;
    color: var(--vp-semantic-color-content-on-brand-neutral) !important;
    opacity: 0.7;
    text-transform: uppercase !important;
  }

  nav li > button {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
}
