.custom-swipeable-drawer {
  .MuiDrawer-paper {
    background-color: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-core-border-radius-400)
      var(--vp-core-border-radius-400) 0 0;
    color: var(--vp-semantic-color-content-main);
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-xl)
      var(--vp-semantic-spacing-xl) var(--vp-semantic-spacing-xl);
    gap: var(--vp-semantic-spacing-xl);
    text-align: start;

    @include m-min {
      padding: var(--vp-semantic-spacing-xs);
      align-items: center;
      text-align: start;
    }
  }

  &__swipe-indicator {
    inline-size: var(--vp-semantic-spacing-3xl);
    border: 1px solid var(--vp-semantic-color-border-quiet);
    border-radius: var(--vp-core-border-radius-100);
    align-self: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .vp-button {
      position: relative;
      right: -(var(--vp-semantic-spacing-s));
      top: -(var(--vp-semantic-spacing-m));
      padding: 0;
      height: var(--vp-semantic-spacing-l);

      &:hover {
        background-color: transparent;
      }
    }
  }
}
