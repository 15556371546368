.page-header {
  display: flex;
  align-items: center;
  max-height: calc(var(--vp-semantic-spacing-xs) * 7.5);
  border-bottom: 1px solid var(--vp-semantic-color-border-quiet);
  background-color: var(--vp-semantic-color-background-main);

  @include m-min {
    display: none;
  }
}

.vp-breadcrumbs {
  margin-block-start: var(--vp-semantic-spacing-m);
}
