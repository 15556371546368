@use 'sass:math';

.migration_page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  text-align: center;
  background-color: var(--vp-semantic-color-background-main);
  z-index: 300;

  &::before {
    content: '';
    display: block;
    background-color: var(--vp-semantic-color-container-brand);
    height: 80%;
    transform: skewY(-8deg);
    transform-origin: left top;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }

  &::after {
    content: '';
    display: block;
    background-color: rgba(0, 0, 0, 0.1);
    height: rem(60);
    transform: skewY(-8deg);
    transform-origin: left top;
    position: absolute;
    left: 0;
    bottom: 20%;
    right: 0;
    border-bottom: rem(45) solid rgba(0, 0, 0, 0.05);
  }

  .migration_logo {
    position: relative;
    padding: rem(17);
    color: var(--vp-semantic-color-content-neutral);
    font-weight: 600;
    font-style: italic;
    font-size: rem(24);
    text-transform: uppercase;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.1);

    &:after {
      content: ' co-creation';
    }
  }

  .migration_title {
    display: block;
    font-size: var(--vp-semantic-spacing-2xl);
    margin-bottom: var(--vp-semantic-spacing-m);
  }

  .migration_message {
    width: calc(100vw - #{rem(40)});
    max-width: rem(640);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: rem(24);
    line-height: math.div(32em, 24);
    color: var(--vp-semantic-color-content-neutral);
    font-weight: 600;
    font-style: italic;

    a {
      text-decoration: underline;
    }

    @include s-max {
      font-size: rem(20);
    }
  }

  .migration_icon {
    display: block;
    margin: 0 auto var(--vp-semantic-spacing-m);

    svg {
      display: block;
      margin: auto;
      font-size: rem(40);
    }
  }
}
