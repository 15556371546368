.contact-form {
  padding-top: var(--vp-semantic-spacing-4xl);
  padding-bottom: var(--vp-semantic-spacing-4xl);

  &__confirmation {
    padding-top: var(--vp-semantic-spacing-3xl);
    padding-bottom: var(--vp-semantic-spacing-3xl);
    text-align: center;
  }

  .vtmn-text-input_container,
  .vtmn-text-input {
    display: block;
    width: 100%;
  }
}

.contact-popin {
  display: flex;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-l);

  .form-group {
    .vtmn-text-input_container,
    input,
    textarea {
      inline-size: 100%;
      block-size: rem(200);
      resize: vertical;
    }
  }

  &__action {
    margin-top: var(--vp-semantic-spacing-m);
  }
}
