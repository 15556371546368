.chat-header {
  &__title {
    font-weight: 600;
    color: var(--vp-semantic-color-content-neutral);
  }

  &__contribution-status {
    display: flex;
    align-items: center;
    gap: var(--vp-semantic-spacing-2xs);

    &__label {
      color: var(--vp-semantic-color-content-quiet);
    }

    svg {
      color: var(--vp-semantic-color-status-info);
    }

    .positive svg {
      color: var(--vp-semantic-color-status-positive);
    }
  }

  &__informations-button {
    margin-left: auto;
  }

  &__media {
    margin-right: var(--vp-semantic-spacing-xs);
    background: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-semantic-border-radius-full);

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;

    height: rem(40);
    width: rem(40);

    .loader {
      height: 100%;
    }
  }
}
