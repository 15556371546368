.auth-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{rem(60 + 80)});
  max-inline-size: rem(500);
  margin: auto;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    padding-block: var(--vp-semantic-spacing-m);
    padding-inline: var(--vp-semantic-spacing-m);

    @include m-min {
      padding-block: var(--vp-semantic-spacing-2xl);
      padding-inline: var(--vp-semantic-spacing-2xl);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-2xs);
    margin-bottom: var(--vp-semantic-spacing-2xl);
  }

  &__footer {
    display: flex;
    gap: var(--vp-semantic-spacing-m);

    &:has(:nth-child(2)) {
      @include m-min {
        justify-content: flex-end;
      }
    }
  }
}
