.banner {
  background-color: var(--vp-semantic-color-container-commercial-neutral);
  color: var(--vp-semantic-color-content-on-commercial-neutral);
  padding: var(--vp-semantic-spacing-2xl) var(--vp-semantic-spacing-l);

  @include l-min {
    padding: var(--vp-semantic-spacing-5xl) var(--vp-semantic-spacing-8xl);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--vp-semantic-spacing-2xl);

    @include l-min {
      display: flex;
      flex-direction: row;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    @include l-min {
      align-items: flex-start;
    }

    &__title,
    &__description {
      text-align: center;
      text-wrap: balance;

      @include m-min {
        max-inline-size: 80%;
      }

      @include l-min {
        text-align: start;
        text-wrap: inherit;
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-l);

      @include l-min {
        flex-direction: row;
        justify-content: flex-start;
        gap: var(--vp-semantic-spacing-2xl);
      }

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--vp-semantic-spacing-m);
      }
    }
  }
}

.sign-up-guest-banner {
  .container-fluid-centered {
    padding-inline: 0;

    @include l-min {
      padding-inline: var(--vp-semantic-spacing-m);
    }
  }
}
