.translate {
  align-items: center;
  column-gap: var(--vp-semantic-spacing-2xs);
  display: flex;
  flex-wrap: wrap;

  color: var(--vp-semantic-color-content-quiet);

  margin-block-start: var(--vp-semantic-spacing-m);

  &__separator {
    content: '';

    border-radius: var(--vp-semantic-border-radius-full);
    background: var(--vp-semantic-color-content-quiet);

    display: block;
    block-size: rem(3);
    inline-size: rem(3);
  }

  &__google-link {
    align-items: center;
    display: flex;
  }

  &__rating {
    position: relative;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--vp-semantic-spacing-s);
      padding: var(--vp-semantic-spacing-xs);
    }

    &__stars {
      display: flex;
      justify-content: center;
    }

    &__submit-action {
      margin-block-start: var(--vp-semantic-spacing-xs);

      @include m-min {
        margin-block-start: 0;
      }

      &.vp-button {
        align-self: stretch;

        @include m-min {
          align-self: center;
        }
      }
    }

    &__success {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--vp-semantic-spacing-2xs);
      margin: var(--vp-semantic-spacing-s) 0;

      @include m-min {
        margin: var(--vp-semantic-spacing-2xs) 0;
      }
    }

    &__success-message {
      color: var(--vp-semantic-color-content-brand);
      font-weight: 600;
    }
  }

  &__action {
    padding: 0 !important;
    height: var(--vp-semantic-spacing-l) !important;

    .vp-button__label {
      text-transform: none;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

.beardman__talk .translate {
  color: var(--vp-semantic-color-content-on-brand-neutral);

  &__separator {
    background: var(--vp-semantic-color-content-on-brand-neutral);
  }

  &__action {
    color: var(--vp-semantic-color-content-on-brand-neutral);
  }
}
