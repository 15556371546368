.accessibility-page {
  h2 {
    margin: var(--vp-semantic-spacing-2xl) 0 0;
  }

  h3 {
    margin: var(--vp-semantic-spacing-2xl) 0 0;
  }

  h4 {
    margin: var(--vp-semantic-spacing-2xl) 0 0;
  }

  p {
    margin: var(--vp-semantic-spacing-s) 0 0;
  }

  li {
    list-style: initial;
    &::before {
      content: none;
    }
  }

  &__banner {
    inline-size: fit-content;
    block-size: fit-content;
    color: var(--vp-semantic-color-content-on-commercial-neutral);
    background: var(--vp-semantic-color-container-commercial-neutral);
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding-block: var(--vp-semantic-spacing-m);
    padding-inline: var(--vp-semantic-spacing-l);
    margin-block-start: var(--vp-semantic-spacing-xl);

    @media only screen and (max-width: 820px) {
      block-size: auto;
      align-items: flex-start;
      flex-direction: column;
      padding: var(--vp-semantic-spacing-m);
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-inline-size: 30%;
      margin-block: var(--vp-semantic-spacing-l);
      margin-inline: var(--vp-semantic-spacing-xl);
    }

    & div:not(:nth-child(1)) {
      margin-block-start: 0;
      margin-block-end: 0;

      @media only screen and (max-width: 1440px) {
        margin: var(--vp-semantic-spacing-m);
      }

      @media only screen and (max-width: 820px) {
        max-inline-size: 100%;
        margin-block: var(--vp-semantic-spacing-m);
        margin-inline: var(--vp-semantic-spacing-xl);
      }

      @media only screen and (max-width: 375px) {
        margin: var(--vp-semantic-spacing-m);
      }
    }

    &__conform-circle {
      min-block-size: rem(215);
      min-inline-size: rem(215);
      border: 8px solid var(--vp-semantic-color-border-brand);
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      & > h2 {
        display: inline-flex;
        align-self: center;
        margin: 0;
      }
    }
  }

  .indent {
    position: relative;
    inset-inline-start: var(--vp-semantic-spacing-l);
  }
}
