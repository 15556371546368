@use 'sass:math';

.project-tabs-skeleton {
  .project-tabs__tabs {
    gap: var(--vp-semantic-spacing-2xs);
  }
  .project-tabs__tab {
    position: relative;
    min-width: rem(100);

    .vtmn-skeleton {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .project-tabs__follow {
    .vtmn-skeleton {
      height: rem(50);
      min-width: rem(180);
    }
  }
}

.project-tabs {
  background-color: var(--vp-semantic-color-background-main);
  border-block-end: 1px solid var(--vp-semantic-color-border-quiet);

  position: sticky;
  inset-block-start: 0;

  z-index: 50;
  transition: top 0.2s ease-out;

  .is-scrolling-up & {
    inset-block-start: rem(80);

    @include m-min {
      inset-block-start: rem(60);
    }
  }

  &__content {
    @include m-min {
      display: flex;
      justify-content: space-between;
    }
  }

  &__follow {
    display: flex;
    align-items: center;
    padding: 0 var(--vp-semantic-spacing-xl);

    @include s-max {
      display: none;
    }
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m-min {
      justify-content: flex-start;
    }
  }

  &__tab {
    min-height: var(--vp-semantic-spacing-6xl);
    border-radius: var(--vp-semantic-border-radius-main);
    border: none;
    border-bottom: 2px solid transparent;
    background: none;
    padding: 0 var(--vp-semantic-spacing-xl);
    font-size: var(--vp-semantic-spacing-m);
    font-weight: 500;
    color: var(--vp-semantic-color-content-quiet);
    transition: all 0.2s ease;
    cursor: pointer;
    flex-grow: 1;

    &:disabled {
      color: var(--vp-semantic-color-content-quiet);
      cursor: default;
    }

    @include m-min {
      flex-grow: 0;
    }

    &--active {
      color: var(--vp-semantic-color-content-brand);
      border-color: var(--vp-semantic-color-border-brand);
      font-weight: 600;
    }

    &:focus {
      outline-offset: 1px;
    }
  }

  &__counter {
    display: inline-block;
    margin-left: var(--vp-semantic-spacing-xs);
    padding-inline: var(--vp-semantic-spacing-2xs);
    color: var(--vp-semantic-color-content-on-brand-neutral);
    background-color: var(--vp-semantic-color-container-brand);
    text-align: center;
    line-height: rem(20);
    min-inline-size: var(--vp-semantic-spacing-l);
    border-radius: var(--vp-semantic-border-radius-full);
    font-size: rem(14);
  }
}

.project-card {
  background-color: var(--vp-semantic-color-background-main);
  border-radius: var(--vp-semantic-border-radius-main);
  border: var(--vp-semantic-border-width-s) solid
    var(--vp-semantic-color-border-quiet);

  display: block;
  position: relative;
  padding: var(--vp-semantic-spacing-xl);

  &:hover {
    border-color: var(--vp-semantic-color-border-brand);
    transition: all 0.2s ease;
  }

  & + & {
    margin-top: var(--vp-semantic-spacing-3xl);
  }

  &__block {
    padding: var(--vp-semantic-spacing-m);
    width: 100%;
  }

  &__opener {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    cursor: pointer;
    z-index: 12;

    &:focus,
    &:hover {
      outline: none;
    }
  }
}

.announcement-list,
.card-detail,
.interaction-detail,
.steps {
  padding: var(--vp-semantic-spacing-3xl) var(--vp-semantic-spacing-m);
}

.interactions-list {
  &__empty {
    padding: 0 var(--vp-semantic-spacing-xl);
    text-align: center;
    font-weight: 600;
    line-height: math.div(24em, 16);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-3xl);
  }

  &.news {
    max-width: rem(760);
    margin: auto;
  }
}

.ongoing-interactions {
  &__item {
    padding-bottom: var(--vp-semantic-spacing-2xl);

    & + & {
      padding-top: var(--vp-semantic-spacing-xl);
      border-top: 1px solid var(--vp-semantic-color-border-quiet);

      @include m-min {
        padding-top: var(--vp-semantic-spacing-2xl);
      }
    }
  }
}

.project-tabpanes {
  position: relative;
  width: 100%;

  &__frame {
    position: relative;

    @include m-max {
      overflow-x: hidden;
    }
  }

  &__tabpane {
    min-height: 80vh;
  }
}

.card-detail {
  display: none;
  position: relative;
  width: 100%;
  padding: var(--vp-semantic-spacing-3xl) var(--vp-semantic-spacing-m);
  z-index: 20;
  background-color: var(--vp-semantic-color-background-main);
  transition: all 0.2s ease-out;

  @include m-min {
    padding-inline: var(--vp-semantic-spacing-3xl);
  }

  &__close {
    margin-bottom: var(--vp-semantic-spacing-m);
  }

  &--opened {
    display: block;
  }
}

.project-detail {
  &__wrapper {
    $total-columns: 12;
    $aspect-ratio: 9 / 16;
    $column-gap: var(--vp-semantic-spacing-2xl);

    &--is-shrunk {
      @include m-min {
        $columns-occupied: 6;
        padding-top: calc(
          (
              (
                  $column-gap * $columns-occupied / $total-columns +
                    $columns-occupied / $total-columns * 100%
                ) *
                $aspect-ratio
            ) - var(--vp-semantic-spacing-2xl) /
            2
        );

        position: relative;
        overflow: hidden;
      }

      @include l-min {
        $columns-occupied: 7;
        padding-top: calc(
          (
              (
                  $column-gap * $columns-occupied / $total-columns +
                    $columns-occupied / $total-columns * 100%
                ) *
                $aspect-ratio
            ) - var(--vp-semantic-spacing-2xl) /
            2
        );
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: var(--vp-semantic-spacing-m);

    position: relative;
    padding-block-start: var(--vp-semantic-spacing-m);

    @include m-min {
      align-items: flex-start;
      column-gap: var(--vp-semantic-spacing-2xl);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto minmax(auto, 1fr) auto;
      justify-content: center;
      row-gap: var(--vp-semantic-spacing-xs);

      padding-block-start: 0;

      &--is-shrunk {
        position: absolute;
        inset: 0;
      }
    }

    &:has(.project-detail__read-more) {
      @include m-min {
        grid-template-rows: auto minmax(auto, 1fr) auto auto;
      }
    }

    &--with-translation {
      @include m-min {
        grid-template-rows: auto auto minmax(auto, 1fr) auto;
      }

      &:has(.project-detail__read-more) {
        @include m-min {
          grid-template-rows: auto auto minmax(auto, 1fr) auto auto;
        }
      }
    }
  }

  &__author,
  &__description,
  &__read-more,
  .translate {
    @include m-min {
      grid-column: 7 / -1;
    }

    @include l-min {
      grid-column: 8 / -1;
    }
  }

  .interaction-share {
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;
    transform: translateY(-100%);
    z-index: 5;

    @include m-min {
      justify-self: flex-end;
      grid-column: 12 / -1;
      position: relative;
      transform: translateY(0);
    }
  }

  &__title {
    text-wrap: balance;

    @include m-min {
      align-self: flex-end;
      grid-column: 7 / 12;
    }

    @include l-min {
      grid-column: 8 / 12;
    }
  }

  &__media {
    @include m-min {
      grid-column: 1 / 7;
      grid-row: 1 / 5;
    }

    @include l-min {
      grid-column: 1 / 8;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    position: relative;
    overflow: hidden;

    max-block-size: rem(24) * 8;

    &--un-shrunk {
      max-block-size: 100%;
    }

    @include m-min {
      max-block-size: none;
      block-size: 100%;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &--clamped {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      overflow: hidden;

      @include m-min {
        -webkit-line-clamp: var(--line-clamp);
      }

      img,
      iframe {
        display: none;
      }
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      list-style-position: inside;
    }
  }

  &__author {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);

    .author {
      margin-block: 0;
    }
  }

  &__follow {
    @include m-min {
      display: none;
    }
  }

  .translate {
    margin-block-start: 0;
  }
}

.project-banner {
  background-color: var(--vp-semantic-color-background-alternative);

  &--separator {
    border-top: 1px solid var(--vp-semantic-color-content-on-brand-neutral);
    padding-top: var(--vp-semantic-spacing-m);
    margin-top: var(--vp-semantic-spacing-xl);
  }

  &--bottom {
    border-radius: var(--vp-core-border-radius-100);
  }

  &--beardman {
    background-color: var(--vp-semantic-color-container-brand);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--vp-semantic-spacing-m);
    gap: var(--vp-semantic-spacing-m);

    &--beardman {
      justify-content: flex-start;
      padding: 0;
    }

    @include m-min {
      flex-direction: row;
      align-items: center;
      gap: var(--vp-semantic-spacing-3xl);
    }
  }

  &__label {
    font-weight: 600;
    font-size: rem(14);

    &--beardman {
      font-weight: 500;
      font-size: rem(16);
    }
  }
}
