@use 'sass:math';

.about {
  &__header {
    display: flex;
    flex-direction: column;
    position: relative;

    @include l-min {
      display: grid;
      grid-template-rows: auto repeat(3, 1fr);
      grid-template-columns:
        var(--vp-semantic-spacing-l)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        repeat(12, 1fr)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        var(--vp-semantic-spacing-l);
      column-gap: var(--vp-semantic-spacing-l);
    }

    &::before {
      @include l {
        position: absolute;
        content: '';
        inset: 0;
        grid-row: 1 / 5;
        grid-column: 1 / 11;

        background-color: var(--vp-semantic-color-container-brand);
      }

      @include xl-min {
        position: absolute;
        content: '';
        inset: 0;
        grid-row: 1 / 3;
        grid-column: 1 / 11;

        background-color: var(--vp-semantic-color-container-brand);
      }
    }

    &__picture {
      position: relative;
      flex-grow: 1;

      &::before {
        display: block;
        content: '';
        padding-block-start: 75%;
      }

      @include l-min {
        grid-row: 1;
        grid-column: 9 / 17;
      }
    }

    &__statistics {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--vp-semantic-spacing-l);
      padding: var(--vp-semantic-spacing-l) 0;

      color: var(--vp-semantic-color-content-inverse);
      background-color: var(--vp-semantic-color-container-catchy);

      @include l-min {
        z-index: 100;

        grid-row: 2 / 4;
        grid-column: 10 / 15;
      }

      @include m {
        z-index: 100;

        inline-size: fit-content;
        margin: -104px auto 0;
        padding: var(--vp-semantic-spacing-l);
      }

      &__divider {
        inline-size: 1px;
        block-size: 100%;
        padding: var(--vp-semantic-spacing-l) 0;

        &::before {
          display: block;
          content: '';
          inline-size: 100%;
          block-size: 100%;
          background-color: var(--vp-semantic-color-content-inverse);
        }
      }

      .statistic {
        display: flex;
        flex-direction: column;
      }
    }

    &__text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-xl);
      padding: var(--vp-semantic-spacing-6xl) var(--vp-semantic-spacing-l);

      color: var(--vp-semantic-color-content-on-brand-neutral);
      background-color: var(--vp-semantic-color-container-brand);

      @include l {
        grid-column: 4 / 8;
        padding: 0;
        justify-content: flex-end;
      }

      @include m {
        padding: var(--vp-semantic-spacing-5xl) var(--vp-semantic-spacing-8xl);
      }

      @include xl-min {
        grid-column: 3 / 7;
        padding: var(--vp-semantic-spacing-9xl) 0 0;
      }

      h1 {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__introduction {
    padding-block-start: var(--vp-semantic-spacing-5xl);
    margin-block-end: var(--vp-semantic-spacing-5xl);

    @include m-min {
      text-align: center;
      max-inline-size: rem(866px);
      margin: 0 auto var(--vp-semantic-spacing-5xl);
    }

    @include l-min {
      padding-block-start: var(--vp-semantic-spacing-7xl);
    }

    > * + * {
      margin-block-start: var(--vp-semantic-spacing-2xl);
    }

    button {
      display: none;

      @include m-min {
        display: inline-flex;
      }
    }
  }

  &__content {
    &__picture {
      position: relative;
      flex-grow: 1;

      &::before {
        display: block;
        content: '';
        padding-block-start: 75%;
      }

      @include m-min {
        inline-size: 50%;
      }
    }

    &__description {
      @include m-min {
        inline-size: 50%;
      }
    }

    &__element {
      margin-block-end: var(--vp-semantic-spacing-7xl);

      @include m-min {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--vp-semantic-spacing-s);
      }

      .about__content__picture {
        margin-block-end: var(--vp-semantic-spacing-2xl);
      }

      h2 {
        margin-block-end: var(--vp-semantic-spacing-xl);
      }

      p.vp-caption {
        margin-block-start: var(--vp-semantic-spacing-xs);
        color: var(--vp-semantic-color-content-quiet);
      }

      &:nth-child(even) {
        .about__content__picture {
          @include m-min {
            order: 1;
            margin-inline-start: auto;
            margin-inline-end: calc(-1 * var(--vp-semantic-spacing-l));
          }
        }

        .about__content__description {
          @include l-min {
            padding-inline-start: var(--vp-semantic-spacing-6xl);
            padding-inline-end: var(--vp-semantic-spacing-5xl);
          }

          @include m {
            padding-inline-start: var(--vp-semantic-spacing-3xl);
            padding-inline-end: var(--vp-semantic-spacing-4xl);
          }
        }
      }

      &:nth-child(odd) {
        .about__content__picture {
          @include m-min {
            margin-inline-start: calc(-1 * var(--vp-semantic-spacing-l));
            margin-inline-end: auto;
          }
        }

        .about__content__description {
          @include l-min {
            padding-inline-start: var(--vp-semantic-spacing-5xl);
            padding-inline-end: var(--vp-semantic-spacing-6xl);
          }

          @include m {
            padding-inline-start: var(--vp-semantic-spacing-4xl);
            padding-inline-end: var(--vp-semantic-spacing-3xl);
          }
        }
      }
    }
  }

  &__banner {
    padding: var(--vp-semantic-spacing-3xl) var(--vp-semantic-spacing-3xl)
      var(--vp-semantic-spacing-4xl) var(--vp-semantic-spacing-3xl);

    color: var(--vp-semantic-color-content-on-brand-neutral);
    background-color: var(--vp-semantic-color-container-brand);

    @include l-min {
      display: grid;
      grid-template-columns:
        var(--vp-semantic-spacing-l)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        repeat(12, 1fr)
        calc(50vw - rem(math.div(map-get($container-max-widths, 'xl'), 2)))
        var(--vp-semantic-spacing-l);
      column-gap: var(--vp-semantic-spacing-l);

      padding: 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: var(--vp-semantic-spacing-2xl);

      &__action {
        align-self: flex-start;
      }

      @include l-min {
        padding: var(--vp-semantic-spacing-6xl) 0;
        grid-column: 4 / 9;
      }
    }

    &__picture {
      display: none;

      @include l-min {
        display: block;
        position: relative;
        flex-grow: 1;
        grid-column: 10 / 14;
        margin: calc(-1 * var(--vp-semantic-spacing-l)) 0;
      }

      &::before {
        @include l-min {
          display: block;
          content: '';
          padding-block-start: 125%;
        }
      }
    }
  }
  &__footer {
    padding: var(--vp-semantic-spacing-5xl) 0;

    @include l-min {
      padding: var(--vp-semantic-spacing-7xl) 0;
    }

    &__content {
      @include l-min {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: repeat(12, 1fr);
        column-gap: var(--vp-semantic-spacing-l);
        margin-block-start: var(--vp-semantic-spacing-4xl);
      }

      &__card {
        margin-block-start: var(--vp-semantic-spacing-4xl);

        @include l-min {
          margin-block-start: 0;
          padding-block-end: var(--vp-semantic-spacing-2xl);
        }

        &:nth-child(1) {
          @include l-min {
            grid-row: 2;
            grid-column: 1 / 5;
          }
        }

        &:nth-child(2) {
          @include l-min {
            grid-row: 1 / 3;
            grid-column: 5 / 9;
          }
        }

        &:nth-child(3) {
          @include l-min {
            grid-row: 2;
            grid-column: 9 / 13;
          }
        }
      }

      &__description {
        h3 {
          margin: var(--vp-semantic-spacing-l) 0 var(--vp-semantic-spacing-xs) 0;
        }

        p {
          color: var(--vp-semantic-color-content-quiet);
        }

        p.vp-body-m {
          font-style: italic;
          margin-block-end: var(--vp-semantic-spacing-xs);
        }
      }

      &__picture {
        position: relative;

        &::before {
          display: block;
          content: '';
          padding-block-start: 80%;
        }

        &--main {
          &::before {
            padding-block-start: 100%;
          }
        }
      }

      &__link {
        @include l-min {
          position: absolute;
          inset-block-end: var(--vp-semantic-spacing-7xl);
        }
      }
    }
  }

  &__mobile-banner {
    position: sticky;
    inset-block-end: 0;
    inset-inline-start: 0;
    inline-size: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-l);

    background-color: var(--vp-semantic-color-container-neutral);

    p {
      inline-size: 50dvw;
      font-weight: var(--vp-semantic-typography-screen-l-title-l-font-weight);
    }

    @include l-min {
      display: none;
    }
  }
}
