.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--vp-semantic-spacing-xs);
  text-align: center;

  &__top {
    text-transform: uppercase;

    > * {
      color: var(--vp-core-color-grey-600);
    }
  }

  &__title {
    color: var(--vp-semantic-color-content-neutral);
  }

  &__description {
    margin-top: var(--vp-semantic-spacing-xs);

    p {
      color: var(--vp-semantic-color-content-quiet);
    }
  }

  &__picture {
    margin-top: var(--vp-semantic-spacing-xl);
    padding: rem(10);
    border-radius: var(--vp-semantic-border-radius-s);
    background-color: var(--vp-semantic-color-background-main);
    width: 100%;
    max-width: rem(430);
  }

  &__media {
    position: relative;
    text-align: center;
  }

  &__inner {
    display: inline-block;
    position: relative;
    min-height: rem(200);
    max-height: 40vh;

    // reset next/image for display specificities
    span[style] > span[style] {
      display: none !important;
    }

    img {
      display: block;
      margin: auto;
      @include resetNextImage();
    }
  }

  &__cta {
    margin-top: var(--vp-semantic-spacing-2xl);

    button {
      margin: auto;
    }
  }
}
