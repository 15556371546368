.comment-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--vp-semantic-spacing-xl);
  background-color: var(--vp-semantic-color-alpha-brand-s);
  padding: 24px 16px;

  @include l-min {
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-xl);
  }
}
