@use 'sass:math';

.sports-select {
  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.sports-filter {
  &__placeholder {
    display: inline-flex;
    gap: var(--vp-semantic-spacing-2xs);
  }

  // React Select
  &__option-icon {
    margin-right: var(--vp-semantic-spacing-xs);

    svg {
      display: block;
    }

    &--selected {
      color: var(--vp-semantic-color-content-brand);
    }
  }

  &__counter {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: var(--vp-core-border-radius-100);
    background-color: var(--vp-semantic-color-background-alternative);
    padding: 0 var(--vp-semantic-spacing-xs);
    color: var(--vp-semantic-color-content-brand);
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(32);
    margin: 0 1px;
  }
}
