@use 'sass:math';

.results {
  $r: &;

  &__confirmation {
    margin-bottom: var(--vp-semantic-spacing-xl);
  }

  &__question-picture {
    margin: 0 auto var(--vp-semantic-spacing-m);
    max-width: rem(600);
    width: 100%;
    text-align: center;
  }

  &__question-picture-inner {
    position: relative;
    display: inline-block;

    // reset next/image for display specificities
    span[style] > span[style] {
      display: none !important;
    }

    img {
      @include resetNextImage();
    }
  }

  &__question {
    margin-top: var(--vp-semantic-spacing-xl);

    &--choices {
      #{$r}__items {
        display: grid;
        grid-column-gap: var(--vp-semantic-spacing-xs);
        grid-row-gap: var(--vp-semantic-spacing-xs);
        text-align: center;
        margin: auto;
        width: 100%;

        // auto-fit items globally
        grid-template-columns: repeat(
          auto-fit,
          minmax(math.div(100%, 2) - 1, 1fr)
        );

        @include m-min {
          grid-template-columns: repeat(
            auto-fit,
            minmax(math.div(100%, 4) - 1, 1fr)
          );
        }

        @include l-min {
          grid-template-columns: repeat(
            auto-fit,
            minmax(math.div(100%, 5) - 1, 1fr)
          );
        }

        // limit grid container with if less than 4 items
        $maxWidth: 240;
        @mixin drawColumns($index) {
          max-width: rem($maxWidth * $index);
          grid-template-columns: repeat($index, 1fr);
        }

        @for $i from 1 through 3 {
          // apply 3 columns only over mobile breakpoint
          $breakpoint: if($i == 3, mediaquery(s-min), 0);
          @media (min-width: $breakpoint) {
            &--by-#{$i} {
              @include drawColumns($i);
            }
          }
        }
      }

      #{$r}__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: var(--vp-semantic-color-background-main);
        border-radius: var(--vp-core-border-radius-100);
        padding: var(--vp-semantic-spacing-xs);

        &--empty {
          #{$r}__picture {
            background-color: var(--vp-semantic-color-background-alternative);

            span {
              width: 100%;
              height: 100%;
              overflow: hidden;

              &::before,
              &::after {
                content: '';
                left: 0;
                width: 200%;
                position: absolute;
                border-top: 1px solid var(--vp-semantic-color-border-catchy);
              }
              &::before {
                transform-origin: left top;
                top: 0;
                transform: rotate(45deg);
              }
              &::after {
                transform-origin: left bottom;
                bottom: 0;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

      #{$r}__value {
        align-items: center;
        display: flex;
        flex-grow: 0;
        justify-content: center;

        font-size: rem(30);
        white-space: nowrap;
      }

      #{$r}__picture-zoom {
        @include zoomButton();
      }

      #{$r}__picture {
        position: relative;
        flex-grow: 0;
        overflow: hidden;

        img {
          cursor: pointer;
        }

        > span {
          display: block;
          height: 100%;
        }

        &::before {
          display: block;
          content: '';
          padding-top: 100%;
        }
      }

      #{$r}__label {
        line-height: 1;
        padding-top: var(--vp-semantic-spacing-xs);
        flex-grow: 1;
      }

      #{$r}__icon {
        display: inline-block;
        color: var(--vp-semantic-color-status-positive);
      }
    }

    &--star-rating {
      #{$r}__items {
        margin-top: var(--vp-semantic-spacing-xs);
      }

      #{$r}__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-top: var(--vp-semantic-spacing-xs);
      }

      #{$r}__label {
        text-align: left;
        padding-right: var(--vp-semantic-spacing-xs);
        line-height: 1;
      }

      #{$r}__value {
        flex-shrink: 0;
      }
    }
  }

  // Nested comment threads
  &__comments {
    margin-top: var(--vp-semantic-spacing-xl);
  }
}

.result {
  &__banner {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-s);
    padding: var(--vp-semantic-spacing-m);
    margin-top: var(--vp-semantic-spacing-xs);
    margin-bottom: var(--vp-semantic-spacing-m);
    &__footer {
      margin-top: var(--vp-semantic-spacing-m);
    }
  }
  &__line {
    margin-top: var(--vp-semantic-spacing-xs);
    margin-bottom: var(--vp-semantic-spacing-xs);
  }
}

.grid-result {
  &__header {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-s);
    padding: var(--vp-semantic-spacing-xs);
    margin-top: var(--vp-semantic-spacing-xs);
    margin-bottom: var(--vp-semantic-spacing-xs);
  }

  &__choice {
    padding: rem(1) var(--vp-semantic-spacing-xs);
    position: relative;
  }

  &__label {
    padding-left: var(--vp-semantic-spacing-2xs);
    text-align: center;
  }

  &__dot {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: var(--vp-semantic-border-radius-full);
    top: 11px;
    left: 11px;
    background-color: var(--vp-semantic-color-container-brand);
    display: inline-block;
  }

  &__subquestion {
    display: flex;
    margin-bottom: var(--vp-semantic-spacing-xs);

    @include s-max {
      flex-direction: column;
      &__label {
        text-align: left;
      }
    }
    @include m-min {
      flex-direction: row;
      align-items: center;
      &__label {
        width: 20%;
        text-align: left;
      }
      &__values {
        width: 80%;
      }
    }
  }

  &__ratio {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-radius: var(--vp-core-border-radius-100);
    height: var(--vp-semantic-spacing-xl);
    line-height: var(--vp-semantic-spacing-xl);
  }

  &__percentage {
    text-align: center;
    color: var(--vp-semantic-color-content-neutral);
  }
}

.choice-question {
  &__percentage {
    &--top {
      position: relative;

      p {
        margin-top: rem(-3);
        margin-bottom: var(--vp-semantic-spacing-xs);
        width: 90%;
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--vp-semantic-color-status-info);
      }
    }

    &--track {
      height: rem(10);
      border-radius: var(--vp-core-border-radius-100);
      background-color: var(--vp-semantic-color-background-alternative);
      width: 100%;
      padding: rem(1);

      &--bar {
        height: var(--vp-semantic-spacing-xs);
        border-radius: var(--vp-core-border-radius-100);
        background-color: var(--vp-semantic-color-container-brand);
      }
    }
  }
}

.ranking-result {
  &__scale {
    padding: rem(80) rem(10) rem(10) rem(10);
    position: relative;

    &--track {
      height: rem(10);
      border-radius: var(--vp-core-border-radius-100);
      background-color: var(--vp-semantic-color-container-brand);
      width: 100%;
      padding: rem(1);
    }

    &__elements {
      position: relative;
    }

    &__element {
      position: absolute;
      height: rem(30);
      width: rem(30);
      border: rem(1) solid var(--vp-semantic-color-border-catchy);
      border-radius: var(--vp-semantic-border-radius-full);
      background-color: var(--vp-semantic-color-background-main);
      top: rem(-20);
      transform: translateX(-50%);
      cursor: pointer;

      &--ranking {
        text-align: center;
        font-weight: 600;
        color: var(--vp-semantic-color-status-info);
        position: relative;
        line-height: rem(30);
        font-size: rem(16);
        white-space: nowrap;
      }
    }

    &__details {
      width: rem(120);
      left: rem(-45);
      position: absolute;
      top: rem(-60);
      opacity: 0;

      &--title {
        color: var(--vp-semantic-color-status-info);
        font-weight: 600;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &--infos {
        font-weight: 500;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.active {
        opacity: 1 !important;
      }
    }
  }

  &__infos {
    margin: rem(2) rem(5);
    @include s-max {
      flex-direction: column;
    }
    @include m-min {
      flex-direction: row;
    }
  }

  &__order {
    height: rem(25);
    width: rem(25);
    border: rem(1) solid var(--vp-semantic-color-border-catchy);
    border-radius: var(--vp-semantic-border-radius-full);
    background-color: var(--vp-semantic-color-background-main);
    color: var(--vp-semantic-color-status-info);
    font-weight: 600;
    font-size: rem(16);
    position: relative;
    text-align: center;
  }

  &__label {
    top: rem(2);
    margin-left: rem(5);
  }
}

.ranking-picture-result {
  $self: &;

  &__container {
    padding: 0;
    display: grid;
    grid-template-columns: auto 3fr;
    grid-column-gap: var(--vp-semantic-spacing-m);
    grid-row-gap: var(--vp-semantic-spacing-m);
    grid-auto-flow: row;
    align-items: center;

    @include m-min {
      grid-template-columns: auto repeat(2, 3fr);
      padding: var(--vp-semantic-spacing-m);
    }
  }

  &__podium {
    display: flex;
    gap: var(--vp-semantic-spacing-xs);
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
    margin-top: var(--vp-semantic-spacing-m);

    @include m-min {
      justify-content: flex-start;
      grid-column: auto;
      margin: 0;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    gap: var(--vp-semantic-spacing-2xs);
    text-align: center;
    margin-bottom: var(--vp-semantic-spacing-m);

    @include m-min {
      grid-column: auto;
      gap: var(--vp-semantic-spacing-xs);
      justify-content: left;
      margin: 0;
    }
  }

  &__title {
    grid-column: 1 / -1;
    text-align: center;

    @include m-min {
      grid-column: auto;
      text-align: left;
    }
  }

  &__position {
    position: relative;

    svg {
      display: block;
    }

    > span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -20%);
      font-weight: 600;
      font-size: rem(20);
      line-height: 1;
      color: var(--vp-core-color-black);
    }

    &--1 svg {
      color: #fadb14;
    }

    &--2 svg {
      color: #d9dde1;
    }

    &--3 svg {
      color: #ff9c6e;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    border-radius: var(--vp-semantic-border-radius-full);
    width: var(--vp-semantic-spacing-6xl);
    outline: 1px solid var(--vp-semantic-color-border-inverse);
    background-color: var(--vp-semantic-color-background-alternative);

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &__fallback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--vp-semantic-color-border-inverse);
  }

  &__zoom {
    background: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
      opacity: 1;
    }

    > * {
      position: relative;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__line {
    grid-column: 1 / -1;
    border-top: 1px solid var(--vp-semantic-color-border-inverse);

    &:first-child {
      display: none;
    }
  }
}

.columns-result {
  $column: &;
  text-align: center;

  &__scrollable {
    padding-bottom: var(--vp-semantic-spacing-m);
    max-width: 100%;
    overflow-y: auto;
  }

  &__container {
    display: inline-flex;
    justify-content: space-between;
    margin: var(--vp-semantic-spacing-m) auto 0;
    width: 100%;

    @include m-min {
      width: auto;
      min-width: rem(400);
    }
  }

  &__column {
    padding: 0 rem(1);
    text-align: center;

    > * {
      white-space: nowrap;
    }

    &:nth-child(1) #{$column}__bar {
      animation-delay: delayAnimation(1);
    }
    &:nth-child(2) #{$column}__bar {
      animation-delay: delayAnimation(2);
    }
    &:nth-child(3) #{$column}__bar {
      animation-delay: delayAnimation(3);
    }
    &:nth-child(4) #{$column}__bar {
      animation-delay: delayAnimation(4);
    }
    &:nth-child(5) #{$column}__bar {
      animation-delay: delayAnimation(5);
    }
    &:nth-child(6) #{$column}__bar {
      animation-delay: delayAnimation(6);
    }
    &:nth-child(7) #{$column}__bar {
      animation-delay: delayAnimation(7);
    }
    &:nth-child(8) #{$column}__bar {
      animation-delay: delayAnimation(8);
    }
    &:nth-child(9) #{$column}__bar {
      animation-delay: delayAnimation(9);
    }
    &:nth-child(10) #{$column}__bar {
      animation-delay: delayAnimation(10);
    }

    #{$column}--col-5 & {
      &:nth-child(1) #{$column}__bar {
        background-color: #ee2316;
      }
      &:nth-child(2) #{$column}__bar {
        background-color: #f6d634;
      }
      &:nth-child(3) #{$column}__bar {
        background-color: #5dfd35;
      }
      &:nth-child(4) #{$column}__bar {
        background-color: #f6d634;
      }
      &:nth-child(5) #{$column}__bar {
        background-color: #ee2316;
      }
    }

    #{$column}--col-7 & {
      &:nth-child(1) #{$column}__bar {
        background-color: #ee2316;
      }
      &:nth-child(2) #{$column}__bar {
        background-color: #f18433;
      }
      &:nth-child(3) #{$column}__bar {
        background-color: #f6d634;
      }
      &:nth-child(4) #{$column}__bar {
        background-color: #5dfd35;
      }
      &:nth-child(5) #{$column}__bar {
        background-color: #f6d634;
      }
      &:nth-child(6) #{$column}__bar {
        background-color: #f18433;
      }
      &:nth-child(7) #{$column}__bar {
        background-color: #ee2316;
      }
    }
  }

  &__caption {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-semantic-border-radius-s);
    padding: var(--vp-semantic-spacing-m);
    margin-top: var(--vp-semantic-spacing-xs);
    margin-bottom: var(--vp-semantic-spacing-m);

    @include s-min {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: initial;
    }
  }

  &__caption-item {
    margin: var(--vp-semantic-spacing-2xs) var(--vp-semantic-spacing-xs);
    text-align: left;

    @include s-min {
      white-space: nowrap;
    }

    &::before {
      content: '';
      display: inline-block;
      margin-right: var(--vp-semantic-spacing-xs);
      width: var(--vp-semantic-spacing-xs);
      height: var(--vp-semantic-spacing-xs);
      border-radius: var(--vp-semantic-border-radius-full);
      background-color: var(--vp-semantic-color-background-alternative);
    }

    #{$column}--col-5 & {
      &:nth-child(1)::before {
        background-color: #ee2316;
      }
      &:nth-child(2)::before {
        background-color: #f6d634;
      }
      &:nth-child(3)::before {
        background-color: #5dfd35;
      }
      &:nth-child(4)::before {
        background-color: #f6d634;
      }
      &:nth-child(5)::before {
        background-color: #ee2316;
      }
    }

    #{$column}--col-7 & {
      &:nth-child(1)::before {
        background-color: #ee2316;
      }
      &:nth-child(2)::before {
        background-color: #f18433;
      }
      &:nth-child(3)::before {
        background-color: #f6d634;
      }
      &:nth-child(4)::before {
        background-color: #5dfd35;
      }
      &:nth-child(5)::before {
        background-color: #f6d634;
      }
      &:nth-child(6)::before {
        background-color: #f18433;
      }
      &:nth-child(7)::before {
        background-color: #ee2316;
      }
    }
  }

  &__percentage {
    font-size: rem(14);
    color: var(--vp-semantic-color-status-info);
  }

  &__track {
    position: relative;
    margin: var(--vp-semantic-spacing-2xs) auto;
    background-color: var(--vp-semantic-color-background-alternative);
    width: var(--vp-semantic-spacing-m);
    min-height: rem(260);
    border: rem(1) solid var(--vp-semantic-color-border-catchy);
    border-radius: var(--vp-core-border-radius-100);
  }

  &__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--vp-semantic-color-container-brand);
    border-radius: var(--vp-core-border-radius-100);
    animation: grow 0.8s ease-out 0s;
    animation-fill-mode: backwards;
    transform-origin: bottom;
  }

  &__label {
    display: inline-block;
    padding: 0 rem(4);
    margin-top: var(--vp-semantic-spacing-xs);
    min-width: var(--vp-semantic-spacing-2xl);
    height: var(--vp-semantic-spacing-2xl);
    line-height: var(--vp-semantic-spacing-2xl);
    background-color: var(--vp-semantic-color-background-alternative);
    border-radius: var(--vp-core-border-radius-100);
    color: var(--vp-semantic-color-content-neutral);
    font-weight: 600;
    font-size: rem(16);
  }
}
