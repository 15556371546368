@use 'sass:math';

html,
body {
  padding: 0;
  margin: 0;
  padding-top: 0 !important;
  font-size: rem($font-size-root);
  word-break: break-word;
  overflow-anchor: none;
  color: var(--vp-semantic-color-content-neutral);
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background: var(--vp-semantic-color-background-main);
}

// Reset
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-content {
  position: relative;
  flex-grow: 1;
  min-height: calc(100dvh - (#{rem(24)} + #{rem(60)}));
}

.page-layout {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
}

* {
  font-family: var(--vp-core-font-family-decathlon),
    var(--vp-core-font-family-inter), var(--vp-core-font-family-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

:focus {
  outline: var(--vp-semantic-color-content-quiet) auto 1px;
}

.skip-links {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
  opacity: 0.1;

  &:focus {
    top: var(--vp-semantic-spacing-xs);
    left: 0;
    height: auto;
    width: auto;
    margin: var(--vp-semantic-spacing-xs);
    border-radius: var(--vp-core-border-radius-100);
    background-color: var(--vp-semantic-color-background-main);
    color: var(--vp-semantic-color-content-neutral);
    padding: 0 var(--vp-semantic-spacing-xs);
    line-height: var(--vp-semantic-spacing-xl);
    font-size: rem(14);
    text-decoration: underline;
    transition: all 0.2s ease-out;
    opacity: 1;
    z-index: 210;
  }
}

// Make an element hidden but readable by screen readers
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

p,
ul,
ol {
  font-size: rem(16);
}

li {
  list-style-position: inside;
}

small {
  @include kit-body-small;
}

a {
  color: inherit;
  text-decoration: underline;

  @include m-min {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

// CLASSES

.hidden {
  display: none;
}

.hidden-xs {
  display: none;
}

@include m-min {
  .hidden-xs {
    display: block;
  }
}

.gutters-md {
  padding-left: 0;
  padding-right: 0;

  @include m-min {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.picture__redirection {
  background-color: var(--vp-semantic-color-container-brand);
}

.note {
  display: block;
  @include kit-body-small;
  padding-top: 1rem;
  text-align: center;
}

.link-button {
  button {
    padding: var(--vp-semantic-spacing-2xs) 0;
    font-weight: 600;
  }
}

.button-wrapper-link {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.alert {
  border-radius: var(--vp-semantic-border-radius-main);
}

.no-result {
  display: block;
  text-align: center;
  font-size: rem(16);
  padding: rem(20);
  margin: 0 0 rem(20);
}

.align-center {
  text-align: center;
}

.inline-flex {
  display: inline-flex;
}

.offline-banner {
  position: fixed;
  background: rgba(180, 0, 0, 0.8);
  padding: rem(6);
  left: 0;
  right: 0;
  top: 0;
  color: var(--vp-semantic-color-background-main);
  font-size: rem(13);
  line-height: math.div(16em, 13);
  text-align: center;
  z-index: 110;
  pointer-events: none;
  animation: appearFromTop 0.2s ease-out 0s 1 backwards;
}

.centered-box {
  height: calc(100vh - #{rem(60 + 80)});
  display: flex;
  flex-direction: column;
  max-inline-size: rem(500);
  margin: auto;
  text-align: center;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - #{var(--vp-semantic-spacing-xl)});
    padding: var(--vp-semantic-spacing-xl);
    margin: auto var(--vp-semantic-spacing-m);
    border: 1px solid var(--vp-semantic-color-border-inverse);
    background-color: var(--vp-semantic-color-background-main);
    border-radius: var(--vp-semantic-border-radius-s);
  }
}

.zoom-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: var(--vp-semantic-spacing-xs);
}

.administrator-label {
  margin: 0 var(--vp-semantic-spacing-xs);
  height: var(--vp-semantic-spacing-2xl);
  padding: var(--vp-semantic-spacing-2xs) var(--vp-semantic-spacing-xs);
  background: var(--vp-semantic-color-container-neutral);
  border-radius: var(--vp-core-border-radius-100);
  font-size: rem(14);
  font-style: normal;
  font-weight: 600;
  line-height: math.div(22em, 13);
  text-align: center;
  color: var(--vp-semantic-color-content-brand);
}

.admin-signature {
  display: flex;
  align-items: center;
  margin-top: var(--vp-semantic-spacing-2xs);
  background: var(--vp-semantic-color-container-neutral);
  border-radius: var(--vp-core-border-radius-100);
  padding: var(--vp-semantic-spacing-2xs) var(--vp-semantic-spacing-m);

  &__content {
    text-align: left;
    font-size: rem(12);
    margin-left: var(--vp-semantic-spacing-xs);
  }

  &__switch {
    margin-left: var(--vp-semantic-spacing-m);
  }

  &__icon {
    display: flex;
    align-items: center;

    border-right: 1px solid var(--vp-semantic-color-border-catchy);
    color: var(--vp-semantic-color-content-quiet);

    padding: var(--vp-semantic-spacing-2xs) var(--vp-semantic-spacing-m)
      var(--vp-semantic-spacing-2xs) 0;
  }

  &__identity {
    font-weight: 600;
  }
}

.messaging-use-list {
  list-style: disc;
  font-size: rem(13);
}

.logout {
  &__actions {
    display: flex;
    gap: var(--vp-semantic-spacing-m);
  }
}
