@use 'sass:math';

// DEFAULT
.input,
.textarea {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: var(--vp-semantic-border-radius-s);
  margin-bottom: var(--vp-semantic-spacing-m);
  padding: var(--vp-semantic-spacing-xs);
  background: var(--vp-semantic-color-background-main);
}

.textarea {
  min-height: 100px;
  resize: none;
}

// Fix iOs to prevent zoom on focus
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    &,
    &:focus {
      font-size: var(
        --vp-semantic-typography-screen-l-body-m-font-size
      ) !important;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-submit {
  text-align: center;
}

.form-fieldset {
  text-align: left;
  margin-bottom: var(--vp-semantic-spacing-xl);

  .vtmn-text-input_container {
    display: block;
  }

  > input,
  > [class*='vtmn-'],
  .vtmn-text-input_container input,
  .vtmn-text-input_container textarea,
  .vtmn-select_container select {
    width: 100%;
  }
}

.other-answer__input.tc_recruitment_answer_text {
  width: 100%;
  resize: vertical;
}

.select-question.vtmn-select_container {
  width: 100%;
}

.form-group {
  position: relative;
  text-align: left !important;

  * {
    text-align: left;
  }
  > input[type='text'],
  > input[type='password'],
  > input[type='email'],
  > input[type='number'],
  > input[type='tel'] {
    height: var(--vp-semantic-spacing-3xl);
  }
}

.control-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: var(--vp-semantic-spacing-xs);
  font-weight: 600;
  color: var(--vp-semantic-color-content-neutral);

  .form-group.has-error & {
    color: var(--vp-semantic-color-status-negative);
  }
}

.control-description {
  display: block;
  margin-bottom: var(--vp-semantic-spacing-xs);
  font-weight: normal;
  color: var(--vp-semantic-color-content-quiet);
  font-size: rem(15);
  line-height: math.div(17em, 15);

  .form-group.has-error & {
    color: var(--vp-semantic-color-status-negative);
  }
}

.control-upload {
  $self: &;
  text-align: center;

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--vp-semantic-spacing-s);
  justify-content: center;

  &__add {
    display: block;
    position: relative;
    height: 100%;
    cursor: pointer;

    label {
      position: relative;
      z-index: 1;
    }

    input {
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      inset: 0;
    }
  }

  &__reset,
  &__cancel {
    display: flex;
    justify-content: center;
    gap: var(--vp-semantic-spacing-xs);
    font-size: 80%;
    margin-bottom: var(--vp-semantic-spacing-m);
    color: var(--vp-semantic-color-content-brand);
    cursor: pointer;

    > svg {
      display: inline-block;
      margin-right: var(--vp-semantic-spacing-xs);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__error-icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--vp-semantic-spacing-m);
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    line-height: math.div(16em, 14);
    color: var(--vp-semantic-color-status-negative);
    margin: var(--vp-semantic-spacing-xs) auto;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__preview {
    -webkit-perspective: 600px;
    perspective: 600px;
    margin: 0 auto var(--vp-semantic-spacing-xs);
    width: rem(300);
    max-width: 100%;

    > span {
      display: block;
      border-radius: var(--vp-core-border-radius-100);
      transform-origin: top;
      transform-style: preserve-3d;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      animation: flapAppear 0.4s ease-out 0.2s 1 backwards;

      &::before {
        content: '';
        display: block;
        padding-top: math.div(200%, 3);
      }
    }

    img {
      display: none;
    }
  }

  &__file {
    margin-bottom: var(--vp-semantic-spacing-m);
    display: inline-flex;
    text-decoration: none;
    font-size: rem(13);
    align-items: center;

    &__label {
      display: inline-block;
      margin: 0 var(--vp-semantic-spacing-xs);
      text-decoration: underline;
    }

    &__extension {
      text-decoration: none;
      color: var(--vp-semantic-color-content-neutral);
      background-color: var(--vp-semantic-color-background-alternative);
      border-radius: rem(3);
      padding: rem(2) rem(5);
      text-transform: uppercase;
    }
  }

  &__action {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--vp-semantic-spacing-m);
  }

  &__circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--vp-semantic-color-border-neutral);
    color: var(--vp-semantic-color-border-neutral);
    border-radius: var(--vp-semantic-border-radius-full);

    block-size: rem(90);
    inline-size: rem(90);
  }

  &--large {
    gap: var(--vp-semantic-spacing-s);

    background-color: var(--vp-semantic-color-background-main);
    border: 1px dashed var(--vp-semantic-color-border-quiet);

    padding: var(--vp-semantic-spacing-2xl);
  }
}

.control-media {
  position: relative;
  margin: 0 auto var(--vp-semantic-spacing-xl);
  max-inline-size: rem(500);
  text-align: center;

  // reset next/image for display specificities
  span[style] > span[style] {
    display: none !important;
  }

  img {
    border-radius: var(--vp-core-border-radius-100);
    @include resetNextImage();
  }

  &__picture {
    display: inline-block;
    position: relative;
    min-height: calc(var(--vp-semantic-spacing-xs) * 7);
  }

  &.control-media-video {
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: math.div(90%, 1.6);
    }

    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.control-placeholder {
  color: var(--vp-semantic-color-content-quiet);
}

.form-group .form-help {
  display: none;
}

.form-help {
  .form-group.has-error & {
    background: var(--vp-semantic-color-status-negative);
    .caret {
      color: var(--vp-semantic-color-status-negative);
    }
  }
  .form-group.has-notif & {
    background: var(--vp-semantic-color-background-alternative);
    .caret {
      color: var(--vp-semantic-color-content-neutral);
    }
  }
  .form-group.has-error &,
  .form-group.has-notif & {
    display: block;
    position: absolute;
    margin-top: var(--vp-semantic-spacing-xs);
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-m);
    color: white;
    font-size: var(--vp-semantic-typography-screen-l-subtitle-m-font-size);
    top: 100%;
    z-index: 10;
    .caret {
      position: absolute;
      top: -8px;
      left: 14px;
      display: block;
      width: 0;
      height: 0;
      margin-left: rem(2);
      border-top: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px dashed;
      border-bottom: 4px solid \9;
      border-left: 4px solid transparent;
    }
  }
}

textarea.form-control {
  min-height: 100px;
  resize: none;
}

/*
* Checkboxes and radios
*/

.form-group.radio,
.form-group.checkbox {
  margin-bottom: var(--vp-semantic-spacing-xs);
  margin-top: var(--vp-semantic-spacing-xs);
  display: inline-block;
  vertical-align: top;
}

.control-inline .form-group.radio,
.control-inline .form-group.checkbox {
  margin-right: var(--vp-semantic-spacing-xl);
}

.control-checkboxes,
.control-radios {
  .other-answer {
    margin-top: var(--vp-semantic-spacing-m);

    textarea {
      width: 100%;
    }
  }
}

// IMAGES VERSION
.form-fieldset-picture-choice {
  .form-group {
    inline-size: calc(#{math.div(100%, 2)} - #{var(--vp-semantic-spacing-xs)});
    max-inline-size: rem(356);

    @include m-min {
      inline-size: calc(
        #{math.div(100%, 3)} - #{var(--vp-semantic-spacing-xs)}
      );
    }

    @include l-min {
      inline-size: calc(
        #{math.div(100%, 4)} - #{var(--vp-semantic-spacing-xs)}
      );
    }

    @include xl-min {
      inline-size: calc(
        #{math.div(100%, 5)} - #{var(--vp-semantic-spacing-xs)}
      );
    }
  }

  // Desktop layout
  // Spread the picture choice cards in the container
  // if only 2 choices
  .control-radios,
  .control-checkboxes {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    &--1,
    &--2 {
      .form-group {
        inline-size: calc(
          #{math.div(100%, 2)} - #{var(--vp-semantic-spacing-xs)}
        );
      }
    }

    &--3 {
      @include m-min {
        justify-content: center;
      }
    }

    &--3,
    &--4 {
      .form-group {
        // Center the picture choice cards if only 3 choices
        // Spread the picture choice cards in the container
        // (applied only if has picture choices)
        @include m-min {
          inline-size: calc(
            #{math.div(100%, 4)} - #{var(--vp-semantic-spacing-xs)}
          );
        }
      }
    }
  }

  .other-answer {
    margin-top: var(--vp-semantic-spacing-xs);
    width: 100%;
  }

  .form-group.radio,
  .form-group.checkbox {
    position: relative;
    display: inline-block;
    margin: var(--vp-semantic-spacing-2xs);
    vertical-align: top;
  }

  .control-ranking-pictures {
    // Ranking number
    .control-checked-ranking {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--vp-core-color-white);
      line-height: var(--vp-semantic-spacing-5xl);
      font-size: var(--vp-semantic-spacing-5xl);
      font-weight: 600;
      pointer-events: none;
    }

    // Dark selection veil
    .control-image > label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 100%;
      background-color: transparent;
      pointer-events: none;
      transition: background 0.2s ease-out;
    }
    &.control-checkbox--selected {
      .control-image > label::after {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .control-checkbox,
  .control-radio {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 100%;
    padding: 0;
    border: 1px solid var(--vp-semantic-color-border-quiet);

    // Checked Icon
    .control-checked-icon {
      position: absolute;
      top: var(--vp-semantic-spacing-2xs);
      right: var(--vp-semantic-spacing-2xs);
      color: var(--vp-semantic-color-container-brand);

      > span {
        position: relative;
      }

      svg {
        color: var(--vp-semantic-color-content-brand);
      }
    }

    .control-input {
      position: fixed;
      opacity: 0;
      pointer-events: none;
    }

    .control-outline {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      outline-style: solid;
      outline-width: rem(2);
      outline-color: transparent;
      transition: all 0.2s ease-in-out;
    }

    .control-image {
      display: block;
      position: relative;
      cursor: pointer;
      border-radius: var(--vp-core-border-radius-200)
        var(--vp-core-border-radius-200) 0 0;
      background-color: var(--vp-semantic-color-background-main);
      overflow: hidden;

      &__fallback {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--vp-semantic-color-border-inverse);
      }

      > label {
        display: block;
        outline: 2px solid transparent;
        position: relative;
        transition: outline 0.2s ease-in-out;
        cursor: pointer;

        &::before {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }

      @include m-min {
        opacity: 1;
        transition: opacity 0.3s ease-out;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .control-legend {
      display: block;
      background-color: var(--vp-semantic-color-background-main);
      flex-grow: 0;
      word-break: break-word;
      border-radius: 0 0 var(--vp-core-border-radius-200)
        var(--vp-core-border-radius-200);
      min-height: calc(var(--vp-semantic-spacing-xs) * 7);
      cursor: pointer;

      label {
        display: block;
        padding: var(--vp-semantic-spacing-xs);
        width: 100%;
        height: 100%;
      }

      &--selected {
        background-color: var(--vp-semantic-color-container-neutral);
      }
    }

    .control-image:hover ~ .control-outline,
    &:focus-within .control-outline {
      outline-color: var(--vp-semantic-color-border-inverse);
    }

    &--selected .control-outline,
    &--selected .control-image:hover ~ .control-outline,
    &--selected:focus-within .control-outline {
      outline-color: var(--vp-semantic-color-border-brand);
    }

    &--selected {
      .control-image {
        opacity: 1;
      }
    }

    .control-image ~ .control-legend {
      flex-grow: 1;
    }
  }
}

.checkbox-inline input[type='checkbox'],
.radio-inline input[type='radio'] {
  position: absolute;
  margin: var(--vp-semantic-spacing-xs);
  margin-top: var(--vp-semantic-spacing-2xs);
  left: 0;
  top: 0;
  width: rem(20);
  height: rem(20);
  cursor: pointer;
}

.control-stacked .form-group.radio,
.control-stacked .form-group.checkbox {
  display: block;
  margin-left: 0;

  &.hidden {
    display: none;
  }
}

.control-rating {
  &__support {
    display: inline-block;
    padding: var(--vp-semantic-spacing-2xs);
    border-radius: var(--vp-semantic-border-radius-s);
    background-color: var(--vp-core-color-white);
  }
  &__sub-question {
    & + & {
      margin-top: var(--vp-semantic-spacing-xl);
    }
  }
}

.control-sortable {
  margin-top: var(--vp-semantic-spacing-m);
  margin-left: var(--vp-semantic-spacing-xl);
  margin-right: var(--vp-semantic-spacing-xl);

  @include m-min {
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    margin: 0;
    list-style: none;
    list-style-type: none;
  }

  li + li {
    margin-top: var(--vp-semantic-spacing-xs);
  }

  li,
  li:hover {
    @include listDrag();
    border: none;
    border-radius: var(--vp-semantic-border-radius-s);
    background-color: var(--vp-semantic-color-background-alternative);
  }

  .handle {
    padding: var(--vp-semantic-spacing-xs);
    margin-left: auto;
    cursor: row-resize;

    &::before {
      content: '';
      display: block;
      width: rem(22);
      height: rem(12);
      background: linear-gradient(
        180deg,
        var(--vp-semantic-color-content-quiet),
        var(--vp-semantic-color-content-quiet) 20%,
        transparent 0,
        transparent 40%,
        var(--vp-semantic-color-content-quiet) 0,
        var(--vp-semantic-color-content-quiet) 60%,
        transparent 0,
        transparent 80%,
        var(--vp-semantic-color-content-quiet) 0,
        var(--vp-semantic-color-content-quiet)
      );
    }
  }
  .index-drag {
    display: inline-block;
    word-break: normal;
  }
  .label-drag {
    display: inline-block;
  }
}

.confirm-sortable {
  margin-top: var(--vp-semantic-spacing-xs);
}

.list-drag {
  @include listDrag();
  border: none;
}

.control-grid {
  $self: &;
  max-width: 100%;
  margin-bottom: var(--vp-semantic-spacing-xs);

  &__table {
    min-width: 100%;
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    color: var(--vp-semantic-color-content-quiet);
    border-collapse: separate;
    border-spacing: 0 var(--vp-semantic-spacing-m);
  }

  th {
    font-weight: normal;
    padding: var(--vp-semantic-spacing-xs);
    text-align: right;
    color: var(--vp-semantic-color-content-neutral);
    word-break: keep-all;
  }

  td {
    text-align: center;
    vertical-align: middle;
    word-break: keep-all;
  }

  thead {
    color: var(--vp-semantic-color-content-neutral);
  }

  @include l-min {
    position: relative;

    &__scrollable {
      overflow-x: auto;

      // Custom scrollbar
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 8px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--vp-semantic-border-radius-s);
        background-color: var(--vp-semantic-color-container-brand);
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: var(--vp-semantic-border-radius-s);
      }
    }

    thead th {
      padding: 0 var(--vp-semantic-spacing-xs);
    }

    thead th,
    tbody td {
      text-align: center;
    }

    tbody {
      th {
        position: sticky;
        left: 0;
        min-width: calc(var(--vp-semantic-spacing-xs) * 14);
        text-align: left;
        z-index: 2;
      }

      td,
      th {
        background-color: var(--vp-semantic-color-background-alternative);
        padding: var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-xl);
        min-width: var(--vp-semantic-spacing-6xl);
      }

      th:first-child {
        background-color: var(--vp-semantic-color-background-alternative);
      }
    }

    // Shadow for sticky elements
    tbody th::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: var(--vp-semantic-spacing-xl);
      transform: translate(100%);
      pointer-events: none;
      transition: all 0.3s ease-out;
      opacity: 0;
    }

    &--scrolled {
      tbody th::before {
        opacity: 1;
      }
    }

    // add gradient if overflowing
    &--overflowing {
      &::after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: var(--vp-semantic-spacing-m);
        width: var(--vp-semantic-spacing-3xl);
        transition: opacity 0.2s ease-out;
        background: linear-gradient(
          0.25turn,
          transparent,
          var(--vp-semantic-color-background-alternative)
            /* should match container background */
        );
      }

      &#{$self}--end {
        &::after {
          opacity: 0;
        }
      }
    }

    // Hide radio label on table display
    .vtmn-radio-button[type='radio'] + label {
      &::before {
        margin-inline-end: 0;
      }
      span {
        display: none;
      }
    }
  }

  @include m-max {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: block;

      tr {
        display: block;

        &:first-child th {
          margin-top: 0;
        }
      }

      th {
        display: block;
        padding: var(--vp-semantic-spacing-m) var(--vp-semantic-spacing-xl);
        margin-bottom: var(--vp-semantic-spacing-xs);
        margin-top: var(--vp-semantic-spacing-m);
        background-color: var(--vp-semantic-color-background-alternative);
        font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
        border-radius: var(--vp-semantic-border-radius-s);
        color: var(--vp-semantic-color-content-neutral);
        text-align: left;
      }

      td {
        display: block;
        margin: 0 var(--vp-semantic-spacing-xl);
        padding: var(--vp-semantic-spacing-m) 0;
        text-align: left;
      }

      td + td {
        border-top: 1px solid var(--vp-semantic-color-border-quiet);
      }
    }
  }
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: var(--vp-semantic-color-status-negative);
}

.mandatory {
  &::after {
    display: inline-block;
    margin-left: var(--vp-semantic-spacing-2xs);
    content: '*';
    line-height: 1;
    font-size: var(--vp-semantic-typography-screen-l-body-m-font-size);
    color: inherit;
  }
}

.mandatory-explain {
  display: block;
  margin-top: rem(2);
  font-size: var(--vp-semantic-typography-screen-l-subtitle-m-font-size);
  color: var(--vp-semantic-color-status-negative);
}

.mandatory-note {
  display: block;
  margin: var(--vp-semantic-spacing-xs) auto;
  font-size: var(--vp-semantic-typography-screen-l-subtitle-m-font-size);
  color: var(--vp-semantic-color-content-quiet);
}

.last-report {
  text-align: center;
  margin: var(--vp-semantic-spacing-m) 0;

  &__count {
    font-weight: 600;
  }

  &__date {
    @include kit-body-small;
  }
}

.form-questions {
  min-height: var(--vp-semantic-spacing-m);
}

.form-actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: var(--vp-semantic-spacing-2xl);

  > * {
    margin-bottom: var(--vp-semantic-spacing-m);
  }

  @include s-max {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include s-min {
    flex-direction: row;
    align-items: center;

    > * {
      margin-right: var(--vp-semantic-spacing-xl);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--centered {
    justify-content: center;
  }

  &--small {
    > * {
      margin-bottom: var(--vp-semantic-spacing-xs);

      @include s-max {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include s-min {
      > * {
        margin-right: var(--vp-semantic-spacing-m);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.progress-content {
  background-color: var(--vp-semantic-color-background-alternative);
  width: 100%;
  position: fixed;
  left: 0;
  top: rem(80);
  z-index: 1;
  transition: top 0.2s ease-out;

  @include m-min {
    top: rem(60);
  }

  .scrolled:not(.is-scrolling-up) & {
    top: 0;
  }
}

.progress {
  background-color: var(--vp-semantic-color-container-brand);
  height: var(--vp-semantic-spacing-2xs);
  transition: all 0.5s ease-out;
  border-top-right-radius: var(--vp-core-border-radius-100);
  border-bottom-right-radius: var(--vp-core-border-radius-100);
}

.form {
  &__choice {
    background-color: var(--vp-semantic-color-background-alternative);
    padding: var(--vp-semantic-spacing-xs) var(--vp-semantic-spacing-m);
    border-radius: var(--vp-semantic-border-radius-s);
  }

  &__label {
    width: 100%;
    cursor: pointer;
  }
}

.text-input {
  &__counter {
    text-align: right;
    color: var(--vp-semantic-color-content-quiet);
  }
}

.ranking {
  &__info {
    width: 100%;
    color: var(--vp-semantic-color-content-quiet);
    background-color: var(--vp-semantic-color-container-neutral);
    padding: var(--vp-semantic-spacing-m);
    border-radius: var(--vp-semantic-border-radius-s);
  }
}

.ranking-picture {
  &__info {
    width: 100%;
    color: var(--vp-semantic-color-content-quiet);
    margin-bottom: var(--vp-semantic-spacing-m);
  }
}

.icon-option {
  display: inline-flex;
  vertical-align: middle;

  border: 1px solid var(--vp-semantic-color-border-quiet);
  width: rem(18);
  height: rem(18);

  &--rounded {
    border-radius: var(--vp-semantic-border-radius-full);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(20);
    height: rem(20);
  }

  &--rounded.icon-option--selected {
    &::before {
      content: '';
      height: rem(12);
      width: rem(12);

      background: var(--vp-semantic-color-container-brand);
      border-radius: var(--vp-semantic-border-radius-full);
    }
  }

  &--selected {
    border-color: var(--vp-semantic-color-border-brand);
    color: var(--vp-semantic-color-content-on-brand-neutral);

    &:not(.icon-option--rounded) {
      background: var(--vp-semantic-color-container-brand);
    }
  }
}
