.maintenance-page {
  background-color: var(--vp-semantic-color-background-main);

  display: flex;
  flex-direction: column;
  height: 100dvh;

  position: relative;
  overflow: hidden;

  header {
    padding: var(--vp-semantic-spacing-2xl) var(--vp-semantic-spacing-l) 0;
    text-align: center;

    .cocreation-logo {
      flex-direction: row;
    }
  }

  main {
    flex-grow: 1;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    min-block-size: 100dvh;
    max-block-size: 100%;
    padding: var(--vp-semantic-spacing-3xl);
    position: relative;

    &::before {
      background-color: var(--vp-semantic-color-container-brand);

      content: '';
      position: absolute;
      inset-inline: 0;
      inset-block-end: 0;
      height: 45%;
    }
  }

  &__message {
    position: relative;

    .maintenance-clouds {
      inset: 0;
      position: absolute;

      margin-inline: auto;
      transform: translateY(-15vh);
      z-index: -5;
    }
  }
}
