@use 'sass:math';

.projects {
  &__more {
    display: flex;
    height: var(--vp-semantic-spacing-4xl);
    margin-bottom: var(--vp-semantic-spacing-2xl);

    > * {
      margin: auto;
    }
  }
}

/*********************
  Projects Filter
**********************/
.projects-filter {
  background-color: var(--vp-semantic-color-background-main);
  position: sticky;
  inset-block-start: 0;
  z-index: 80;
  transition: top 0.2s ease-out;

  .is-scrolling-up & {
    inset-block-start: rem(80);

    @include m-min {
      inset-block-start: rem(60);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--vp-semantic-spacing-m) 0;
    gap: var(--vp-semantic-spacing-xs);

    @include s-max {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__status > ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: var(--vp-semantic-spacing-xs);

    // Hide scrollbars
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    @include s-max {
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: var(--vp-semantic-spacing-xs);
    }
  }

  &__sport {
    margin-left: auto;
    transform-origin: top left;
    width: rem(440);
    height: var(--vp-semantic-spacing-3xl);
    transition: all 0.2s ease;

    @include s-max {
      width: 100%;
    }
  }

  /******** Select ***********/

  &__select-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__select-favourite-icon-prefix {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem(4);
    padding: rem(1);
    color: var(--vp-semantic-color-content-neutral);
    font-size: rem(11);
  }
}

// Specific override for
#react-select-filterSport-input {
  &,
  &:focus {
    font-size: rem(14) !important;
    font-weight: 600;
    color: var(---vp-semantic-color-content-quiet);
  }
}

/*********************
  Projects List
**********************/
.projects-list {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--vp-semantic-spacing-2xl);
  padding: 0 0 var(--vp-semantic-spacing-m);
  margin-top: var(--vp-semantic-spacing-m);
  margin-bottom: var(--vp-semantic-spacing-m);

  @include m-min {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @include l-min {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  & + .load-more {
    margin-bottom: var(--vp-semantic-spacing-xl);
  }

  // Homepage override
  .all-projects & {
    @include m-min {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include l-min {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
